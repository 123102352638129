import styled from "@emotion/styled";
import React, { useCallback, useEffect, useState } from "react";
import { createModal, ModalCardContainer, ModalCloseIcon } from "services/modal-service";
import { AnimateSharedLayout, motion } from "framer-motion";
import { Document } from "domain/document";
import { colors, mobile, row, Spacer, stack } from "style";
import { useI18n } from "utils/i18n";
import { fullName } from "../../domain/utils";
import { useObservable } from "micro-observables";
import { patientService } from "../../services/patient-service";
import { Button } from "../button";
import { Surgery } from "../../domain/surgery";
import { RadioButtonsInput, RadioButtonsInputProps } from "../inputs/radio-buttons-input";
import { CreatInfoModal } from "./create-info-modal";

export const MySignModal: React.FC<{
	surgeryId: string;
	surgeryDate: string;
	documents: Document[];
	onClose: (result?: string) => void;
	surgery: Surgery | undefined;
}> = ({ surgeryId, surgeryDate, documents, onClose, surgery }) => {
	const { format, formatDate } = useI18n();
	const patient = useObservable(patientService.patient);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<{ code: number } | null>(null);
	const [apiCodeError, setApiCodeError] = useState<string>("");
	const [loop, setLoop] = useState<boolean>(false);
	const [refresh, setRefresh] = useState<boolean>(false);
	const [smsType, setSmsType] = useState<boolean>(false);
	const [removeOnclose, setRemoveOnclose] = useState<boolean>(false);

	// start Process API HSS
	const getGUIDApi = useCallback(async () => {
		try {
			setLoading(true);
			setError(null);
			setLoop(true)
			setApiCodeError(await patientService.signDocumentApiV3(surgeryId, smsType));
		} catch (e: any) {
			setLoading(false);
			setLoop(false)
			setError({ code: e?.response?.status });
		}

	}, [apiCodeError, surgeryId, smsType]);

	// Lance un check tous les 5 secondes
	const checkContract = useCallback(async (surgeryId: string) => {
		try {
			if (surgery?.id != undefined) {
				const response = await patientService.etatContratV3(surgeryId);
				// affichage du code erreur retour
				if (response != "30") {
					setLoop(true)
				} if (response == "99") {
					setLoop(true)
					console.log("Il y a eu une erreur, probalbement GUID qui n'existe pas")
					// onClose()
				} else if (response == "30") {
					setLoop(false)
					onClose()
					await patientService.fetchCurrentSurgery(surgeryId);
				}
			}
		} catch (error) {
			console.error('Erreur check signature:', error);
		}
	}, [surgeryId]);

	// signer
	useEffect(() => {
		let intervalId: NodeJS.Timeout;
		let timeoutId: NodeJS.Timeout;

		// on stop la boucle d'appel de check au bout de 5 minutes
		const stopFetching = () => {
			clearInterval(intervalId);
			setLoop(false);
			onClose()
		};

		if (loop) {
			intervalId = setInterval(() => {
				checkContract(surgeryId).then();
			}, 12000);
			timeoutId = setTimeout(stopFetching, 5 * 60 * 1000); // 5 minutes
		}

		return () => {
			clearInterval(intervalId);
			clearTimeout(timeoutId);
		};
	}, [checkContract, loop, apiCodeError, loading, surgery, refresh, onClose, surgeryId]);

	return (
		<AnimateSharedLayout>
			<Card layout>
				{!removeOnclose &&
					(<ModalCloseIcon onClick={() => onClose()} />
					)}

					<Form>
						<Header>
							<Title>{format("patient.signModal.title")}</Title>
							<DescriptionWrapper>
								<Spacer />
								<PatientData>
									{patient && <PatientName>{fullName(patient)}</PatientName>}
									<PatientSurgery>
										{format("hospital.validationModal.surgeryDate")}{" "}
										{formatDate(surgeryDate, { day: "numeric", month: "numeric", year: "numeric" })}
									</PatientSurgery>
								</PatientData>
							</DescriptionWrapper>
						</Header>
						<Content>
							<SendInBlue>
								<TitleContent>
									<VerificationTitle>{format("patient.signModal.verificationTitle")}</VerificationTitle>
								</TitleContent>
								<Instruction>
									<InfosSign>{format("patient.signModal.sign")}</InfosSign>

									<LinkModalInfo
										onClick={async () => {
											await createModal<string | null>(({ onClose }) => (
												<CreatInfoModal onClose={onClose} />
											));
										}}
									>
										{format("howToSign")}
									</LinkModalInfo>

									<AnswerWrapper>
										<AnswerRadioButtons
											innerId="answer-radio-sms"
											required
											value={smsType}
											options={[true, false]}
											onChange={smsType => {
												if (smsType != null)
													setSmsType(smsType)
											}}
											itemRenderer={(item) => {
												if (item === false)
													return "Par Email"
												else
													return "Par SMS"
											}}
										/>
									</AnswerWrapper>
								</Instruction>
								<FinalSignButton
									key="validate"
									layout="position"
									loading={loading}
									onClick={e => {
										e.preventDefault();
										getGUIDApi().then();
									}}
								>
									{format("patient.signModal.signDocuments")}
								</FinalSignButton>
							</SendInBlue>

							{loop ? (
									<>
										<TextActionToDo>
										{format("patient.signModal.signataire")}
										</TextActionToDo>
										<TextActionToDo>
										{format("patient.signModal.dontQuit")}
										</TextActionToDo>
									</>
							) :
								null
							}

						</Content>
					</Form>

			</Card>
		</AnimateSharedLayout>
	);
};

// region style
const Form = styled.form`
    width: 100%;
    height: 100%;
`;

const Card = styled(motion(ModalCardContainer))`
    ${stack("M")};
    width: 80%;
    height: 80%;
    padding: 10px 40px;

    @media (max-width: 920px) {
        width: 100%;
        height: 100%;
        padding: 1px;
    }
`;

const Header = styled.div`
    ${stack("S")};
    height: 15%;
    width: 100%;
`;

const Instruction = styled.div`
    margin: auto;
		font-size: 22px;
`;

const TitleContent = styled.div`
    margin: auto;
`;

const InfosSign = styled.div`
    margin: auto;
		font-size: 22px;
`;

const Content = styled.div`
    height: 70%;
    width: 100%;
`;
//		HEADER		//

const Title = styled.h1`
    font-size: 32px;
    max-width: 600px;
    color: ${colors.black};
    @media ${mobile} {
        font-size: 24px;
    }
`;

const DescriptionWrapper = styled.div`
    ${row("S")};
    width: 100%;
`;

const PatientData = styled.div`
    ${stack(0, "flex-start", "flex-end")}
`;

const PatientName = styled.div`
    font-size: 26px;
    font-weight: bold;
    color: ${colors.black};
    @media ${mobile} {
        font-size: 20px;
    }
`;

const PatientSurgery = styled.div`
    font-size: 15px;
    color: ${colors.black};
`;

//		BODY		//

const FinalSignButton = styled(Button)`
		margin: auto;
`;

const VerificationTitle = styled.h1`
    text-align: center;
    font-size: 28px;
    color: ${colors.black};
`;

const SendInBlue = styled.div`
    @media (min-width: 920px) {
        width: 40%;
        min-height: 70%;
    }
    ${stack("M")};
    display: flex;
    padding: 35px;
    background-color: #f0f0f0;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    border-radius: 26px;
    border: none;
`;

const AnswerWrapper = styled.div`
    margin-top: 40px;
		text-align: center;
    display: block ruby;
`;

const TextActionToDo = styled.div`
    color: ${colors.red};
		margin: auto;
    text-align: center;
    display: block;
`;

type AnswerProps = React.FC<RadioButtonsInputProps<boolean | null>>;
const AnswerRadioButtons = styled<AnswerProps>(RadioButtonsInput)``;

const LinkModalInfo = styled.div`
	${stack("S", "flex-start", "stretch")};
	font-size: 15px;
	color: ${colors.black};
  cursor: pointer;
  text-decoration: underline;
	:hover {
			text-decoration: none;
	}
`;
