import styled from "@emotion/styled";
import { Button } from "components/button";
import { FormField } from "components/form-field";
import { SelectInput } from "components/inputs/select-input";
import { Section, SectionTitle } from "components/section";
import { Surgery, SurgeryLaterality, SurgeryUpdateDTO, VisitType } from "domain/surgery";
import { fullName } from "domain/utils";
import { motion } from "framer-motion";
import React, { CSSProperties, FormEvent, HTMLAttributes, useCallback, useEffect, useMemo, useState } from "react";
import { colors, gammeColors, row, sizes, Spacer, stack } from "style";
import { useI18n } from "utils/i18n";
import { normalizeDate } from "utils/time";
import debounce from "lodash/debounce";
import { practitionerService, useCCAMDatabasePractitioner } from "../../services/practitioner-service";
import { CheckboxMultiSelect } from "../inputs/multi-select-input";
import { CheckboxInput } from "../inputs/checkbox-input";
import { TextInput } from "../inputs/text-input";
import { supportTechnicalService, usePractitionersForSettings } from "../../services/support-technical-service";

interface SecretarySectionProps {
	onEdit?: (data: {showNotification: boolean, state: string, message: string, id?: string}) => void;
}
export const CreateSecretarySection: React.FC<SecretarySectionProps> = ({onEdit}) => {
	const { format, formatDpmMode } = useI18n();
	const [firstName, setFirstName] = useState<string>("");
	const [lastName, setLastname] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const {practitioners} = usePractitionersForSettings()
	const [practitionersValue, setPractitionersValue] = useState<string[]>([]);
	const [sendOnBoardingMail, setSendOnBoardingMail] = useState<boolean>(false);


	const createSecretary = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
		try{
			event?.preventDefault();
			setLoading(!loading)
			const response = await supportTechnicalService.createSecretary(firstName, lastName, email, practitionersValue, sendOnBoardingMail)
			setLoading(false);
			onEdit?.({ showNotification: true, state: "success", message: `Success: ${response.data.message}`, id: response.data.id});
		}
		catch (e: any) {
			e?.response?.status;
			setLoading(false);
			onEdit?.({ showNotification: true, state: "danger", message: `Erreur : ${e?.response?.data.message || "Une erreur inconnue est survenue"}` });
		}
		finally {
			setLoading(false);
		}

	}, [email, firstName, lastName, loading, onEdit, practitionersValue, sendOnBoardingMail])


	return (
		<Form onSubmit={createSecretary}>
			<SectionTitle layout>{format("create.secretary")}</SectionTitle>
			<Row>
				<Input
					value={lastName}
					placeholder={format("practitioner.createSecretary.lastNamePlaceholder")}
					onChange={e => {
						setLastname(e.target.value.toUpperCase());
					}}
					required
				/>
				<Input
					value={firstName}
					placeholder={format("practitioner.createSecretary.firstNamePlaceholder")}
					onChange={e => {
						setFirstName(e.target.value);
					}}
					required
				/>
			</Row>
			<Row>
				<Input
					value={email}
					type="email"
					placeholder={format("practitioner.createAccount.emailPlaceholder")}
					onChange={e => {
						setEmail(e.target.value);
					}}
					required
				/>
			</Row>
			<Row>
				<CheckboxMultiSelect<string>
					innerId="practitioners"
					values={practitionersValue ?? []}
					onChange={(e) => setPractitionersValue(e)}
					defaultValue="Sélectionner un praticien"
					options={practitioners
						?.sort((prA, prB) => prA.lastName.localeCompare(prB.lastName))
						.map(pr => pr.id) ?? []}
					itemRenderer={id => `Dr ${practitioners?.find(pr => pr.id === id)?.lastName}` ?? ""}
					uniqueChoice
				/>
			</Row>
			<CheckboxInput value={sendOnBoardingMail} onChange={() => setSendOnBoardingMail(!sendOnBoardingMail)}>
				Envoyer un email de création de compte ?
			</CheckboxInput>
			<Buttons>
				<Button
					key="submit"
					type="submit"
					initial={{ opacity: 1 }}
					loading={loading}
					disabled={loading}
				>
					{format("patient.dashboard.info.submit")}
				</Button>
			</Buttons>
		</Form>
	);
};

const Form = styled.form`
	${stack("L", "flex-start", "stretch")};
	flex-grow: 1;
`;

const Input = styled(TextInput)`
	width: 300px
`;

const Buttons = styled(motion.div)`
	align-self: flex-end;
	${row("S", "flex-end", "center")};
`;

const Row = styled.div`
    ${row("M", "flex-start", "flex-start")};
`;


const FieldStack = styled.div`
	${stack("S")}
`;
