import styled from "@emotion/styled";
import { Button } from "components/button";
import { EmailInput } from "components/inputs/text-input";
import { UserType } from "domain/user-type";
import React, { useCallback, useState } from "react";
import { authService } from "services/auth-service";
import { colors, desktop, row, stack } from "style";
import { useI18n } from "utils/i18n";

interface PasswordRecoveryFormProps {
	userType: UserType;
}

export const PasswordRecoveryForm: React.FC<PasswordRecoveryFormProps> = ({ userType }) => {
	const { format } = useI18n();
	const [email, setEmail] = useState("");
	const [error, setError] = useState<{ code: number } | null>(null);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	const recoverPassword = useCallback(
		async (event: React.FormEvent<HTMLFormElement>) => {
			event?.preventDefault();
			try {
				setLoading(true);
				setError(null);
				await authService.recoverPassword(userType, email);
				setSuccess(true);
			} catch (e: any) {
				e?.response?.status && setError({ code: e?.response?.status });
			} finally {
				setLoading(false);
			}
		},
		[email, userType]
	);

	return (
		<FormContainer>
			<Form onSubmit={recoverPassword}>
				<AppTitle>
					<img src="/images/logo.svg" alt="logo"/>
					<div>SOPHI</div>
				</AppTitle>

				<Title>{format("passwordRecovery.title")}</Title>
				{success ? (
					<Subtitle>{format("passwordRecovery.success")}</Subtitle>
				) : (
					<>
						<ErrorWrapper>
							<Fields>
								<EmailInput
									placeholder={format("passwordRecovery.emailPlaceholder")}
									value={email}
									onChange={e => setEmail(e.target.value)}
									autoComplete="username"
								/>
							</Fields>
							{error && (
								<ErrorMessage>
									{error.code === 401
										? format("passwordRecovery.error.incorrectEmail")
										: format("passwordRecovery.error.unknownError")}
								</ErrorMessage>
							)}
						</ErrorWrapper>
						<Button loading={loading}>{format("passwordRecovery.submit")}</Button>
					</>
				)}
			</Form>
		</FormContainer>
	);
};

const AppTitle = styled.div`
	${row("S", "flex-start", "center")}
	font-size: 27px;
	font-weight: bold;
	color: ${colors.black};

	div {
		position: relative;
		top: -3px;
	}

	img {
		width: 29px;
		height: 25px;
	}
`;

const Title = styled.h1`
	font-size: 27px;
	max-width: 250px;
`;
const Subtitle = styled.div`
	font-size: 15px;
	color: ${colors.black};
`;
const FormContainer = styled.div`
	${row(0, "center", "center")};

	@media ${desktop} {
		width: calc(50vw - 100px);
	}
`;
const Form = styled.form`
	${stack("XL")};
`;
const Fields = styled.div`
	${stack("M", "flex-start", "stretch")};
`;

const ErrorMessage = styled.div`
	color: ${colors.red};
	font-size: 12px;
`;

const ErrorWrapper = styled.div`
	${stack("S")};
	max-width: 250px;
`;
