import { isPatientMinor } from "utils/isPatientMinor";
import { AnesthesiaType } from "./anesthesia";
import { Document, DocumentType, SurgeryDocumentSource, SurgeryDocumentValidationStatus } from "./document";
import { Patient } from "./patient";
import { Scoring } from "./scoring";
import { Hpu } from "./hpu";
import { SupportTechnical } from "./support-technical";

export interface HCI {
	id: string;
	name: string;
	anesthesiaDepartmentPhoneNumber: string;
	documentTransferByEmailEnabled: boolean;
	documentTransferByFtpEnabled: boolean;
}

export interface Surgery {
	id: string;
	status: SurgeryStatus;
	creationDate: string;
	practitionerId: string;
	practitioner?: Practitioner;
	surgeryDate: string;
	surgeryDocuments: Document[];
	patient: Patient | null;
	hospitalId?: string;
	hciPatientId?: string;
	hciVisitId?: string;
	anesthesiaType?: AnesthesiaType;
	anesthesiaAppointmentDate?: string;
	anesthesiaAppointmentHonored?: boolean;
	anesthesiaFormAnswers?: string;
	anesthesiaHighRiskPatient?: boolean;
	anesthesiaDisabledPatient?: boolean;
	hospital?: HCI;
	statePatientDocuments?: SurgeryCompletionState;
	stateLegalDocuments?: SurgeryCompletionState;
	stateAnesthesiaAppointment?: SurgeryCompletionState;
	statePreAdmission?: SurgeryCompletionState;
	classificationCode?: string;
	laterality?: string;
	visitType: VisitType;
	visitStartDate?: string;
	visitEndDate?: string;
	documentsTxEnabled?: boolean;
	postOperatives?: PostOperative[];
	healthcareProvider?: HealthcareProvider;
	healthcareProviderId?: string | null;
	patientStatus?: string | null;
	legalGuardian1Status?: string | null;
	legalGuardian2Status?: string | null;
	ids?: string;
	dates?: string;
	times?: string;
	descriptions?: string;
	createdByJob: boolean;
	outsidePath?: string;
	remark?: string;
	place?: string;
	codeExt?: string;
	codeDescExt?: string;
	scoring?: Scoring;
	hpState?: string;
	needHealthcareProvider: boolean;
	healthcareProviderReason?: string;
	formParad: number;
	cancerType?: number;
	cancerStade?: number;
	scoreParad?: number;
	guidPivot?: string;
	signEngaged?: number;
}

export interface Secretary {
	id: string;
	userProfileId?: string;
	firstName: string;
	lastName: string;
	email: string;
	delegation?: boolean;
}

export interface Practitioner {
	id: string;
	userProfileId?: string;
	firstName: string;
	lastName: string;
	email: string;
	rppsCode: string;
	hospitals: { id: string; name: string }[];
	secretaries: { id: string; firstName: string; lastName: string; email: string; delegation: boolean }[];
	saveSign: boolean;
	nameSignature?: string;
	signature?: string;
	displayNotifSuivi: number;
	evaluation: number;
	isOptam: number;
}

export interface Speciality {
	id: string;
	name: string;
	whenCreated: string;
	whenUpdated?: string;
	whenDeleted: string;
}
export interface Coordinate {
	x: number,
	y:number,
}

export interface PostOperative {
	id: string;
	Surgery: Surgery;
	date: string;
	creationDate: string;
	updateDate: string;
	hospitals: { id: string; name: string };
	description: string;
	place: string;
}

export interface Module {
	id: string;
	name: string;
	type: string;
}

export interface HealthcareProvider {
	link: string | undefined;
	id: string;
	email: string;
	name: string;
	type: string;
	phoneNumber1: string;
	phoneNumber2: string | null;
	enabled: boolean;
}

export interface HealthcareProviderAgency {
	link: string | undefined;
	id: string;
	healthcareProvider: HealthcareProvider,
	email: string;
	name: string;
	type: string;
	phoneNumber1: string;
	phoneNumber2: string | null;
	enabled: boolean;
}
export interface FormValue {
	id: string;
	value: string;
	surgeryId: string;
	formFieldId: string;
	whenCreated: string;
	whenUpdated?: string;
	whenDeleted?: string;
	enabled: boolean;
}

export interface FormField {
	id: string;
	label: string;
	tag: string;
	type: string;
	value: string | null;
	enabled: boolean;
	docCustomId: string;
	formValues?: FormValue[];
}

export interface Hospital {
	id: string;
	name: string;
}

export interface DocCustomData {
	docCustomId: string;
	surgeryId: string;
	value: FormValueData[];
}

export interface FormValueData {
	formFieldId: string;
	value: string;
}
export interface DocCustom {
	id: string;
	hospitalId?: string;
	practitionerId?: string;
	surgeryDocumentTypeId?: string;
	surgeryDocumentTypeName?: string;
	surgeryCodeFrId?: string;
	healthcareProviderId?: string;
	range?: string;
	name: string;
	whenCreated: string;
	whenUpdated?: string;
	whenDeleted?: string;
	enabled: boolean;
	target: string;
	formFields?: FormField[];
	dlConnectorId?: string;
	treatmentStatus?: string;
}

export interface CCAM {
	id: string;
	codeCCAM: string;
	description: string;
	activity?: string;
	priceOptam?: string;
	price?: string;
	descriptionCustom: string;
}

export interface CancerType {
	id: string;
	description: string;
}
export enum SurgeryLaterality {
	Left = "Left",
	Right = "Right",
	Both = "Both",
	None = "None",
}

export enum SurgeryHealthCareProvider {
	Healing = "Cicatrisation",
	Perfusion = "Perfusion",
	Respiratory = "Respiratoire",
	Analgesia = "Analgésie",
	Nutrition = "Nutrition",
	InsulinTherapy = "Insulinothérapie",
}

export const surgeryHealthCareProviderValues = [
	SurgeryHealthCareProvider.Analgesia,
	SurgeryHealthCareProvider.Healing,
	SurgeryHealthCareProvider.InsulinTherapy,
	SurgeryHealthCareProvider.Nutrition,
	SurgeryHealthCareProvider.Perfusion,
	SurgeryHealthCareProvider.Respiratory,
];

export const lateralityValues = [
	SurgeryLaterality.None,
	SurgeryLaterality.Both,
	SurgeryLaterality.Left,
	SurgeryLaterality.Right,
];

export enum VisitType {
	Inpatient = "Inpatient",
	Outpatient = "Outpatient",
	External = "External",
}

export enum SurgeryStatus {
	Active = "Active",
	Archived = "Archived",
}

export enum MutualType {
	ALD = "ALD",
	CMU = "CMU",
	Classic = "Classique",
}

export enum hpState {
	TODO = "TODO",
	ACCEPTED = "ACCEPTED",
	ARCHIVED = "ARCHIVED",
	REFUSED = "REFUSED",
	DOCUMENTREJECTED = "DOCUMENTREJECTED",
	DOCUMENTWITHOUTSIGN = "DOCUMENTWITHOUTSIGN",
	DOCUMENTSIGNED = "DOCUMENTSIGNED",
	INFOADD = "INFOADD",
}

export enum CcamDpmMode {
	NONE = "None",
	FIXED_AMOUNT = "Fixed_amount",
	PERCENTAGE = "Percentage",
	TOTAL_AMOUNT= "Total_amount",
}

export enum CcamOptamType {
	NONE = "NONE",
	OPTAM = "OPTAM",
	NO_OPTAM = "NO_OPTAM",
}

export const visitTypeValues = [VisitType.Outpatient, VisitType.External, VisitType.Inpatient];

export type SurgeryUpdateDTO = Partial<
	Pick<
		Surgery,
		| "surgeryDate"
		| "hciPatientId"
		| "hciVisitId"
		| "anesthesiaType"
		| "anesthesiaAppointmentDate"
		| "anesthesiaAppointmentHonored"
		| "anesthesiaFormAnswers"
		| "anesthesiaHighRiskPatient"
		| "anesthesiaDisabledPatient"
		| "hospitalId"
		| "classificationCode"
		| "laterality"
		| "visitType"
		| "visitStartDate"
		| "visitEndDate"
		| "postOperatives"
		| "ids"
		| "dates"
		| "times"
		| "descriptions"
		| "outsidePath"
		| "remark"
		| "place"
		| "healthcareProvider"
		| "healthcareProviderReason"
	>
>;

export type PostOperativeUpdateDTO = Partial<Pick<PostOperative, "date" | "description" | "place">>;

export enum SurgeryCompletionState {
	NotApplicable = "NotApplicable",
	InProgress = "InProgress",
	ActionRequired = "ActionRequired",
	Completed = "Completed",
	Critical = "Critical",
}

export interface DataForm {
	key: string;
	value: string;
}

export interface PractitionerDpi {
	id: string;
	practitionerId: string;
	hospitalId: string;
	dpiId: string;
	lastname: string;
	firstname: string;
}

export function splitSurgeryDocuments(surgery: Surgery, patient: Patient, documentTypes: DocumentType[]) {
	const patientIsMinor = isPatientMinor(new Date(patient.birthDate), new Date(surgery.creationDate));
	let documentTypeIds = documentTypes
		.filter(
			docType =>
				docType.source == SurgeryDocumentSource.Patient && docType.filename != "livret-de-famille" ||
				patientIsMinor && docType.filename == "livret-de-famille"
		)
		.map(docType => docType.id);
	if (!surgery.createdByJob) {
		documentTypeIds = documentTypeIds.filter(doc => doc != "fa07f1442eee4cdbb2d1d06f97ce2545");
	}
	const { rejectedDocuments, missingDocuments, processingDocuments, okDocuments } = splitDocuments(
		documentTypeIds,
		surgery.surgeryDocuments
	);
	return { rejectedDocuments, missingDocuments, processingDocuments, okDocuments };
}

function splitDocuments(typeIds: string[], surgeryDocument: Document[]) {
	const rejectedDocuments: Document[] = [];
	const missingDocuments: string[] = [];
	const processingDocuments: Document[] = [];
	const okDocuments: Document[] = [];

	typeIds.forEach(typeId => {
		const existingDocument = surgeryDocument.find(d => d.documentType.id === typeId);
		if (!existingDocument) {
			missingDocuments.push(typeId);
		} else if (existingDocument.validationStatus === SurgeryDocumentValidationStatus.Rejected) {
			rejectedDocuments.push(existingDocument);
		} else if (existingDocument.validationStatus === SurgeryDocumentValidationStatus.Validated) {
			okDocuments.push(existingDocument);
		} else {
			processingDocuments.push(existingDocument);
		}
	});

	return { rejectedDocuments, missingDocuments, processingDocuments, okDocuments } as const;
}

export function practitionerHasFilledTheirInfo(practitioner: Practitioner, surgery: Surgery | null) {
	if (surgery === null) {
		return !!practitioner.email && !!practitioner.firstName && !!practitioner.lastName;
	} else {
		return !!practitioner.email && !!practitioner.firstName && !!practitioner.lastName;
	}
}

export function secretaryHasFilledTheirInfo(secretary: Secretary, surgery: Surgery | null) {
	if (surgery === null) {
		return !!secretary.email && !!secretary.firstName && !!secretary.lastName;
	} else {
		return !!secretary.email && !!secretary.firstName && !!secretary.lastName;
	}
}

export function hospitalHasFilledTheirInfo(hospitalId: string | null, surgery: Surgery | null) {
	if (surgery === null) {
		return !!hospitalId;
	} else {
		return !!hospitalId;
	}
}

export function hpuHasFilledTheirInfo(hpu: Hpu, surgery: Surgery | null) {
	if (surgery === null) {
		return !!hpu.email && !!hpu.hpuFirstName && !!hpu.hpuLastName;
	} else {
		return !!hpu.email && !!hpu.hpuFirstName && !!hpu.hpuLastName;
	}
}

export function supportTechnicalHasFilledTheirInfo(supportTechnical: SupportTechnical, surgery: Surgery | null) {
	if (surgery === null) {
		return !!supportTechnical.email && !!supportTechnical.firstName && !!supportTechnical.lastName;
	} else {
		return !!supportTechnical.email && !!supportTechnical.firstName && !!supportTechnical.lastName;
	}
}
