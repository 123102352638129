import styled from "@emotion/styled";
import React, { useState } from "react";
import { colors, sizes, stack } from "style";
import { useI18n } from "utils/i18n";
import {
	FIRST_SURGERY_PAGE,
	secretaryService,
} from "../../services/secretary-service";
import {
	Body as RtlBody,
	Cell as RtlCell,
	Header as RtlHeader,
	HeaderRow as RtlHeaderRow,
	Row as RtlRow,
	Table as RtlTable,
	TableNode,
} from "@table-library/react-table-library/table";

import { HeaderCellSort as RtlHeaderCellSort, SortToggleType, useSort } from "@table-library/react-table-library/sort";
import { useRowSelect } from "@table-library/react-table-library/select";
import { useTheme } from "@table-library/react-table-library/theme";
import { Pagination } from "../pagination";
import ReactPaginate from "react-paginate";
import { PaginationOptions } from "../../utils/pagination";
import EffacerIcon from "@assets/icons/effacer.svg";
import {
	commonService,
	useCommonPractitionerDocCustom
} from "../../services/common-service";
import { createModal } from "../../services/modal-service";
import { ConfirmModal } from "../modals/secretary/confirm-modal";
import { practitionerService } from "../../services/practitioner-service";
import EyeIcon from "@assets/icons/eye.svg";

export const PractitionerCustomDocumentSection: React.FC<{
	practitionerId?: string | undefined;
	secretaryId?: string | undefined;
}> = ({ practitionerId, secretaryId }) => {
	const [rtlSearch, setRtlSearch] = useState("");
	const [pageOptions, setPageOptions] = React.useState<PaginationOptions>(FIRST_SURGERY_PAGE);
	const {practitionerDocCustoms} = useCommonPractitionerDocCustom(pageOptions, rtlSearch);
	const {format, formatDate} = useI18n()
	const theme = useTheme({
		HeaderCell: `
		height: 60px;
		`,
	});
	const nodes: TableNode[] = [];
	if (practitionerDocCustoms && practitionerDocCustoms.content && practitionerDocCustoms.content.length > 0) {
		const contentSize = practitionerDocCustoms?.content.length;
		for (let row = 0; row < contentSize; row++) {
			const newNode = {
				id: practitionerDocCustoms.content[row].id,
				name: practitionerDocCustoms.content[row].name,
				surgeryDocumentTypeName: practitionerDocCustoms.content[row].surgeryDocumentTypeName,
				codeCcam: practitionerDocCustoms.content[row].codeCcam ?? "Tous",
				dlConnectorId: practitionerDocCustoms.content[row].dlConnectorId,
				hospitalName: practitionerDocCustoms.content[row].hospitalName ?? "-",
			};
			nodes.push(newNode)
		}
	}
	const data = { nodes };
	const handleRtlSearch = (input: string) => {
		setRtlSearch(input);
		setPageOptions(pagination => ({ ...pagination, page: 0 }));
	};

	/*
	useEffect(() => {
		if (secretaryId != undefined) {
			commonService.fetchPractitionerDocCustom(practitionerId as string, pageOptions, rtlSearch).then()
		} else {
			commonService.fetchPractitionerDocCustom(undefined, pageOptions, rtlSearch).then()
		}
	},[pageOptions, practitionerId, rtlSearch, secretaryId])
	 */
	
	const sort = useSort(
		data,
		{
			onChange: () => null,
		},
		{
			sortToggleType: SortToggleType.AlternateWithReset,
			sortFns: {
				CREATE_DATE: array => array.sort((a, b) => a.whenCreated.localeCompare(b.whenCreated)),
				STATUS: array => array.sort((a, b) => a.treatmentStatus.localeCompare(b.treatmentStatus)),
				TYPE: array => array.sort((a, b) => a.name.localeCompare(b.name)),
			},
		}
	);
	const select = useRowSelect(data, {
		onChange: () => null,
	})

	return (
	<MainContainer>
		<MainContent>
			<label htmlFor="search">
				Rechercher par code CCAM :&nbsp;
				<input
					id="search"
					type="text"
					value={rtlSearch}
					onChange={event => handleRtlSearch(event.target.value)}
					style={{ width: "180px", marginLeft: "10px", border: "solid #E6E6FA", borderRadius: "5px" }}
				/>
			</label>
			{ practitionerDocCustoms && practitionerDocCustoms.content.length == 0 ?
			<div>
				Aucunes données trouvées
			</div> :
			<RtlTable data={data} sort={sort} theme={theme} select={select}>
				{tableList => (
					<>
						<RtlHeader>
							<RtlHeaderRow>
								<StyledRtlHeaderCellSort
									resize
									sortKey="TYPE"
								>
									{format("type")}
								</StyledRtlHeaderCellSort>
								<StyledRtlHeaderCellSort
									resize
									sortKey="FILENAME"
								>
									{format("filename")}
								</StyledRtlHeaderCellSort>
								<StyledRtlHeaderCellSort
									resize
									sortKey="CCAM"
								>
									{format("ccam")}
								</StyledRtlHeaderCellSort>
								<StyledRtlHeaderCellSort
									resize
									sortKey="HOSPITAL"
								>
									{format("hospital")}
								</StyledRtlHeaderCellSort>
								<StyledRtlHeaderCellSort
									resize
									sortKey="ACTION"
								>
									{format("action")}
								</StyledRtlHeaderCellSort>
							</RtlHeaderRow>
						</RtlHeader>
						<RtlBody>
							{tableList.map(item => (
								<RtlRow key={item.id} item={item}>
									<StyledRtlCell>
										{item.surgeryDocumentTypeName}
									</StyledRtlCell>
									<StyledRtlCell>
										{item.name}
									</StyledRtlCell>
									<StyledRtlCell>
										{item.codeCcam}
									</StyledRtlCell>
									<StyledRtlCell>
										{item.hospitalName}
									</StyledRtlCell>

									<StyledRtlCell>
										{item.hospitalName == "-" && (
											<StyledEffacerIcon onClick={
												async (e: { preventDefault: () => void; }) => {
													e.preventDefault()
													const action = await createModal<string | null>(({ onClose }) => (
														<ConfirmModal onClose={onClose} object={item}/>
													));
													if (item && item.id != undefined && action === "dismissal") {
														if (secretaryId != undefined) {
															await secretaryService.deleteDocCustom(item.id, practitionerId as string).then();
															await commonService.fetchPractitionerDocCustom(practitionerId as string, pageOptions, rtlSearch).then();
														} else {
															await practitionerService.deleteDocCustom(item.id).then();
															await commonService.fetchPractitionerDocCustom(undefined, pageOptions, rtlSearch).then();
														}
													}
												}
											}/>
										)}
										<StyledEyeIcon onClick={
											async (e: { preventDefault: () => void; }) => {
												e.preventDefault()
												if (secretaryId != undefined) {
													await secretaryService.getDocCustom(secretaryId as string, item.dlConnectorId, item.name).then();
												} else {
													await practitionerService.getDocCustom(item.dlConnectorId, item.name).then();
												}
											}
										}/>
									</StyledRtlCell>
								</RtlRow>
							))}
						</RtlBody>
					</>
				)}
			</RtlTable>
			}
			{practitionerDocCustoms ? (
				<Pagination>
					<ReactPaginate
						containerClassName="pagination"
						onPageChange={newPage => setPageOptions({ ...pageOptions, page: newPage.selected })}
						pageCount={practitionerDocCustoms.total_pages}
						pageRangeDisplayed={
							pageOptions.page <= 1 ? 2 : pageOptions.page >= practitionerDocCustoms.total_pages - 2 ? 3 : 1
						}
						marginPagesDisplayed={1}
						forcePage={pageOptions.page}
						previousLabel=""
						nextLabel=""
					/>
				</Pagination>
			) : null}
		</MainContent>
	</MainContainer>
	);
}

const MainContainer = styled.div`
    ${stack("M", "flex-start", "center")};
    width: 100%;
    border-radius: 26px;
    box-shadow: 0 32px 114px 0 rgba(0, 0, 0, 0.1);
    background-color: ${colors.white};
    padding: 15px ${sizes.L};
    flex-grow: 1;
    position: relative;
`;

const MainContent = styled.div<{ isLoading?: boolean }>`
	opacity: ${props => (props.isLoading ? 0.33 : 1)};
	transition: opacity 0.2s linear;
	flex-grow: 1;
	width: 100%;
`;

const StyledRtlHeaderCellSort = styled(RtlHeaderCellSort)`
	font-size: 11px;
`;

const StyledRtlCell = styled(RtlCell)`
	font-size: 14px;
	padding-top: 20px
`;

const StyledEyeIcon = styled(EyeIcon)`
		width: 58px;
		height: 29px;
    vertical-align: bottom;
    margin-bottom: -2px;
`
const StyledEffacerIcon = styled(EffacerIcon)<{ selected: boolean }>`
    width: 19px;
    height: 19px;
    path {
        fill: ${colors.black}; /* Couleur initiale */
    }

    &:hover {
        path {
            fill: ${colors.pink}; /* Couleur au survol */
        }
    }
`;
