import { Surgery } from "./surgery";
import { UserProfile } from "./user-profile";

export type PatientGender = "Male" | "Female";

export interface Patient {
	birthDate: string;
	email: string | undefined;
	firstName: string;
	id: string;
	gender: PatientGender | null;
	lastName: string;
	onBoardingAt?: string;
	phoneNumber1: string | null;
	phoneNumber2: string | null;
	address: string;
	pharmacy: string;
	nurse: string;
	nextSurgery?: {
		id: string;
		surgeryDate: string;
		anesthesiaHighRiskPatient?: number;
		anesthesiaDisabledPatient?: number;
	};
	legalGuardian1?: Patient;
	legalGuardian2?: Patient;
	userProfile?: UserProfile;
	riskScore?: number | null;
	lg1PhoneNumber1?: string;
	lg2PhoneNumber1?: string;
}
export interface Pharmacy {
	name: string,
	latitude: number,
	longitude: number,
	vicinity: string,
	phoneNumber?: string,
}

export interface Nurse {
	name: string,
	latitude: number,
	longitude: number,
	vicinity: string,
	phoneNumber?: string,
}

export function patientHasFilledTheirInfo(patient: Patient, surgery: Surgery | null): boolean {
	// la difficulté ici est que le patient arrive sous 3 formes différentes (patient, surgery.patient, ou patient tuteur)
	if (surgery?.patient != null) {
		return (
			(!!patient.phoneNumber1 ||
				(patient.phoneNumber1 == null &&
					(!!surgery.patient?.legalGuardian1?.phoneNumber1 || !!surgery.patient?.legalGuardian2?.phoneNumber1) ||
					patient.lg1PhoneNumber1 != null ||
					patient.lg2PhoneNumber1 != null)
			) &&
			!!patient.gender &&
			!!patient.address &&
			!!surgery.patient?.nurse &&
			!!surgery.patient?.pharmacy &&
			!!surgery.patient?.gender &&
			!!surgery.patient?.address
		);
	} else {
		return (
			(!!patient.phoneNumber1 ||
				(patient.phoneNumber1 == null &&
					(patient.legalGuardian1?.phoneNumber1 != null || patient?.legalGuardian2?.phoneNumber1 != null) ||
					patient.lg1PhoneNumber1 != null ||
					patient.lg2PhoneNumber1 != null)
			) &&
			!!patient.gender &&
			!!patient.address
		);
	}
}

export function patientHasFilledScoringForm(surgery: Surgery) {
	if (
		(surgery && surgery.scoring && surgery.scoring.when_created) ||
		Date.parse(surgery.creationDate) < Date.parse("2023-02-07 00:00:00.000000")
	) {
		return surgery.scoring && surgery.scoring.when_created
			? surgery?.scoring?.when_created.length > 0
			: Date.parse(surgery.creationDate) < Date.parse("2023-02-07 00:00:00.000000");
	} else return false;
}

export function formParadOk(surgery: Surgery) {
	if (!surgery.formParad && surgery.practitioner?.evaluation == 1) {
		return false
	} else if (surgery.practitioner?.evaluation == 1 && surgery.formParad) {
		return true;
	} else if (surgery.practitioner?.evaluation == 1 || surgery.practitioner?.evaluation == undefined) {
		return true;
	}
}
