import styled from "@emotion/styled";
import { DocumentErrorIcon } from "@assets/icons/document-error.svg";
import { Button } from "components/button";
import React, { useCallback, useState } from "react";
import { ModalCardContainer, ModalCloseIcon } from "services/modal-service";
import { practitionerService } from "services/practitioner-service";
import { colors, row, stack } from "style";
import { useI18n } from "utils/i18n";
import {motion} from "framer-motion";
import {Patient} from "../../domain/patient";
import {secretaryService} from "../../services/secretary-service";
interface ConfirmSecretaryModalProps {
	onClose: (patientId: string | null) => void;
	firstName: string;
	lastName: string;
	birthDate: string;
	email?: string;
	phoneNumber1?: string;
	updateEmailOnly?: boolean;
	patient: Patient | null;
	practitionerId?: string | null;
}

export const ConfirmSecretaryModal: React.FC<ConfirmSecretaryModalProps> = ({ onClose, firstName, lastName, birthDate, email, phoneNumber1, updateEmailOnly, patient, practitionerId }) => {
	const { format } = useI18n();
	const [loading, setLoading] = useState(false);

	const save = useCallback(async ()=>{
		try{
			setLoading(true)
			if(practitionerId && patient){
				await secretaryService.editPatient(patient, practitionerId as string,{firstName,lastName,birthDate,email,phoneNumber1},null);
				onClose(null)
			}else if(patient){
				await practitionerService.editPatient(patient, {firstName,lastName,birthDate,email,phoneNumber1},null);
				onClose(null)
			}
		}catch(e: any){
			alert(format( "secretary.modal.confirm.patient.email.exist"))
			throw e.message
		}
		finally {
			setLoading(false)
		}
	},[loading])
	return (

			<ModalContainer>
				<form
					onSubmit={e => {
						e.preventDefault();
						save();
					}}
				>
				<ModalCloseIcon onClick={() => onClose(null)} />
				<Content>
					<DocumentsMessageContainer>
						<ColorDocumentErrorIcon color={colors.orange} />
					<Title>
						{format( "secretary.modal.confirm.patient.title")}
					</Title>
					</DocumentsMessageContainer>
					{updateEmailOnly ? (
						<>
							<Text>
								{format("secretary.modal.confirm.patient.emailOnly.warning")}
							</Text>
							<Text>
								{format("secretary.modal.confirm.patient.emailOnly.info")}
							</Text>
						</>
					) : (
						<>
							<Text>
								{format("secretary.modal.confirm.patient.warning")}
							</Text>
							<Text>
								{format("secretary.modal.confirm.patient.infos")}
							</Text>
						</>
					)}
					<Text>
						{format("secretary.modal.confirm.patient.confirmation")}
					</Text>
					<Buttons>
						<Button
							loading={loading}>
							{format("secretary.modal.confirm.patient.confirmation.button")}
						</Button>
						<Button secondary onClick={() => onClose(null)}>
							{format( "secretary.modal.confirm.patient.cancel.button")}
						</Button>
					</Buttons>
				</Content>

				</form>
			</ModalContainer>
	);
};


const ModalContainer = styled(ModalCardContainer)`
	padding: 30px;
	${row(0, "center", "center")};
`;

const DocumentsMessageContainer = styled.div`
	${row("S", "flex-start", "flex-start")};
`;

const ColorDocumentErrorIcon = styled(DocumentErrorIcon)<{ color: string }>`
	width: 32px;
	height: 32px;
	path {
		fill: ${props => props.color};
	}
`;
const Content = styled.div`
	${stack("M")};
	max-width: 500px;
`;
const Title = styled(motion.div)`
	font-size: 20px;
	font-weight: bold;
	color: ${colors.black};
`;

const Buttons = styled.div`
	${row("S", "flex-end", "flex-end")}
`;

const Text = styled(motion.span)<{ textColor?: string }>`
	font-size: 13px;
	color: ${props => props.textColor?? "black"};
`;