import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { colors, row } from "style";

interface BooleanInputProps {
	value: boolean;
	onChange?: (value: boolean) => void;
	className?: string;
	required?: boolean;
	name?: string;
	disabled?: boolean;
	children?: React.ReactNode; // Ajoutez cette ligne
}

export const CheckboxInput: React.FC<BooleanInputProps> = ({ value, onChange,disabled, children, className, required, name }) => {
	return (
		<InputWrapper className={className} onClick={() => onChange?.(!value)}>
			<CheckedInput name={name} type="checkbox" isChecked={value} required={required} disabled={disabled}/>
			<div>{children}</div>
		</InputWrapper>
	);
};

const InputWrapper = styled.div`
	${row("S", "flex-start", "center")};
	cursor: pointer;
`;

const CheckedInput = styled.input<{ isChecked: boolean }>`
	appearance: none;
	position: relative;
	width: 25px;
	height: 25px;
	border-radius: 6px;
	border: solid 1px ${colors.grey};
	background-color: ${colors.white};
	outline: none;
	margin: 0;
	cursor: pointer;
	${({ isChecked }) =>
		isChecked &&
		css`
			:after {
				content: "";
				position: absolute;
				height: 15px;
				width: 15px;
				top: 4px;
				left: 4px;
				background-color: ${colors.pink};
				border-radius: 3px;
			}
		`};

	&:focus-visible {
		border: solid 1px transparent;
		box-shadow: 0 0 0 2px ${colors.green} inset;
		outline: 0;
	}
`;
