import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { colors, desktop, sizes, stack } from "style";

export const Section = styled(motion.div)`
	${stack("L", "flex-start", "stretch")}
  width: 100%;
	@media ${desktop} {
		padding: ${sizes.L};
		border-radius: 12px;
		box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.05);
		background-color: ${colors.white};
		flex-grow: 1;
	}
`;

export const SectionTitle = styled(motion.div)`
	font-size: 20px;
	font-weight: bold;
	color: ${colors.black};
`;
