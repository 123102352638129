import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "components/button";
import { DocumentsMessage } from "components/documents/document-message";
import { SelectInput } from "components/inputs/select-input";
import { TextInput } from "components/inputs/text-input";
import { Nurse, Patient, PatientGender, patientHasFilledTheirInfo, Pharmacy } from "domain/patient";
import { fullName } from "domain/utils";
import { HTMLMotionProps, motion } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";
import { colors, desktop, mobile, row, sizes, stack } from "style";
import { useI18n } from "utils/i18n";
import { SurgeryStep } from "../../domain/surgery-step";
import { CheckboxInput } from "../inputs/checkbox-input";
import { patientService } from "../../services/patient-service";

interface InfoSectionProps extends HTMLMotionProps<"div"> {
	patient: Patient | null;
	onEdit?: (data: Partial<Pick<Patient, "gender" | "phoneNumber1" | "phoneNumber2" | "address" |"pharmacy" | "nurse">>) => Promise<void>;
	overrideTitle?: string;
	step?: SurgeryStep;
	tutored?: boolean;
	isTutor?: boolean;
	surgeryId?: string;
}
export const PatientInfoSection: React.FC<InfoSectionProps> = ({
	patient,
	onEdit,
	overrideTitle,
	step,
	tutored,
	isTutor = false,
	surgeryId,
	...props
}) => {
	const { format, formatDate, formatGender } = useI18n();
	const [editing, setEditing] = useState(false);

	const [gender, setGender] = useState<PatientGender | null>(null);
	const [address, setAddress] = useState("");
	const [phoneNumber1, setPhoneNumber1] = useState("");
	const [phoneNumber2, setPhoneNumber2] = useState("");
	const [phoneNumberError, setPhoneNumberError] = useState("");
	const [streetNumber, setStreetNumber] = useState("");
	const [addressLine2, setAddressLine2] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [city, setCity] = useState("");
	const [getPharmacy, setGetPharmacy] = useState(false);
	const [getNurse, setGetNurse] = useState(false);
	const [didntGetPharmacy, setDidntGetPharmacy] = useState(false);
	const [didntGetNurse, setDidntGetNurse] = useState(false);
	const [loadingPharmacies, setLoadingPharmacies] = useState(true);
	const [loadingNurses, setLoadingNurses] = useState(true);
	const [pharmacies, setPharmacies] = useState<Pharmacy[]>([]);
	const [nurses, setNurses] = useState<Nurse[]>([]);
	const [patientPharmacy, setPatientPharmacy] = useState<Pharmacy>();
	const [patientNurse, setPatientNurse] = useState<Nurse>();
	const [otherNurseName, setOtherNurseName] = useState<string>("");
	const [otherPharmacyName, setOtherPharmacyName] = useState<string>("");
	const [otherNursePostalCode, setOtherNursePostalCode] = useState<string>("");
	const [otherPharmacyPostalCode, setOtherPharmacyPostalCode] = useState<string>("");
	const [otherNurseCity, setOtherNurseCity] = useState<string>("");
	const [otherPharmacyCity, setOtherPharmacyCity] = useState<string>("");

	useEffect(() => {
		if (getPharmacy && pharmacies.length === 0) {
			const timeoutPharmacy = setTimeout(() => {
				setLoadingPharmacies(false); // Passer le chargement à faux après 5 secondes
			}, 5000); // 5000 ms = 5 secondes
			return () => clearTimeout(timeoutPharmacy); // Nettoyer le timeout lors du démontage du composant
		} else {
			setLoadingPharmacies(true);
		}
	}, [getPharmacy, pharmacies]);
	useEffect(() => {
		if (getNurse && nurses.length === 0) {
			const timeout = setTimeout(() => {
				setLoadingNurses(false); // Passer le chargement à faux après 5 secondes
			}, 5000); // 5000 ms = 5 secondes
			return () => clearTimeout(timeout); // Nettoyer le timeout lors du démontage du composant
		} else {
			setLoadingNurses(true); // Si getNurse est faux ou il y a des nurses, passer le chargement à faux immédiatement
		}
	}, [getNurse, nurses]);
	useEffect(() => {
		if (patient && patient.address) {
			const addressParts = patient.address.split(",");
			let streetNumberPart: string;
			let addressLine2Part = "";
			let postalCodePart: string;
			let cityPart: string;
			if (addressParts.length == 4) {
				streetNumberPart = addressParts[0].trim();
				addressLine2Part = addressParts[1] ? addressParts[1].trim() : "";
				postalCodePart = addressParts[2] ? addressParts[2].trim() : "";
				cityPart = addressParts[3] ? addressParts[3].trim() : "";
			} else {
				streetNumberPart = addressParts[0].trim();
				postalCodePart = addressParts[1] ? addressParts[1].trim() : "";
				cityPart = addressParts[2] ? addressParts[2].trim() : "";
				addressLine2Part = "";
			}
			setStreetNumber(streetNumberPart);
			setAddressLine2(addressLine2Part);
			setPostalCode(postalCodePart);
			setCity(cityPart);
		}
	}, [patient]);
	const setEditingAndResetFields = useCallback(
		(editing: boolean) => {
			setEditing(editing);
			setGender(patient?.gender ?? null);
			setAddress(patient?.address ?? "");
			setPhoneNumber1(patient?.phoneNumber1 ?? "");
			setPhoneNumber2(patient?.phoneNumber2 ?? "");
		},
		[patient?.address, patient?.gender, patient?.phoneNumber1, patient?.phoneNumber2]
	);
	const getPharmacyList = useCallback(async (adresse: string) => {
		if((streetNumber && postalCode && city) != ""){
			const listPharmacy = await patientService.getPharmacies(`${streetNumber} ${addressLine2} ${postalCode} ${city}`)
			setPharmacies(listPharmacy)
		}
		else {
			const listPharmacy = await patientService.getPharmacies(adresse)
			setPharmacies(listPharmacy)
		}
	},[streetNumber, addressLine2, postalCode, city])
	const getNurseList = useCallback(async (adresse: string) => {
		if((streetNumber && postalCode && city) != ""){
			const listNurse = await patientService.getNurses(`${streetNumber} ${addressLine2} ${postalCode} ${city}`)
			setNurses(listNurse)
		}
		else {
			const listNurse = await patientService.getNurses(adresse)
			setNurses(listNurse)
		}
	},[streetNumber, addressLine2, postalCode, city])
	const save = useCallback(async () => {
		const fullAddress = `${streetNumber}${addressLine2 ? ", " + addressLine2 : ""}, ${postalCode}, ${city}`;
		const pharmacy = didntGetPharmacy ? format("patient.info.pharmacy.didnt.get") : otherPharmacyName.length > 0 ? `${otherPharmacyName}, ${otherPharmacyPostalCode}, ${otherPharmacyCity}` : `${patientPharmacy?.name}, ${patientPharmacy?.vicinity}, ${patientPharmacy?.phoneNumber}`
		const nurse = didntGetNurse ? format("patient.info.nurse.didnt.get") : otherNurseName.length > 0 ? `${otherNurseName}, ${otherNursePostalCode}, ${otherNurseCity}` : `${patientNurse?.name}, ${patientNurse?.vicinity}, ${patientNurse?.phoneNumber}`
		onEdit?.({
			gender: gender ? gender : undefined,
			phoneNumber1: phoneNumber1 ? phoneNumber1 : undefined,
			phoneNumber2: phoneNumber2 ? phoneNumber2 : undefined,
			address: fullAddress ? fullAddress : undefined,
			pharmacy: pharmacy? pharmacy : undefined,
			nurse: nurse ? nurse : undefined
		});
		setEditingAndResetFields(false);
	}, [
		streetNumber,
		addressLine2,
		city,
		postalCode,
		gender,
		onEdit,
		phoneNumber1,
		phoneNumber2,
		setEditingAndResetFields,
		didntGetPharmacy,
		otherPharmacyName,
		otherPharmacyPostalCode,
		otherPharmacyCity,
		patientPharmacy,
		patientNurse,
		didntGetNurse,
		otherNurseName,
		otherNursePostalCode,
		otherNurseCity
	]);

	return (
		<Encart>
			<form
				onSubmit={e => {
					e.preventDefault();
					// Validation du numéro de téléphone avec le pattern
					const phoneNumberPattern = /^(06|07|\+336|\+337|00336|00337)[0-9]{8}$/;
					if (phoneNumber1 && !phoneNumberPattern.test(phoneNumber1)) {
						setPhoneNumberError(
							format("patient.info.phone.error")
						);
						return;
					}

					if (gender == null) {
						setPhoneNumberError(
							format("patient.error.gender")
						);
						return;
					}

					save().then();
				}}
			>
				<Section {...props}>
					{!patient ? (
						<Info>
							<SkeletonLine />
							<SkeletonLine />
							<SkeletonLine />
							<SkeletonLine />
						</Info>
					) : !editing ? (
						<>
							{onEdit && !patientHasFilledTheirInfo(patient, null) && (
								<DocumentsMessage color="orange" label={format("patient.dashboard.infos.missing")} />
							)}
							<Info>
								<InfoField key="name" singleLine><Label>Nom & prénom :</Label>{fullName(patient)}</InfoField>
								<InfoField key="birthDate" singleLine>
									<Label>Date de naissance :</Label>
									{formatDate(patient.birthDate, { day: "2-digit", month: "long", year: "numeric" })}
								</InfoField>
								<InfoField key="email" singleLine title={patient.email}>
									<Label>Email : </Label>
									{patient.email}
								</InfoField>
								{patient.gender && <InfoField key="gender" singleLine><Label>Genre à la naissance :</Label> {formatGender(patient.gender)}</InfoField>}
								{patient.address && <InfoField key="address" singleLine><Label>Adresse :</Label> {patient.address}</InfoField>}
								{patient.phoneNumber1 && <InfoField key="phoneNumber1" singleLine><Label>Numéro de téléphone :</Label> {patient.phoneNumber1}</InfoField>}
								{patient.phoneNumber2 && <InfoField key="phoneNumber2" singleLine><Label>Numéro de téléphone :</Label> {patient.phoneNumber2}</InfoField>}
								{!isTutor && patient.pharmacy && <InfoField key="pharmacy" singleLine><Label>{format("patient.info.pharmacy")} :</Label>{patient.pharmacy.toUpperCase()}</InfoField>}
								{!isTutor && patient.nurse && <InfoField key="nurse" singleLine><Label>{format("patient.info.nurse")} :</Label> {patient.nurse.toUpperCase()}</InfoField>}
								<Buttons>
									{onEdit && patient && !editing ? (
										<Button onClick={() => setEditingAndResetFields(true)} type="button">
											{patientHasFilledTheirInfo(patient, null)
												? format("patient.dashboard.info.update")
												: format("patient.dashboard.info.edit")}
										</Button>
									) : null}
								</Buttons>
							</Info>
						</>
					) : (
						<Info>
							<InfoField key="name">{fullName(patient)}</InfoField>
							<InfoField key="birthDate">
								{formatDate(patient.birthDate, { day: "2-digit", month: "long", year: "numeric" })}
							</InfoField>
							<InfoField key="email" singleLine title={patient.email}>
								{patient.email}
							</InfoField>
							{patient.gender ? (
								<InfoField key="gender">{formatGender(patient.gender)}</InfoField>
							) : (
								<InfoField key="gender">
									<SelectInput<PatientGender>
										value={gender}
										innerId="gender"
										onChange={setGender}
										placeholder={format("patient.dashboard.info.genderPlaceholder")}
										options={["Male", "Female"]}
										itemRenderer={gender => {
											switch (gender) {
												case "Male":
													return format("gender.male");
												case "Female":
													return format("gender.female");
												/*case "Other":
                                                return format("gender.other");*/
											}
										}}
										required
										autoFocus
									/>
								</InfoField>
							)}
							<InfoField key="streetNumber">
								<TextInput
									required
									placeholder={format("patient.dashboard.info.streetNumberPlaceholder")}
									value={streetNumber}
									onChange={e => setStreetNumber(e.target.value)}
								/>
							</InfoField>
							<PostalRow>
								<InfoField key="addressLine2">
									<TextInput
										placeholder={format("patient.dashboard.info.addressLine2Placeholder")}
										value={addressLine2}
										onChange={e => setAddressLine2(e.target.value)}
									/>
								</InfoField>
								<InfoField key="postalCode">
									<TextInput
										required
										placeholder={format("patient.dashboard.info.postalCodePlaceholder")}
										value={postalCode}
										onChange={e => setPostalCode(e.target.value)}
									/>
								</InfoField>
								<InfoField key="city">
									<TextInput
										required
										placeholder={format("patient.dashboard.info.cityPlaceholder")}
										value={city}
										onChange={e => setCity(e.target.value)}
									/>
								</InfoField>
							</PostalRow>
							{!tutored ? (
								<>
									<InfoField key="phoneNumber1">
										<TextInput
											type="tel"
											required
											placeholder={format("patient.dashboard.info.phoneNumber1Placeholder")}
											value={phoneNumber1}
											pattern="^(06|07|\+336|\+337|00336|00337)[0-9]{8}$"
											onChange={e => setPhoneNumber1(e.target.value)}
											title="Le numéro de téléphone doit commencer par 06, 07, +336, +337, 00336 ou 00337, suivi de 8 chiffres."
										/>
										{phoneNumberError && <div className="error-message">{phoneNumberError}</div>}
									</InfoField>
									<InfoField key="phoneNumber2">
										<TextInput
											type="tel"
											placeholder={format("patient.dashboard.info.phoneNumber2Placeholder")}
											value={phoneNumber2}
											pattern="(0|\+33|0033)[1-9][0-9]{8}"
											onChange={e => setPhoneNumber2(e.target.value)}
										/>
									</InfoField>
								</>
							) : ("")}
							{
								!isTutor && <>
									<PostalRow>
										<InfoField key="pharmacy">
											<RadioButton
												type="radio"
												name="pharmacy"
												value="Text"
												checked={getPharmacy}
												onChange={() => {
													setDidntGetPharmacy(false)
													setGetPharmacy(true)
													getPharmacyList(patient?.address).then()
												}}/>
											<RadioButtonLabel onClick={() =>{
												setDidntGetPharmacy(false)
												setGetPharmacy(true)
												getPharmacyList(patient?.address).then()
											}}>{format("patient.info.pharmacy.get")}</RadioButtonLabel>
											<RadioButton
												type="radio"
												name="unknownPharmacy"
												value="Text"
												checked={didntGetPharmacy}
												onChange={() => {
													setGetPharmacy(false)
													setDidntGetPharmacy(true)
												}}/>
											<RadioButtonLabel onClick={() =>{
												setGetPharmacy(false)
												setDidntGetPharmacy(true)
											}
											}>{format("patient.info.pharmacy.didnt.get")}</RadioButtonLabel>
										</InfoField>
									</PostalRow>
									{pharmacies.length > 0 && getPharmacy ?
										<PharmacyStack>
											{pharmacies.map((pharmacy, index) =>
												<PharmacyRow selected={patientPharmacy == pharmacies[index]} key={index} onClick={() => {
													setPatientPharmacy(pharmacies[index])
												}}>
													<CheckboxInput value={patientPharmacy == pharmacies[index]} onChange={() => {
														patientPharmacy == pharmacies[index] ? setPatientPharmacy(undefined) : setPatientPharmacy(pharmacies[index])
														setOtherPharmacyCity("")
														setOtherPharmacyName("")
														setOtherPharmacyPostalCode("")
													}}/>
													<PharmacyStack>
														<Row><RightIcon src="/icons/pharmacie.svg"/>{pharmacy.name},</Row>
														<Row><RightIcon src="/icons/location.svg"/>{pharmacy.vicinity}</Row>
														<Row><RightIcon src="/icons/phone-grey2.svg"/>{pharmacy.phoneNumber}</Row>
													</PharmacyStack>
												</PharmacyRow>
											)}
											<PharmacyRow>
												<p>{format("patient.info.pharmacy.other")} </p>
												<TextInput
													value={otherPharmacyName.toUpperCase()}
													onChange={e => {
														setPatientPharmacy(undefined)
														setOtherPharmacyName(e.target.value)}}
													placeholder={format("patient.info.pharmacy.other.placeholder.name")}
													icon="/icons/pharmacie.svg"
													autoCapitalize="on"
												/>
												<TextInput
													value={otherPharmacyPostalCode}
													onChange={e =>
													{
														setPatientPharmacy(undefined)
														setOtherPharmacyPostalCode(e.target.value)
													}}
													placeholder={format("patient.info.pharmacy.other.placeholder.postal.code")}
													icon="/icons/pharmacie.svg"
													autoCapitalize="on"
												/>
												<TextInput
													value={otherPharmacyCity.toUpperCase()}
													onChange={e => {
														setPatientPharmacy(undefined)
														setOtherPharmacyCity(e.target.value)
													}}
													placeholder={format("patient.info.pharmacy.other.placeholder.city")}
													icon="/icons/pharmacie.svg"
													autoCapitalize="on"
												/>
											</PharmacyRow>
										</PharmacyStack> : getPharmacy && pharmacies.length == 0 &&
										<PostalRow>
											<>
												{loadingPharmacies ? (
													<SpinnerContainer
														key="spinner"
														variants={{ idle: { y: 100 }, loading: { y: 0 } }}
														transition={{ type: "spring", stiffness: 1000, damping: 60 }}
														exit={{ y: 100 }}
														initial="idle"
														animate={loadingPharmacies ? "loading" : "idle"}
													>
														<Spinner src="/icons/spinner-grey.svg"/>
													</SpinnerContainer>
												):
													<PharmacyStack>
														<p>{format("patient.info.pharmacy.didnt.get.message")}</p>
														<PharmacyRow>
															<TextInput
																value={otherPharmacyName.toUpperCase()}
																onChange={e => {
																	setPatientPharmacy(undefined)
																	setOtherPharmacyName(e.target.value)}}
																placeholder={format("patient.info.pharmacy.other.placeholder.name")}
																icon="/icons/pharmacie.svg"
																autoCapitalize="on"
															/>
															<TextInput
																value={otherPharmacyPostalCode}
																onChange={e =>
																{
																	setPatientPharmacy(undefined)
																	setOtherPharmacyPostalCode(e.target.value)
																}}
																placeholder={format("patient.info.pharmacy.other.placeholder.postal.code")}
																icon="/icons/pharmacie.svg"
																autoCapitalize="on"
															/>
															<TextInput
																value={otherPharmacyCity.toUpperCase()}
																onChange={e => {
																	setPatientPharmacy(undefined)
																	setOtherPharmacyCity(e.target.value)
																}}
																placeholder={format("patient.info.pharmacy.other.placeholder.city")}
																icon="/icons/pharmacie.svg"
																autoCapitalize="on"
															/>
														</PharmacyRow>
													</PharmacyStack>
												}
											</>
										</PostalRow>}
									<PostalRow>
										<InfoField key="nurse">
											<RadioButton
												type="radio"
												name="nurse"
												value="Text"
												checked={getNurse}
												onChange={() => {
													setDidntGetNurse(false)
													setGetNurse(true)
													getNurseList(patient?.address).then()
												}}/>
											<RadioButtonLabel onClick={() =>{
												setDidntGetNurse(false)
												setGetNurse(true)
												getNurseList(patient?.address).then()
											}}>{format("patient.info.nurse.get")}</RadioButtonLabel>
											<RadioButton
												type="radio"
												name="unknownNurse"
												value="Text"
												checked={didntGetNurse}
												onChange={() => {
													setGetNurse(false)
													setDidntGetNurse(true)
												}}/>
											<RadioButtonLabel onClick={() =>{
												setGetNurse(false)
												setDidntGetNurse(true)
											}
											}>{format("patient.info.nurse.didnt.get")}</RadioButtonLabel>
										</InfoField>
									</PostalRow>
									{
										nurses.length > 0 && getNurse ?
											<PharmacyStack>
												{nurses.map((nurse, index) =>
													<PharmacyRow selected={patientNurse == nurses[index]} key={index} onClick={() => {
														setPatientNurse(nurses[index])
													}}>
														<CheckboxInput value={patientNurse == nurses[index]} onChange={() => patientNurse == nurses[index] ? setPatientNurse(undefined) : setPatientNurse(nurses[index])}/>
														<PharmacyStack>
															<Row><RightIcon src="/icons/nurse.svg"/>  {nurse.name},</Row>
															<Row><RightIcon src="/icons/location.svg"/> {nurse.vicinity}</Row>
															<Row><RightIcon src="/icons/phone-grey2.svg"/> {nurse.phoneNumber}</Row>
														</PharmacyStack>
													</PharmacyRow>
												)}
												<PharmacyRow>
													<p>{format("patient.info.pharmacy.other")}</p>
													<TextInput
														value={otherNurseName.toUpperCase()}
														onChange={e => {
															setPatientNurse(undefined)
															setOtherNurseName(e.target.value)}}
														placeholder={format("patient.info.pharmacy.other.placeholder.name")}
														icon="/icons/nurse.svg"
														autoCapitalize="on"
													/>
													<TextInput
														value={otherNursePostalCode}
														onChange={e =>
														{
															setPatientNurse(undefined)
															setOtherNursePostalCode(e.target.value)
														}}
														placeholder={format("patient.info.pharmacy.other.placeholder.postal.code")}
														icon="/icons/nurse.svg"
														autoCapitalize="on"
													/>
													<TextInput
														value={otherNurseCity.toUpperCase()}
														onChange={e => {
															setPatientNurse(undefined)
															setOtherNurseCity(e.target.value)
														}}
														placeholder={format("patient.info.pharmacy.other.placeholder.city")}
														icon="/icons/nurse.svg"
														autoCapitalize="on"
													/>
												</PharmacyRow>
											</PharmacyStack> : getNurse && nurses.length == 0 &&
											<PostalRow>
												<>
													{loadingNurses ? (
														<SpinnerContainer
															key="spinner"
															variants={{ idle: { y: 100 }, loading: { y: 0 } }}
															transition={{ type: "spring", stiffness: 1000, damping: 60 }}
															exit={{ y: 100 }}
															initial="idle"
															animate={loadingNurses ? "loading" : "idle"}
														>
															<Spinner src="/icons/spinner-grey.svg"/>
														</SpinnerContainer>
													) :
														<PharmacyStack>
															<p>{format("patient.info.nurse.didnt.get.message")}</p>
															<PharmacyRow>
																<TextInput
																	value={otherNurseName.toUpperCase()}
																	onChange={e => {
																		setPatientNurse(undefined)
																		setOtherNurseName(e.target.value)}}
																	placeholder={format("patient.info.pharmacy.other.placeholder.name")}
																	icon="/icons/nurse.svg"
																	autoCapitalize="on"
																/>
																<TextInput
																	value={otherNursePostalCode}
																	onChange={e =>
																	{
																		setPatientNurse(undefined)
																		setOtherNursePostalCode(e.target.value)
																	}}
																	placeholder={format("patient.info.pharmacy.other.placeholder.postal.code")}
																	icon="/icons/nurse.svg"
																	autoCapitalize="on"
																/>
																<TextInput
																	value={otherNurseCity.toUpperCase()}
																	onChange={e => {
																		setPatientNurse(undefined)
																		setOtherNurseCity(e.target.value)
																	}}
																	placeholder={format("patient.info.pharmacy.other.placeholder.city")}
																	icon="/icons/nurse.svg"
																	autoCapitalize="on"
																/>
															</PharmacyRow>
														</PharmacyStack>
													}
												</>
											</PostalRow>
									}
								</>
							}
							<Buttons>
								<Button
									key="submit"
									type="submit"
									initial={{ opacity: 0 }}
									animate={{ opacity: 1, transition: { delay: 0.3 } }}
									disabled={(!getPharmacy && !didntGetPharmacy //aucuns des radios bouton n'est cochés
										|| !getNurse && !didntGetNurse //aucuns des radios bouton n'est cochés
										|| getNurse && otherNurseName.length == 0 && patientNurse == null //le patient connait son IDEL mais n'as rien renseigné
										|| getPharmacy && otherPharmacyName.length == 0 && patientPharmacy == null) && !isTutor//le patient connait sa pharmacie mais n'as rien renseigné
								}
								>
									{format("patient.dashboard.info.submit")}
								</Button>
								<Button
									secondary
									onClick={() => setEditingAndResetFields(false)}
									type="button"
									initial={{ opacity: 0 }}
									animate={{ opacity: 1, transition: { delay: 0.3 } }}
								>
									{format("patient.dashboard.info.cancel")}
								</Button>
							</Buttons>
						</Info>
					)}
				</Section>
			</form>
		</Encart>
	);
};

const Section = styled(motion.div)`
	${stack("XL", "flex-start", "stretch")}
	@media ${desktop} {
		padding: ${sizes.L};
		border-radius: 12px;
		box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.05);
		background-color: ${colors.white};
		flex-grow: 1;
		min-height: 310px;
	}
`;
const Info = styled.div`
	${stack("S", "flex-start", "stretch")}
`;
const InfoField = styled(motion.div)<{ singleLine?: boolean }>`
	${props =>
		!props.singleLine
			? row(0, "flex-start", "flex-start")
			: css`
					text-overflow: ellipsis;
					overflow: hidden;
			  `}
	color: ${colors.black};

	> * {
		flex-grow: 0;
	}
`;

const SkeletonLine = styled.div`
	height: 1rem;
	background-color: ${colors.grey};
	border-radius: 3px;
`;

const Buttons = styled(motion.div)`
	align-self: flex-end;
	${row("S", "flex-end", "center")};

	> * {
		flex-grow: 1;
	}
`;
const PostalRow = styled.div`
	@media${desktop} {
		${row("L", "flex-start", "flex-start")};
	}
	@media${mobile} {
		flex-wrap: wrap;
		align-items: flex-end; /* Aligne les éléments sur la ligne de base à la fin */
		> * {
			flex-basis: 100%;
		}
		> :first-child {
			flex-basis: 150%;
		}
	}
`;

const PharmacyStack = styled.div`
@media${desktop} {
	${stack("S", "flex-start", "flex-start")};
}
@media${mobile} {
	flex-wrap: wrap;
	align-items: flex-end; /* Aligne les éléments sur la ligne de base à la fin */
> * {
		flex-basis: 100%;
	}
> :first-child {
		flex-basis: 150%;
	}
}
`;
const Row = styled.div`
	@media${desktop} {
			${row("S", "flex-start", "flex-start")};
	}
	@media${mobile} {
			flex-wrap: wrap;
			align-items: flex-end; /* Aligne les éléments sur la ligne de base à la fin */
	}
	&:hover {
			color: ${colors.pink};
			cursor: pointer;
	}
`;
const PharmacyRow = styled.div<{selected?: boolean}>`
	@media${desktop} {
	${row("S", "flex-start", "flex-start")};
	}
	@media${mobile} {
	flex-wrap: wrap;
	align-items: flex-end; /* Aligne les éléments sur la ligne de base à la fin */
	}
	&:hover {
			color: ${colors.pink};
      cursor: pointer;
	}
	${({selected}) => selected ? css` color: ${colors.pink}` : css`color: ${colors.black}`}
`;
const Encart = styled.div`
	width: 100%;
`;

const RadioButton = styled.input`
	margin-top : 5px;
	margin-right: 5px;
	cursor: pointer;
	width: 14px;
	height: 14px;
	accent-color: ${colors.pink};
`;
const RadioButtonLabel = styled.div`
	font-size: 15px;
	cursor: pointer;
	color: ${colors.black};
`;

const SpinnerContainer = styled(motion.div)`
	top: 5px;
	left: 5px;
	right: 5px;
	bottom: 5px;
	${row(0, "center", "center")};
	pointer-events: none;
`;

const Spinner = styled.img`
	width: 30px;
	height: 30px;
	object-fit: contain;
`;
const RightIcon = styled.img`
	width: 20px;
	height: 20px;
	top: ${sizes.S};
	right: ${sizes.S};
`;

const Label = styled.div`
	font-size: 15px;
	color: ${colors.grey2};
`;