import {WritableObservable} from "micro-observables";
import {MediaFile} from "../domain/media-file"
import {api} from "./api";

export class MediaFileService {
    public mediaFile = new WritableObservable<MediaFile | null>(null);

    async fetchMediaFile(fileName: string): Promise<MediaFile> {
        const res = await api.get<MediaFile>("medias/get", {params: {fileName}});
        this.mediaFile.set(res.data);
        return res.data;
    }

    async getTermsDocument(documentId: string | undefined) {
        if (documentId) {
            try {
                //URL différente du praticien car la route existe déjà avec une utilisation autre
                const response = await api.get(`/terms/${documentId}`, {
                    responseType: 'blob', // Important: Indique que la réponse doit être traitée comme un Blob
                });
                // Créer un Blob à partir de la réponse
                const blob = new Blob([response.data], { type: 'application/pdf' }); // Spécifiez le type MIME correct si nécessaire

                // Créer une URL pour le Blob
                const url = window.URL.createObjectURL(blob);

                // Ouvre un nouvel onglet avec le document
                window.open(url, '_blank');

                // Nettoyer l'URL du Blob après ouverture
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error("Erreur lors du téléchargement du document:", error);
            }
        }
    }

}

export const mediaFileService = new MediaFileService();