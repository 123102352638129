import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { colors, row, stack } from "../../style";
import Close from "@assets/icons/white-cross.svg";
import Link from "@assets/icons/link-redirect.svg";
import { useNavigate } from "react-router";
import { useI18n } from "../../utils/i18n";

export const AlertComponent: React.FC<{
	openAlertBox: boolean;
	state: string;
	counter?: number | null;
	link?: string;
	message: string;
	onClose: () => void;
}> = ({ openAlertBox, state, counter, link , message, onClose}) => {
	const [alertBox, setAlertBox] = useState(openAlertBox);
	const [alertBackgroundColor, setAlertBackgroundColor] = useState("");
	const [alertMessage, setAlertMessage] = useState("");
	const history = useNavigate();
	const { format } = useI18n();
	useEffect(() => {
		// Synchronise alertBox avec openAlertBox chaque fois qu'il change
		setAlertBox(openAlertBox);
	}, [openAlertBox]);
	useEffect(() => {
		switch (state) {
			case "success":
				setAlertBackgroundColor(colors.green);
				setAlertMessage(message);
				break;
			case "info":
				setAlertBackgroundColor(colors.blue2);
				setAlertMessage(message);
				break;
			case "warning":
				setAlertBackgroundColor(colors.orange);
				setAlertMessage(message);
				break;
			case "danger":
				setAlertBackgroundColor(colors.red);
				setAlertMessage(message);
				break;
			default:
				break;
		}
	}, [state, alertBox, message]);

	const handleClick = () => {
		if (link) {
			history(link, {
				state: { fromLogin: false },
			});
		}
	};

	return (
		<>
		{openAlertBox &&
			<Container className="relative">
				<AlertContainer className={`${alertBox ? "enter" : "leave"}`}>
					<AlertBox>
						<AlertMessage backgroundColor={alertBackgroundColor}>
							<Stack>
								<div dangerouslySetInnerHTML={{ __html: alertMessage }} />
								{link && <Row>
									<StyledLink onClick={handleClick}>{format("notifications.link.hpu.patient")}</StyledLink>
									<div onClick={handleClick}>
										<LinkIcon />
									</div>
								</Row>}
							</Stack>
							<CloseIcon src="/icons/close.svg" onClick={onClose} />
						</AlertMessage>
					</AlertBox>
				</AlertContainer>
			</Container>
		}
		</>
	);
}

const Container = styled.div`
	position: absolute;
	z-index: 800;
`;

const AlertContainer = styled.div`
	position: fixed;
	top: 20%;
	right: 1%;
	max-width: 650px;
	width: 90%;
	transition: opacity 300ms ease-in-out;

	&.enter {
		opacity: 1;
		animation: slideInRight 0.5s ease-in-out;
	}

	&.leave {
		opacity: 0;
		animation: slideOutRight 0.5s ease-in-out;
	}

	@keyframes slideInRight {
		0% {
			transform: translateX(100%);
		}
		100% {
			transform: translateX(0);
		}
	}

	@keyframes slideOutRight {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(100%);
		}
	}
`;
const Stack = styled.div`
	${stack(0, "center", "center")};
`;
const Row = styled.div`
	${row("S", "center", "center")};
`;
const AlertBox = styled.div`
	padding: 10px;
	gap: 20px;
	a {
		text-decoration: underline;
		cursor: pointer;
	}
`;
const StyledLink = styled.a`
	text-decoration: none;
	color: inherit;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`;

const AlertMessage = styled.div<{ backgroundColor: string }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	color: ${colors.white};
	font-size: 18px;
	font-weight: bold;
	min-height: 67px;
	max-width: 90%;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	border-radius: 12px;
	padding: 16px 22px 17px 20px;
	background-color: ${props => props.backgroundColor};
	white-space: pre-line;
`;

const CloseIcon = styled(Close)`
	width: 18px;
	position: absolute;
	top: 15px;
	right: 90px;
	cursor: pointer;
	path {
		opacity: 1;
		rect {
			fill: #fff;
		}
	}
`;

const LinkIcon = styled(Link)`
	width: 20px;
	cursor: pointer;
	path {
		opacity: 1;
		rect {
			fill: #fff;
		}
	}
`;
