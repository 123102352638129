import {HTMLMotionProps, motion} from "framer-motion";
import {capitalize, useI18n} from "../../utils/i18n";
import React, {Fragment, useCallback, useState} from "react";
import {Patient, patientHasFilledTheirInfo} from "../../domain/patient";
import {SurgeryStep} from "../../domain/surgery-step";
import {fullName} from "../../domain/utils";
import {Button} from "../button";
import {EmailInput, TextInput} from "../inputs/text-input";
import styled from "@emotion/styled";
import {colors, desktop, row, sizes, stack} from "../../style";
import {css} from "@emotion/react";
import {normalizeDate} from "../../utils/time";
import {DatePickerInput} from "../inputs/datepicker-input";
import {createModal} from "../../services/modal-service";
import {ConfirmSecretaryModal} from "../modals/confrm-secretary-modal";

interface InfoPatientUpdate extends HTMLMotionProps<"div"> {
    patient: Patient | null;
    onEdit?: (data: Partial<Pick<Patient, "firstName" | "lastName" | "birthDate" | "email" | "phoneNumber1">>) => Promise<void>;
    step?: SurgeryStep;
    practitionerId?: string | null;
}

export const PatientInfoSectionUpdate: React.FC<InfoPatientUpdate> = ({patient, onEdit,practitionerId, ...props}) => {
    const { format, formatDate} = useI18n()
    const [editing, setEditing] = useState(false);

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [birthDate, setBirthDate] = useState('')
    const [phoneNumber1, setPhoneNumber1] = useState('')
    const [email, setEmail] = useState('')
    const [showTooltip, setShowTooltip] = useState(false)
    const alreadyOnboarded = !!patient?.onBoardingAt

    const setEditingAndChangeFields = useCallback(
        (editing: boolean) => {
            setEditing(editing);
            setFirstName(patient?.firstName.trim() ?? "");
            setLastName(patient?.lastName.trim() ?? "");
            setBirthDate(patient?.birthDate ?? "");
            setPhoneNumber1(patient?.phoneNumber1 ?? "");
            setEmail(patient?.email?.trim() ?? "");
        },
        [patient?.firstName, patient?.lastName, patient?.birthDate, patient?.phoneNumber1],
    );

    const saveUpdate = useCallback(async() => {
        const patientData = [patient?.firstName, patient?.lastName, patient?.birthDate, patient?.email, patient?.phoneNumber1];
        // console.log("old: " + patientData)
        const newPatientData = [firstName, lastName, birthDate, email, phoneNumber1];
        // console.log("new: " + newPatientData);
        const anyDiff = (JSON.stringify(newPatientData) != JSON.stringify(patientData));
        // console.log("anyDiff: " + anyDiff);
        const emailOnly = (firstName === patient?.firstName.trim() && lastName === patient.lastName.trim() && birthDate === patient.birthDate && email != patient.email);
        // console.log("emailOnly: " + emailOnly);

        if (anyDiff) {
            await createModal<string | null>(({onClose}) => (
                <ConfirmSecretaryModal onClose={onClose} firstName={firstName} lastName={lastName} birthDate={birthDate} email={email} phoneNumber1={phoneNumber1} updateEmailOnly={emailOnly} patient={patient} practitionerId={practitionerId}/>
            ));
        }
        setEditingAndChangeFields(false);
    }, [firstName, lastName, birthDate, email, phoneNumber1]);

    return (
        <Encart>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    saveUpdate();
                }}
            >
                <Section {...props}>
                    {!patient ? (
                        <Info>
                            <SkeletonLine />
                            <SkeletonLine />
                            <SkeletonLine />
                            <SkeletonLine />
                        </Info>
                    ) : !editing ? (
                        <>
                            {onEdit && !patientHasFilledTheirInfo(patient, null)}
                            <Info>
                                <InfoField key="name">
                                    {fullName(patient)}
                                </InfoField>
                                <InfoField key="birthDate">
                                    {formatDate(patient.birthDate,{ day: "numeric", month: "numeric", year: "numeric" })}
                                </InfoField>
                                <InfoField key="email">
                                    {patient.email}
                                </InfoField>
                                <InfoField key="phoneNumber1">
                                  {patient.phoneNumber1}
                                </InfoField>
                                <Buttons>
                                    {onEdit && patient && !editing ? (
                                        <Button
                                            onClick={() => setEditingAndChangeFields(true)}
                                            type="button"
                                        >
                                            {format("patient.dashboard.info.edit.modify")}
                                        </Button>
                                    ) : null}

                                </Buttons>
                            </Info>
                        </>
                    ) : (
                        <Info>
                            <InfoField key="firstName">
                                <TextInput
                                    required
                                    placeholder={format("practitioner.dashboard-patients.columns.firstName")}
                                    value={firstName}
                                    autoCapitalize="words"
                                    onChange={e => setFirstName(capitalize(e.target.value))}
                                />
                            </InfoField>
                            <InfoField key="lastName">
                                <TextInput
                                    required
                                    placeholder={format("practitioner.dashboard-patients.columns.lastName")}
                                    value={lastName}
                                    autoCapitalize="characters"
                                    onChange={e => setLastName(e.target.value.toUpperCase())}
                                />
                            </InfoField>
                            <InfoField>
                                <DatePickerInput
                                    placeholder={format("practitioner.dashboard-patients.columns.birthDate")}
                                    onChange={e => {
                                        setBirthDate(normalizeDate(e.toLocaleString()))
                                    }}
                                    earlierDatesAllowed
                                    newDate={birthDate}
                                    required
                                />
                            </InfoField>
                            <InfoField key="emailAddress" style={{ position: "relative" }}>
                                <EmailInput
                                    placeholder={format("secretary.account.profile.email")}
                                    type={email}
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    autoComplete="username"
                                    disabled={alreadyOnboarded || patient.legalGuardian1 != null}
                                />
                                { alreadyOnboarded && (
                                    <Fragment>
                                        <img src="/icons/info.svg"
                                             style={{ position: "absolute", top: "-10px", right: "-5px", width: 18, height: 18 }}
                                             onMouseOver={() => setShowTooltip(true)}
                                             onMouseOut={() => setShowTooltip(false)}
                                        />
                                    </Fragment>
                                )}
                            </InfoField>
                            <InfoField key="phoneNumber1">
                              <TextInput
                                placeholder={format("patient.dashboard.info.phoneNumber1Placeholder")}
                                value={phoneNumber1}
                                pattern="^(06|07|\+336|\+337|00336|00337)[0-9]{8}$"
                                onChange={e => {
                                  setPhoneNumber1(e.target.value);
                                }}
                                disabled={patient.legalGuardian1 != null}
                              />
                            </InfoField>
                            <Buttons>
                                <Button
                                    key="submit"
                                    type="submit"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1, transition: { delay: 0.3 } }}
                                >
                                    {format("patient.dashboard.info.submit")}
                                </Button>
                                <Button
                                    secondary
                                    onClick={() => setEditingAndChangeFields(false)}
                                    type="button"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1, transition: { delay: 0.3 } }}
                                >
                                    {format("patient.dashboard.info.cancel")}
                                </Button>
                            </Buttons>
                        </Info>
                    )}
                </Section>
            </form>
        </Encart>
    );
};

const Section = styled(motion.div)`
	${stack("XL", "flex-start", "stretch")}
	@media ${desktop} {
		padding: ${sizes.L};
		border-radius: 12px;
		box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.05);
		background-color: ${colors.white};
		flex-grow: 1;
	}
`;

const Info = styled.div`
	${stack("S", "flex-start", "stretch")}
`;

const InfoField = styled(motion.div)<{ singleLine?: boolean }>`
	${props =>
    !props.singleLine
        ? row(0)
        : css`
					text-overflow: ellipsis;
					overflow: hidden;
			  `}
	color: ${colors.black};

	> * {
		flex-grow: 1;
	}
`;

const SkeletonLine = styled.div`
	height: 1rem;
	background-color: ${colors.grey};
	border-radius: 3px;
`;

const Buttons = styled(motion.div)`
	align-self: flex-end;
	${row("S", "flex-end", "center")};

	> * {
		flex-grow: 1;
	}
`;

const Encart = styled.div`
	width: 100%;
`;
