import styled from "@emotion/styled";
import { Button } from "components/button";
import {CheckboxInput} from "../inputs/checkbox-input";
import React, { useCallback, useEffect, useState } from "react";
import {colors, desktop, mobile, row, sizes, stack} from "style";
import { Patient } from "../../domain/patient";
import { Scoring } from "../../domain/scoring";
import {TextInput} from "../inputs/text-input";
import {css} from "@emotion/react";
import { patientService, useCurrentSurgery, useSurgery } from "../../services/patient-service";
import {useI18n} from "../../utils/i18n";

export const PatientScoringForm: React.FC<{
	patient: Patient,
	surgeryId: string,
	onRead?:()=>void,
	overrideSubsubtitle?: string,
}> = ({patient, surgeryId, overrideSubsubtitle, onRead}) => {
	const {format} = useI18n();
	const [editing, setEditing] = useState(true);
	const [weight, setWeight] = useState( "");
	const [height, setHeight] = useState( "");
	const [bimCompleted, setBimCompleted] = useState(false);
	const [dependence, setDependence] = useState<boolean | undefined | null>(null);
	const [dependenceData, setDependenceData] = useState<string>("");
	const [handicap, setHandicap] = useState<boolean | undefined | null>( null);
	const [handicapData, setHandicapData] = useState<string[]>([]);
	const [smoker, setSmoker] = useState<boolean | undefined | null>(null);
	const [treatment, setTreatment] = useState<boolean | undefined | null>(null);
	const [treatmentData, setTreatmentData] = useState<string[]>([]);
	const [otherTreatment, setOtherTreatment] = useState(false);
	const [otherTreatmentData, setOtherTreatmentData] = useState<string>("");
	const [cardio, setCardio] = useState<boolean | undefined | null>(null);
	const [breath, setBreath] = useState<boolean | undefined | null>(null);
	const [dialysis, setDialysis] = useState<boolean | undefined | null>(null);
	const [blood, setBlood] = useState<boolean | undefined | null>(null);
	const [dependenceCompleted, setDependenceCompleted] = useState(false);
	const [handicapCompleted, setHandicapCompleted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [treatmentCompleted, setTreatmentCompleted] = useState(false);
	const [lastFourQuestionsHighRisk, setLastFourQuestionsHighRisk] = useState<boolean | undefined | null>(null);
	const [fullyCompleted, setFullyCompleted] = useState(false);
	useEffect(() => {
		patientService.fetchCurrentSurgery(surgeryId).then()
	}, [surgeryId]);

	const {surgery} = useCurrentSurgery(surgeryId)

	useEffect(() => {
		setBimCompleted(height.length > 0 && weight.length > 0);
	}, [height, weight])
	useEffect(() => {
		setDependenceCompleted((dependence == false) || (dependence == true && dependenceData.length > 0));
	}, [dependence, dependenceData])
	useEffect(() => {
		setHandicapCompleted((handicap == false) || (handicap == true && handicapData.length > 0));
	}, [handicap, handicapData])
	useEffect(() => {
		setTreatmentCompleted((treatment == false) || (treatment == true && treatmentData.length > 0) || (treatment == true && otherTreatment == true && otherTreatmentData.length > 0));
	}, [treatment, treatmentData, otherTreatment, otherTreatmentData])
	useEffect(() => {
		setLastFourQuestionsHighRisk(cardio || breath || dialysis || blood);
	}, [cardio, breath, dialysis, blood])
	useEffect(() => {
		if(!!weight && !!height && bimCompleted && dependenceCompleted && handicapCompleted && treatmentCompleted && smoker != null &&
			(treatment == false || (treatment == true && (treatmentData.length > 0) && cardio != null && breath != null && dialysis != null && blood != null) || (treatment == true && otherTreatment && otherTreatmentData.length > 0)))
			setFullyCompleted(true)
	}, [bimCompleted, treatment, weight, height, dependenceCompleted, handicapCompleted, smoker,
		treatmentCompleted, cardio, breath, dialysis, blood, otherTreatment])
	useEffect(() => {
		setEditing(!(!!patient && !!surgery && !!surgery.scoring && !!surgery.scoring.when_created));
	},[patient, surgery])
	const editScoring = useCallback(async (scoring: Scoring | undefined) => {
		await patientService.deleteScoring(scoring)
		if (onRead) {
			onRead?.()
		}
		setEditing(true)
	},[])
	const save = async () => {
		// answers compilation & persistance
		let formAnswers: Scoring;
		if (patient && surgery && fullyCompleted) {
			formAnswers = {
				patientId: patient.id.toString(),
				surgeryId: surgery.id,
				weight: weight.toString(),
				height: height.toString(),
				dependencySituation: dependenceData ? dependenceData.toString() : "False",
				handicapSituation: (handicap && handicapData.length > 0) ? handicapData.toString() : "False",
				smoker: smoker == true ? "True" : "False",
				healthIssues: (treatmentData /*&& treatmentData.length > 0*/ && otherTreatment) ?
					(treatmentData.toString() + "," + otherTreatmentData) :
					(treatmentData && treatmentData.length > 0) ? treatmentData.toString() : "False",
				cardioHistory: cardio == true ? "True" : "False",
				chronicRespiratoryProblems: breath == true ? "True" : "False",
				dialyzed: dialysis == true ? "True" :"False",
				bloodIssues: blood == true ? "True" : "False",
				version: 1,
			};
			// setScoringFormData(formAnswers)
			await patientService.createScoring(formAnswers);
			// updateSurgery if highRisk or handicap
			if (lastFourQuestionsHighRisk) {
				surgery.anesthesiaFormAnswers = String(cardio) + ',' + String(breath) + ',' + String(dialysis) + ',' + String(blood);
				surgery.anesthesiaHighRiskPatient = true;
			} else {
				surgery.anesthesiaFormAnswers = ",,,";
				surgery.anesthesiaHighRiskPatient = false;
			}
			surgery.anesthesiaDisabledPatient = handicapData.length > 0;
			setLoading(false)
			if (surgery.practitioner?.id) {
				await patientService.updateSurgery(surgery.id, surgery.practitioner.id, surgery);
			}
			if (onRead) {
				onRead?.()
			}

			window.scrollTo({ top: 0});
		}
	}

	return (patient && surgery && surgery.scoring && !editing ? (
			<Section readOnly={!editing}>
				<Header>
					<Subtitle>
						{format("scoringForm.reading.patient.subtitle")}
					</Subtitle>
					{surgery.anesthesiaHighRiskPatient && (
						<WarningFlag>{format("patient.highRisk")}</WarningFlag>
					)}
				</Header>
				{overrideSubsubtitle ? (
					<SubSubtitle>
						{overrideSubsubtitle}
					</SubSubtitle>
				) : (
					<SubSubtitle>
						{format("scoringForm.reading.patient.subsubtitle1")}
					</SubSubtitle>
				)}
				<Fields>
					<Fields marginFree>{format("scoringForm.reading.patient.weight")} {surgery.scoring.weight} kg</Fields>
					<Fields marginFree>{format("scoringForm.reading.patient.height")} {surgery.scoring.height} cm</Fields>
				</Fields>
				<Fields>
					<Fields marginFree>{format("scoringForm.reading.patient.dependencySituation")} {surgery.scoring.dependencySituation == "Partially" ? "partielle" : surgery.scoring.dependencySituation == "Totally" ? "totale" : "non"}</Fields>
					<Fields marginFree>
						{format("scoringForm.reading.patient.handicapSituation")}
						{(surgery.scoring.handicapSituation.length == 0 || surgery.scoring.handicapSituation == "False") && "non"}
						{surgery.scoring.handicapSituation.includes("physical") ? "physique, " : ""}
						{surgery.scoring.handicapSituation.includes("sensorial") ? "sensorielle, " : ""}
						{surgery.scoring.handicapSituation.includes("mental") ? "mentale, " : ""}
					</Fields>
					<Fields marginFree>
						{format("scoringForm.reading.patient.smokerSituation")}
						{(surgery.scoring.smoker.length == 0 || surgery.scoring.smoker == "False") && "non"}
						{(surgery.scoring.smoker == "True") && "oui"}
					</Fields>
					<Fields marginFree>
						{format("scoringForm.reading.patient.medicalTreatment")}
						{(surgery.scoring.healthIssues.length == 0 || surgery.scoring.healthIssues == "False") && "non"}
						{surgery.scoring.healthIssues.includes("hta") ? "HTA, " : ""}
						{surgery.scoring.healthIssues.includes("diabetes") ? "diabète, " : ""}
						{surgery.scoring.healthIssues.includes("cholesterol") ? "cholestérol, " : ""}
						{!surgery.scoring.healthIssues.includes("False")
							&& surgery.scoring.healthIssues.replace(/hta|diabetes|cholesterol/g, "").replace(/,/g, " ")}
					</Fields>
				</Fields>
				<Fields>
					<Fields marginFree>
						{format("scoringForm.reading.patient.cardioHistory")}
						{(surgery.scoring.cardioHistory.length == 0 || surgery.scoring.cardioHistory == "False") && "non"}
						{surgery.scoring.cardioHistory == "True" && "oui"}
					</Fields>
					<Fields marginFree>
						{format("scoringForm.reading.patient.chronicRespiratoryIssues")}
						{(surgery.scoring.chronicRespiratoryProblems.length == 0 || surgery.scoring.chronicRespiratoryProblems == "False") && "non"}
						{surgery.scoring.chronicRespiratoryProblems == "True" && "oui"}
					</Fields>
					<Fields marginFree>
						{format("scoringForm.reading.patient.dialyzed")}
						{(surgery.scoring.dialyzed.length == 0 || surgery.scoring.dialyzed == "False") && "non"}
						{surgery.scoring.dialyzed == "True" && "oui"}
					</Fields>
					<Fields marginFree>
						{format("scoringForm.reading.patient.bloodIssues")}
						{(surgery.scoring.bloodIssues.length == 0 || surgery.scoring.bloodIssues == "False") && "non"}
						{surgery.scoring.bloodIssues == "True" && "oui"}
					</Fields>
				</Fields>
				<Buttons>
					<Button onClick={() =>editScoring(surgery?.scoring)}>{format("patient.scoring.section.editButton")}</Button>
				</Buttons>
			</Section>
		) : (
			<Section>
				<Header>
					<Subtitle>
						{format("scoringForm.editing.patient.subtitle")}
					</Subtitle>
				</Header>
				<SubSubtitle>
					{format("scoringForm.editing.patient.subsubtitle1")}
				</SubSubtitle>
				<SubSubtitle>
					<img src="/icons/warning.svg" style={{ height: "24px", marginTop: "6px", marginBottom: "-6px"}} alt="warning icon"/>
					{format("scoringForm.editing.patient.subsubtitle2")}
				</SubSubtitle>
				<form
					onSubmit={e => {
						setLoading(true)
						e.preventDefault();
						save();
					}}
				>
					<FormStack>
						<Fields>
							<FieldSection>
								<FieldLabel>
									{format("scoringForm.editing.patient.weight.label")}
								</FieldLabel>
								<FieldInputSection>
									<Text
										placeholder={format("scoringForm.editing.patient.weight.placeholder")}
										pattern="([1-9])[0-9]{0,2}"
										title={format("scoringForm.editing.patient.weight.patternAlert")}
										value={weight}
										onChange={(e) => setWeight(e.target.value)}
									/>
								</FieldInputSection>
							</FieldSection>
							<FieldSection>
								<FieldLabel>
									{format("scoringForm.editing.patient.height.label")}
								</FieldLabel>
								<FieldInputSection>
									<Text
										placeholder={format("scoringForm.editing.patient.height.placeholder")}
										pattern="([1-9])[0-9]{0,2}"
										title={format("scoringForm.editing.patient.height.patternAlert")}
										onChange={(e) => setHeight(e.target.value)}
										value={height}
									/>
								</FieldInputSection>
							</FieldSection>
						</Fields>
						<Fields>
							<FieldSection>
								<FieldLabel>{format("scoringForm.editing.patient.dependencySituation.label")}</FieldLabel>
							</FieldSection>
							<FieldSection>
								<FieldInputSection>
									<RadioButton
										type="radio"
										name="dependenceRadio"
										value="Oui"
										checked={dependence != null ? dependence : false}
										onChange={() => {
											setDependence(true)
										}}
									/>
									<RadioButtonLabel onClick={() =>{
										setDependence(true)
									}}>{format("scoringForm.editing.patient.dependencySituation.yes")}</RadioButtonLabel>
									<RadioButton
										type="radio"
										name="dependenceRadio"
										value="Non"
										checked={dependence != null ? !dependence: false}
										onChange={() => {
											setDependence(false)
											setDependenceData("")
										}}
									/>
									<RadioButtonLabel onClick={() =>{
										setDependence(false)
									}}>{format("scoringForm.editing.patient.dependencySituation.no")}</RadioButtonLabel>
								</FieldInputSection>
								{dependence &&
									<FieldInputSection>
										<RadioButton
											type="radio"
											name="dependenceDataRadio"
											value="Partially"
											checked={dependenceData.length > 0 && dependenceData == 'Partially'}
											onChange={() => {
												setDependence(true)
												if (dependenceData != "Partially") {
													setDependenceData('Partially')
												}
											}}
										/>
										<RadioButtonLabel onClick={() =>{
											setDependence(true)
											if (dependenceData != "Partially") {
												setDependenceData('Partially')
											}
										}}>{format("scoringForm.editing.patient.dependencySituation.partially")}</RadioButtonLabel>
										<RadioButton
											type="radio"
											name="dependenceDataRadio"
											value="Totally"
											checked={dependenceData.length > 0 && dependenceData == 'Totally'}
											onChange={() => {
												setDependence(true)
												if (dependenceData != "Totally") {
													setDependenceData('Totally')
												}
											}}
										/>
										<RadioButtonLabel onClick={() =>{
											setDependence(true)
											if (dependenceData != "Totally") {
												setDependenceData('Totally')
											}
										}}>{format("scoringForm.editing.patient.dependencySituation.totally")}</RadioButtonLabel>
									</FieldInputSection>
								}
							</FieldSection>
						</Fields>
						<Fields>
							<FieldSection>
								<FieldLabel>{format("scoringForm.editing.patient.handicapSituation.label")}</FieldLabel>
								<FieldInputSection>
									<RadioButton
										type="radio"
										name="handicapRadio"
										value="Oui"
										checked={handicap != null ? handicap : false}
										onChange={() => {
											setHandicap(true)
										}}
									/>
									<RadioButtonLabel onClick={() =>{
										setHandicap(true)
									}}>{format("scoringForm.editing.patient.handicapSituation.yes")}</RadioButtonLabel>
									<RadioButton
										type="radio"
										name="handicapRadio"
										value="Non"
										checked={handicap != null ? !handicap: false}
										onChange={() => {
											setHandicap(false)
											setHandicapData([])
										}}
									/>
									<RadioButtonLabel onClick={() =>{
										setHandicap(false)
									}}>{format("scoringForm.editing.patient.handicapSituation.no")}</RadioButtonLabel>
								</FieldInputSection>
								{handicap &&
									<FieldInputSection>
										<CheckboxInput value={handicapData.includes('physical')} onChange={() =>{
											if (!handicapData.includes('physical')) {
												const newHandicapData = [...handicapData];
												newHandicapData.push('physical');
												setHandicapData(newHandicapData);
											} else {
												const index = handicapData.indexOf('physical');
												const newHandicapData = [...handicapData];
												newHandicapData.splice(index, 1);
												setHandicapData(newHandicapData);
											}
										}}>
											<FieldLabel>
												{format("scoringForm.editing.patient.handicapSituation.physical.label")}
											</FieldLabel>
										</CheckboxInput>
										<CheckboxInput value={handicapData.includes('sensorial')} onChange={() =>{
											if (!handicapData.includes('sensorial')) {
												const newHandicapData = [...handicapData];
												newHandicapData.push('sensorial');
												setHandicapData(newHandicapData);
											} else {
												const index = handicapData.indexOf('sensorial');
												const newHandicapData = [...handicapData];
												newHandicapData.splice(index, 1);
												setHandicapData(newHandicapData);
											}
										}}>
											<FieldLabel>
												{format("scoringForm.editing.patient.handicapSituation.sensorial.label")}
											</FieldLabel>
										</CheckboxInput>
										<CheckboxInput value={handicapData.includes('mental')} onChange={() =>{
											if (!handicapData.includes('mental')) {
												const newHandicapData = [...handicapData];
												newHandicapData.push('mental');
												setHandicapData(newHandicapData);
											} else {
												const index = handicapData.indexOf('mental');
												const newHandicapData = [...handicapData];
												newHandicapData.splice(index, 1);
												setHandicapData(newHandicapData);
											}
										}}>
											<FieldLabel>
												{format("scoringForm.editing.patient.handicapSituation.mental.label")}
											</FieldLabel>
										</CheckboxInput>
									</FieldInputSection>
								}
							</FieldSection>
						</Fields>
						<Fields>
							<FieldSection>
								<FieldLabel>{format("scoringForm.editing.patient.smokerSituation.label")}</FieldLabel>
								<FieldInputSection>
									<RadioButton
										type="radio"
										name="smokerRadio"
										value="Oui"
										checked={smoker != null ? smoker : false}
										onChange={() => {
											setSmoker(true)
										}}
									/>
									<RadioButtonLabel onClick={() =>{
										setSmoker(true)
									}}>{format("scoringForm.editing.patient.smokerSituation.yes")}</RadioButtonLabel>
									<RadioButton
										type="radio"
										name="smokerRadio"
										value="Non"
										checked={smoker != null ? !smoker: false}
										onChange={() => {
											setSmoker(false);
										}}
									/>
									<RadioButtonLabel onClick={() =>{
										setSmoker(false)
									}}>{format("scoringForm.editing.patient.smokerSituation.no")}</RadioButtonLabel>
								</FieldInputSection>
							</FieldSection>
						</Fields>
						<Fields>
							<FieldSection>
								<FieldLabel>{format("scoringForm.editing.patient.medicalTreatment.label")}</FieldLabel>
								<FieldInputSection>
									<RadioButton
										type="radio"
										name="treatmentRadio"
										value="Oui"
										checked={treatment != null ? treatment : false}
										onChange={() => {
											setTreatment(true)
										}}
									/>
									<RadioButtonLabel onClick={() =>{
										setTreatment(true)
									}}>{format("scoringForm.editing.patient.medicalTreatment.yes")}</RadioButtonLabel>
									<RadioButton
										type="radio"
										name="treatmentRadio"
										value="Non"
										checked={treatment != null ? !treatment: false}
										onChange={() => {
											setTreatment(false);
											setTreatmentData([]);
											setCardio(false);
											setBreath(false);
											setDialysis(false);
											setBlood(false);
										}}
									/>
									<RadioButtonLabel onClick={() =>{
										setTreatment(false);
									}}>{format("scoringForm.editing.patient.medicalTreatment.no")}</RadioButtonLabel>
								</FieldInputSection>
								{treatment &&
									<LongFieldInputSection>
										<CheckboxInput value={treatmentData.includes('hta')} onChange={() =>{
											if (!treatmentData.includes('hta')) {
												const newTreatmentData = [...treatmentData];
												newTreatmentData.push('hta');
												setTreatmentData(newTreatmentData);
											} else {
												const index = treatmentData.indexOf('hta');
												const newTreatmentData = [...treatmentData];
												newTreatmentData.splice(index, 1);
												setTreatmentData(newTreatmentData);
											}
										}}>
											<FieldLabel>
												{format("scoringForm.editing.patient.medicalTreatment.hta.label")}
											</FieldLabel>
										</CheckboxInput>
										<CheckboxInput value={treatmentData.includes('diabetes')} onChange={() =>{
											if (!treatmentData.includes('diabetes')) {
												const newTreatmentData = [...treatmentData];
												newTreatmentData.push('diabetes');
												setTreatmentData(newTreatmentData);
											} else {
												const index = treatmentData.indexOf('diabetes');
												const newTreatmentData = [...treatmentData];
												newTreatmentData.splice(index, 1);
												setTreatmentData(newTreatmentData);
											}
										}}>
											<FieldLabel>
												{format("scoringForm.editing.patient.medicalTreatment.diabetes.label")}
											</FieldLabel>
										</CheckboxInput>
										<CheckboxInput value={treatmentData.includes('cholesterol')} onChange={() =>{
											if (!treatmentData.includes('cholesterol')) {
												const newTreatmentData = [...treatmentData];
												newTreatmentData.push('cholesterol');
												setTreatmentData(newTreatmentData);
											} else {
												const index = treatmentData.indexOf('cholesterol');
												const newTreatmentData = [...treatmentData];
												newTreatmentData.splice(index, 1);
												setTreatmentData(newTreatmentData);
											}
										}}>
											<FieldLabel>
												{format("scoringForm.editing.patient.medicalTreatment.cholesterol.label")}
											</FieldLabel>
										</CheckboxInput>
										<CheckboxInput value={otherTreatment} onChange={() =>{
											if (!otherTreatment) {
												setOtherTreatment(true);
											} else {
												setOtherTreatment(false);
												setOtherTreatmentData("");
											}
										}}>
											<FieldLabel>
												{format("scoringForm.editing.patient.medicalTreatment.other.label")}
											</FieldLabel>
										</CheckboxInput>
										{otherTreatment &&
											<InlineTextInputSection>
												<Text
													placeholder={format("scoringForm.editing.patient.medicalTreatment.other.placeholder")}
													onChange={(e) => setOtherTreatmentData(e.target.value)}
												/>
											</InlineTextInputSection>
										}
									</LongFieldInputSection>
								}
							</FieldSection>
						</Fields>
						{treatment &&
							<Fields>
								<Fields>
									<FieldLabel>{format("scoringForm.editing.patient.cardioHistory.label")}</FieldLabel>
									<FieldInputSection>
										<RadioButton
											type="radio"
											name="cardioRadio"
											value="Oui"
											checked={cardio != null ? cardio : false}
											onChange={() => {
												setCardio(true)
											}}
										/>
										<RadioButtonLabel onClick={() =>{
											setCardio(true)
										}}>{format("scoringForm.editing.patient.cardioHistory.yes")}</RadioButtonLabel>
										<RadioButton
											type="radio"
											name="cardioRadio"
											value="Non"
											checked={cardio != null ? !cardio: false}
											onChange={() => {
												setCardio(false)
											}}
										/>
										<RadioButtonLabel onClick={() =>{
											setCardio(false)
										}}>{format("scoringForm.editing.patient.cardioHistory.no")}</RadioButtonLabel>
									</FieldInputSection>
								</Fields>
								<Fields><br/>
									<FieldLabel>
										{format("scoringForm.editing.patient.chronicRespiratoryIssues.label")}
									</FieldLabel>
									<FieldInputSection>
										<RadioButton
											type="radio"
											name="breathRadio"
											value="Oui"
											checked={breath != null ? breath : false}
											onChange={() => {
												setBreath(true)
											}}
										/>
										<RadioButtonLabel onClick={() =>{
											setBreath(true)
										}}>{format("scoringForm.editing.patient.chronicRespiratoryIssues.yes")}</RadioButtonLabel>
										<RadioButton
											type="radio"
											name="breathRadio"
											value="Non"
											checked={breath != null ? !breath: false}
											onChange={() => {
												setBreath(false)
											}}
										/>
										<RadioButtonLabel onClick={() =>{
											setBreath(false)
										}}>{format("scoringForm.editing.patient.chronicRespiratoryIssues.no")}</RadioButtonLabel>
									</FieldInputSection>
								</Fields>
								<Fields><br/>
									<FieldLabel>{format("scoringForm.editing.patient.dialyzed.label")}</FieldLabel>
									<FieldInputSection>
										<RadioButton
											type="radio"
											name="dialysisRadio"
											value="Oui"
											checked={dialysis != null ? dialysis : false}
											onChange={() => {
												setDialysis(true)
											}}
										/>
										<RadioButtonLabel onClick={() =>{
											setDialysis(true)
										}}>{format("scoringForm.editing.patient.dialyzed.yes")}</RadioButtonLabel>
										<RadioButton
											type="radio"
											name="dialysisRadio"
											value="Non"
											checked={dialysis != null ? !dialysis: false}
											onChange={() => {
												setDialysis(false)
											}}
										/>
										<RadioButtonLabel onClick={() =>{
											setDialysis(false)
										}}>{format("scoringForm.editing.patient.dialyzed.no")}</RadioButtonLabel>
									</FieldInputSection>
								</Fields>
								<Fields><br/>
									<FieldLabel>
										{format("scoringForm.editing.patient.bloodIssues.label")}
									</FieldLabel>
									<FieldInputSection>
										<RadioButton
											type="radio"
											name="bloodRadio"
											value="Oui"
											checked={blood != null ? blood : false}
											onChange={() => {
												setBlood(true)
											}}
										/>
										<RadioButtonLabel onClick={() =>{
											setBlood(true)
										}}>{format("scoringForm.editing.patient.bloodIssues.yes")}</RadioButtonLabel>
										<RadioButton
											type="radio"
											name="bloodRadio"
											value="Non"
											checked={blood != null ? !blood: false}
											onChange={() => {
												setBlood(false)
											}}
										/>
										<RadioButtonLabel onClick={() =>{
											setBlood(false)
										}}>{format("scoringForm.editing.patient.bloodIssues.no")}</RadioButtonLabel>
									</FieldInputSection>
								</Fields>
							</Fields>
						}
					</FormStack>
					<Buttons>
						<Button
							key="submit"
							type="submit"
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, transition: { delay: 0.3 } }}
							loading={loading}
							disabled={!fullyCompleted}
						>
							{format("scoringForm.editing.patient.validate")}
						</Button>
					</Buttons>
				</form>
			</Section>
		)
	);
};

const Section = styled.div<{ readOnly?: boolean }>`
	${stack("L", "flex-start", "stretch")}
	@media ${desktop} {
		padding: ${sizes.L};
		border-radius: 12px;
		box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.05);
		background-color: ${colors.white};
		flex-grow: 1;
		${({ readOnly }) =>
			readOnly &&
			css`
				max-height: 520px;
		`};
	}
`;

const Header = styled.div`
	${row("M", "flex-start", "baseline")}
`;

const Subtitle = styled.div`
	font-size: 20px;
	font-weight: bold;
	color: ${colors.black};
`;

const SubSubtitle = styled.div`
	color: ${colors.grey2};
	font-size: 15px;
	margin-top: -20px;
`;

const Buttons = styled.div`
	align-self: flex-end;
	${row("S", "flex-end", "center")};
`;

const Fields = styled.div<{ marginFree?: boolean }>`
	${({ marginFree }) =>
		marginFree &&
		css`
			margin: 0;
		`};
`;

const FieldLabel = styled.span`
	color: ${colors.black};
	width: 100%;
	overflow-wrap: break-word;
`;

const FormStack = styled.div`
	${stack("M")}
`;

const FieldSection = styled.div`
	${stack(0)}
`;

const Text = styled(TextInput)`
	min-width: 15%;
	max-width: 100%;
`;

const FieldInputSection = styled.div`
	padding-top: 5px;
	${row("S", "flex-start", "flex-start")};
`;

const LongFieldInputSection = styled.div`
	padding-top: 5px;
	@media ${desktop} {
		${row("S", "flex-start", "flex-start")};
	}
	@media ${mobile} {
		${stack("S", "flex-start", "stretch")};
	}
`;

const InlineTextInputSection = styled.div`
	margin-top: -8px;
	width: 240px;
`;

const RadioButton = styled.input`
	margin-top : 5px;
	margin-right: 5px;
	cursor: pointer;
	width: 14px;
	height: 14px;
	accent-color: ${colors.pink};
`;
const Row = styled.div`
	${row("XL")}
`;
const RadioButtonLabel = styled.div`
	font-size: 15px;
	cursor: pointer;
	color: ${colors.black};
`;

const WarningFlag = styled.div`
	padding: 2px 8px 3px;
	border-radius: 10px;
	background-color: #fff5eb;
	color: ${colors.orange};
	font-size: 12px;
	font-weight: bold;
	margin-bottom: -5px;
	text-align: center;
`;
