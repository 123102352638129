import styled from "@emotion/styled";
import {Button} from "components/button";
import {FormField} from "components/form-field";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {ModalCardContainer, ModalCloseIcon} from "services/modal-service";
import {colors, row, sizes, stack} from "style";
import { useI18n } from "../../../utils/i18n";
import { practitionerService, usePractitionerProfile } from "../../../services/practitioner-service";
import { TextInput } from "../../inputs/text-input";
import debounce from "lodash/debounce";
import { PractitionerAmount } from "../../../domain/practitioner";
import { CCAM, CcamDpmMode } from "../../../domain/surgery";
import { SelectInput } from "../../inputs/select-input";
import { usePractitionerAmount } from "../../../services/practitioner-service";

interface CreateSurgeryModalProps {
	practitionerAmountId?: string | undefined;
	practitionerId: string;
	onClose: () => void;
}
export const CreatePractitionerAmountModal: React.FC<CreateSurgeryModalProps> = ({ practitionerId, onClose, practitionerAmountId }) => {
	const { formatDpmMode } = useI18n();
	const practitioner = usePractitionerProfile();
	const practitionerAmounts = usePractitionerAmount();
	const [error, setError] = useState<{ code: number, message: string } | null>(null);
	const { format } = useI18n();
	const [dpmMode, setDpmMode] = useState<string>("");
	const [amount, setAmount] = useState<string>("");
	const [ccam, setCcam] = useState("");
	const [ccamDesc, setCcamDesc] = useState("");
	const [classificationCodeDebounced, setClassificationCodeDebounced] = useState("");
	const [loading, setLoading] = useState(false);
	const [ccamEntity, setCcamEntity] = useState<CCAM | null>(null);

	// init le form avec les valeurs à modifier quand on a un id
	useEffect(() => {
		let currentPractitionerAmount: PractitionerAmount | undefined;

		const fetchCcamDataParameter = async (practitionerAmountId: string | undefined) => {
			if (practitionerAmountId !== undefined) {
				currentPractitionerAmount = practitionerAmounts.find(it => it.id == practitionerAmountId);
				if (currentPractitionerAmount) {
					const codeCcamEntityParameter = await practitionerService.getCCAMById(currentPractitionerAmount.codeCcamId, practitionerId as string);
					if (codeCcamEntityParameter) {
						setDpmMode(currentPractitionerAmount.mode);
						setAmount(currentPractitionerAmount.amount);
						setCcamEntity(codeCcamEntityParameter);
						setCcam(codeCcamEntityParameter?.codeCCAM);
						setCcamDesc(codeCcamEntityParameter?.description);
					}
				}
			}
		};

		fetchCcamDataParameter(practitionerAmountId).then(); // Appel de la fonction asynchrone
	}, [practitionerAmountId, practitionerAmounts, practitionerId]);

	const handleClassificationCodeInputChange = (e: { target: { value: string } }, practitionerId: string) => {
		const value = e.target.value.toUpperCase();
		setCcam(value);
		getCcamDesc(value, practitionerId as string).then()
	};
	const debounceClassificationCode = useMemo(
		() =>
			debounce((code: string) => {
				setClassificationCodeDebounced(code);
			}, 1500),
		[]
	);
	const getCcamDesc = useCallback(async (ccam: any, practitionerId: string) => {
		try {
			const codeCcamEntity = await practitionerService.getCCAMByCode(ccam, practitionerId as string);
			if (codeCcamEntity != undefined) {
				setCcamEntity(codeCcamEntity)
				setCcamDesc(codeCcamEntity?.description)
			} else {
				setCcamEntity(null)
				setCcamDesc("")
			}
		} catch (e: any) {
			throw e.message
		}
	}, [])

	const save = useCallback(async (e: { preventDefault: () => void; }, practitionerId: string, practitionerAmountId: string | undefined) => {
		setLoading(true)
		e.preventDefault();
		try {
			setLoading(true)
			if (practitionerId && dpmMode && amount && ccam && ccamEntity) {
				const practitionerAmount = {
					practitionerId: practitionerId as string,
					codeCcamId: ccamEntity.id,
					mode: dpmMode,
					amount: amount
				}
				if (practitionerAmountId == undefined) {
					await practitionerService.addPractitionerAmount(practitionerId as string, practitionerAmount);
				} else {
					await practitionerService.updatePractitionerAmount(practitionerId as string, practitionerAmountId, practitionerAmount);
				}
				practitionerService.fetchPractitionerAmount(practitionerId as string).then();
			}
		} catch (e: any) {
			alert(e.message)
			throw e.message
		} finally {
			setLoading(false)
			onClose()
		}
	}, [amount, ccam, ccamEntity, dpmMode, onClose])

	return (
		<Card>
			<ModalCloseIcon onClick={() => onClose()} />
			<Form onSubmit={(event) => save(event, practitionerId as string, practitionerAmountId).then()}>

				<Title>{format("addAmountParameter")}</Title>
				<Description>{format("createSurgeryModal.subtitle")}</Description>
				<Fields>
					<FormField label={format("role.practitioner")}>
						{practitioner && (
							practitioner.firstName + " " + practitioner.lastName
						)}
					</FormField>
				</Fields>

				<Fields>
					<FormField label={format("CCAM")}
										 style={{ gridArea: "ccam" }}>
						<TextInput
							value={ccam}
							placeholder={format("createSurgeryModal.classificationCode.placeholder")}
							onChange={e => {
								handleClassificationCodeInputChange(e, practitionerId as string);
								debounceClassificationCode(e.target.value);
							}}
							required
						/>
					</FormField>
				</Fields>
				<FieldsCustom>
					{ccamDesc}
				</FieldsCustom>
				<Fields>
					<FormField label={format("mode")} style={{ gridArea: "mode" }}>
						<SelectInput<string>
							innerId="side"
							value={dpmMode}
							onChange={e => {
								setDpmMode(e);
							}}
							options={[
								CcamDpmMode.NONE,
								CcamDpmMode.FIXED_AMOUNT,
								CcamDpmMode.PERCENTAGE,
								CcamDpmMode.TOTAL_AMOUNT,
							]}
							itemRenderer={formatDpmMode}
							required
						/>
					</FormField>
				</Fields>

				<Fields>
					<FormField label={format("amount")} style={{ gridArea: "amount" }}>
						<TextInput
							value={amount}
							onChange={e => {
								setAmount(e.target.value);
							}}
							maxLength={7}
							placeholder={format("createSurgeryModal.classificationCode.placeholder")}
							required
						/>
					</FormField>
				</Fields>

				{error && (
					<ErrorMessage>
						{error.code === 400
							? error.message
							: format("login.error.unknownError.admin")
						}
					</ErrorMessage>
				)}
				<ButtonContainer>
					{
						practitionerAmountId === undefined ? (
							<Button loading={loading} type="submit">
								{format("addAmount")}
							</Button>
						) : (
							<Button loading={loading} type="submit">
								{format("updateAmount")}
							</Button>
						)
					}

					<Button secondary type="button" onClick={() => {
						onClose()
					}}>
						{format("createSurgeryModal.cancelButton")}
					</Button>
				</ButtonContainer>
			</Form>
		</Card>
	);
};

const Card = styled(ModalCardContainer)`
    padding: 63px 80px;
    width: 650px; /* Ajoutez cette ligne pour définir une largeur fixe */
    max-width: 100%; /* Cela garantit que la carte ne dépasse pas 100% de la largeur de l'écran */
    ${row(0, "center", "center")};
    @media (max-width: 920px) {
        padding: ${sizes.L};
        width: auto; /* Ajustement pour les petits écrans */
    }
`;

const Title = styled.h1`
	font-size: 27px;
	color: ${colors.black};
`;


const RowPatient = styled.div`
	${row("L")}
`;

const Description = styled.div`
	font-size: 15px;
	color: ${colors.black};
`;

const Form = styled.form`
	${stack("S")}
	max-height: 100vh;
	overflow: auto;
`;


const Fields = styled.div`
	${stack("M")}
`;

const FieldsCustom = styled.div`
	${stack("M")}
`;

const ButtonContainer = styled.div`
	${row("S")}
	align-self:flex-end;
`;

const ErrorMessage = styled.div`
	color: ${colors.red};
	font-size: 12px;
`;
