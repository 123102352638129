import React, {useEffect, useState} from "react";
import {CheckboxInput} from "../inputs/checkbox-input";
import {Button} from "../button";
import styled from "@emotion/styled";
import {colors, desktop, mobile, row, sizes, stack} from "../../style";
import {motion} from "framer-motion";
import {useI18n} from "../../utils/i18n";
import {userProfileService} from "../../services/user-profile-service";
import {UserProfile} from "../../domain/user-profile";
import {TermsDocument} from "../../domain/terms";
import DownloadIcon from "@assets/icons/download.svg";
import { mediaFileService } from "../../services/media-file-service";

interface TermsAgreementSectionProps {
    audience: string;
    userProfile?: UserProfile | null;
    touTermsDoc?: TermsDocument | null;
    gdprTermsDoc?: TermsDocument | null;
}

export const TermsAgreementSection: React.FC<TermsAgreementSectionProps> = ({ audience, userProfile, touTermsDoc, gdprTermsDoc}) => {
    const [checkedTou, setCheckedTou] = useState(false);
    const [checkedGdpr, setCheckedGdpr] = useState(false);
    const [bothStatus, setBothStatus] = useState(false);
    const handleChangeTou = () => {
        setCheckedTou(!checkedTou)
    };
    const handleChangeGdpr = () => {
        setCheckedGdpr(!checkedGdpr)
    };
    const handleClick = async () => {
        const strUserProfileId = JSON.parse(JSON.stringify(userProfile?.id)).value.toString();
        await userProfileService.setAcceptedLastTerms(strUserProfileId)
        if (touTermsDoc && gdprTermsDoc) {
            await userProfileService.addNewTermsRow(userProfile, touTermsDoc.documentName, touTermsDoc.publicationDate, gdprTermsDoc.documentName, gdprTermsDoc.publicationDate)
        }
        await userProfileService.fetchUserProfile(userProfile?.userId, audience);
    }
    const { format } = useI18n();

    useEffect( () => {
        setBothStatus(checkedTou && checkedGdpr)
    }, [checkedTou, checkedGdpr])

    return (
        <Sections>
            <Section>
                <Header>
                    <Title>{format("termsAgreementSection.title")}</Title>
                    <SubtitleImportant>{format("termsAgreementSection.subtitle-important")}
                    </SubtitleImportant>
                    <RowFirst>
                        <RowIcon>
                            <CheckboxInput
                                name="checkbox1"
                                value={checkedTou}
                                onChange={handleChangeTou}
                            >
                            </CheckboxInput>
                            <LinkTerm onClick={async () => {
                                await mediaFileService.getTermsDocument("66ffee517c85c970f30287db")
                            } }>{format("termsAgreementSection.checkbox-Tou.label")} <Souligne>{format("click")}</Souligne></LinkTerm>
                        </RowIcon>
                    </RowFirst>
                    <Row>
                        <RowIcon>
                            <CheckboxInput
                                name = "checkbox2"
                                value={checkedGdpr}
                                onChange={handleChangeGdpr}
                            >
                            </CheckboxInput>
                            <LinkTerm onClick={async () => {
                                await mediaFileService.getTermsDocument("66ffeed77c85c970f3029101")
                            } }>{format("termsAgreementSection.checkbox-Gdpr.label")} <Souligne>{format("click")}</Souligne></LinkTerm>
                        </RowIcon>
                    </Row>
                    <div>
                        <Button
                            disabled={!bothStatus}
                            onClick={handleClick}
                        >{format("termsAgreementSection.submit")}</Button>
                    </div>
                </Header>
            </Section>
        </Sections>
    )
}

const Sections = styled.div`
	${stack("S", "flex-start", "stretch")}
`;

const Section = styled(motion.div)`
	${stack("XL", "flex-start", "stretch")}
	@media ${desktop} {
		padding: ${sizes.L};
		border-radius: 12px;
		box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.05);
		background-color: ${colors.white};
		flex-grow: 1;
	}
`;
const Header = styled.div`
	${stack("S")};
`;

const Title = styled(motion.div)`
	font-size: 20px;
	font-weight: bold;
	color: ${colors.black};
`;

const SubtitleImportant = styled.div`
	font-size: 16px;
	color: ${colors.orange};
`;

const RowFirst= styled.div`
	${row("L", "flex-start", "center")}
	@media ${mobile} {
		margin-top: 25px;
	}
`;

const Row= styled.div`
	${row("L", "flex-start", "center")}
`;

const RowIcon= styled.div`
	${row("S", "flex-end", "flex-end")}
	position: relative;
`;

const DownloadSvg = styled(DownloadIcon)`
	position: absolute;
	top: 3px;
	left: 100%;
	width: 22px;
	height: 22px;
	path,
	rect {
	    fill: ${colors.green};
    }
    
    @media ${mobile} {
		display: none;
	}
`;

const LinkTerm = styled.a`
    cursor: pointer;
    @media ${mobile} {
		text-decoration: underline;
	}
`;

const Souligne = styled.span`
    cursor: pointer;
    text-decoration: underline;
`;
