import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useObservable } from "micro-observables";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { hospitalService, useModules } from "services/hospital-service";
import { colors, mobile, row, sizes, Spacer, stack } from "style";
import { useI18n } from "utils/i18n";
import { AccountSelector } from "./account-selector";
import { UserType } from "../../domain/user-type";
import { createModal } from "../../services/modal-service";
import { IdeaModal } from "../modals/idea-modal";
import { Bulb, ButtonSupport, ToolTipIdeas } from "./practitioner-nav-bar";

export const HospitalNavBar: React.FC<{ className?: string }> = ({ className }) => {
	const history = useNavigate();
	const location = useLocation();
	const { format } = useI18n();
	const profile = useObservable(hospitalService.profile);
	const modules = useModules(profile?.hospitalId);
	const [showToolTip, setShowToolTip] = useState<boolean>(false);

	useEffect(() => {
		hospitalService.fetchProfile();
	}, []);

	return (
		<Container className={className}>
			<LogoContainer>
				<LogoSophi src="/images/logo.svg" />
				<span>{format("practitioner.header.logo")}</span>
			</LogoContainer>
			<Spacer />
			<Tab
				selected={
					location.pathname.endsWith("/hospital") ||
					location.pathname.startsWith("/hospital/dashboard-admittance/practitioner/")
				}
				onClick={() => history("/hospital/dashboard-admittance/practitioner/all/page/0")}
			>
				{format("hospital.navbar.admittance")}
			</Tab>
			{modules && modules.some(item => "anesthesia" == item.type) && (
				<Tab
					selected={location.pathname.startsWith("/hospital/dashboard-anesthesia")}
					onClick={() => history("/hospital/dashboard-anesthesia")}
				>
					{format("hospital.navbar.anesthesia")}
				</Tab>
			)}
			<Spacer />
			<AccountSelector
				name={profile ? `${profile.hospitalName} (${profile.name})` : ""}
				accountType={UserType.Hospital}
			/>
			<div
				onMouseOver={() => setShowToolTip(true)}
				onMouseOut={() => {
					setShowToolTip(false);
				}}
				onClick={() => {
					createModal(({ onClose }) => <IdeaModal onClose={onClose} hospitalUser={profile} />);
				}}
			>
				<ButtonSupport>
					<Bulb />
				</ButtonSupport>
				<ToolTipIdeas>{format("ideas.box.button.tooltip")}</ToolTipIdeas>
			</div>
		</Container>
	);
};

const Container = styled.div`
	${row("L", "flex-start", "center")};
	padding: ${sizes.L};
	background-color: ${colors.white};
	border-radius: 26px;
	box-shadow: 0 22px 64px 0 rgba(0, 0, 0, 0.05);
	font-size: 15px;
	font-weight: bold;

	@media ${mobile} {
		${stack("S", "center", "center")}
	}
`;

const LogoContainer = styled.div`
	${row(5, "flex-start", "center")}
	color: ${colors.black};
`;

const LogoSophi = styled.img`
	width: 29px;
`;

const Tab = styled.div<{ selected: boolean }>`
	position: relative;
	cursor: pointer;
	color: ${colors.black};
	opacity: 0.3;

	${({ selected }) =>
		selected &&
		css`
			opacity: 1;
			:after {
				position: absolute;
				bottom: -15px;
				left: 50%;
				transform: translateX(-50%);
				content: "";
				height: 6px;
				width: 6px;
				border-radius: 3px;
				background-color: ${colors.pink};

				@media ${mobile} {
					left: -15px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		`}
`;
