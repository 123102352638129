import styled from "@emotion/styled";
import { DocumentErrorIcon } from "@assets/icons/document-error.svg";
import { Button } from "components/button";
import React, { useCallback, useState } from "react";
import { ModalCardContainer, ModalCloseIcon } from "services/modal-service";
import { colors, row, stack } from "style";
import { useI18n } from "utils/i18n";
import {motion} from "framer-motion";
import { CCAM } from "../../../domain/surgery";
import { DocCustomLight, SurgeryFrCodePractitioner } from "../../../domain/practitioner";
import { TableNode } from "@table-library/react-table-library/table";

interface ConfirmModalProps {
	onClose: (status: string | null) => void;
	object: CCAM | DocCustomLight | SurgeryFrCodePractitioner | TableNode;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = (
	{
		onClose,
		object
	}) => {
	const { format } = useI18n();
	const [loading, setLoading] = useState(false);
	const save = useCallback(async ()=>{
		try{
			setLoading(true)
		}catch(e: any){
			throw e.message
		}
		finally {
			setLoading(false)
		}
	},[])
	return (

			<ModalContainer>
				<form
					onSubmit={e => {
						e.preventDefault();
						save()
					}}
				>
				<ModalCloseIcon onClick={() => onClose(null)} />
				<Content>
					<DocumentsMessageContainer>
						<ColorDocumentErrorIcon color={colors.orange} />
					<Title>
						{format( "patient.trusted.person.modal.title")}
					</Title>
					</DocumentsMessageContainer>
					<Text>
						{"codeCCAM" in object ? format("practitioner.surgery.removeCCAM") + " " + object.codeCCAM : "ccam" in object ?
							format("practitioner.surgery.removeCCAM") + " le libellé pour " + object.ccam : "name" in object ?
							format("practitioner.surgery.removeCCAM") + " " + object.name :
							format("practitioner.surgery.removeCCAM") + " " + object.surgeryDocumentTypeName}
					</Text>
					<Text>
						{format("confirmDelete")}
					</Text>
					<Buttons>
						<Button
							loading={loading}
							onClick={() => onClose("dismissal")}
						>
							{format("patient.trusted.person.modal.dismiss.confirmation.button")}
						</Button>
						<Button secondary onClick={() => onClose(null)}>
							{format( "patient.trusted.person.modal.dismiss.cancel.button")}
						</Button>
					</Buttons>
				</Content>

				</form>
			</ModalContainer>
	);
};


const ModalContainer = styled(ModalCardContainer)`
	padding: 30px;
	${row(0, "center", "center")};
`;

const DocumentsMessageContainer = styled.div`
	${row("S", "flex-start", "flex-start")};
`;

const ColorDocumentErrorIcon = styled(DocumentErrorIcon)<{ color: string }>`
	width: 32px;
	height: 32px;
	path {
		fill: ${props => props.color};
	}
`;
const Content = styled.div`
	${stack("M")};
	max-width: 500px;
`;
const Title = styled(motion.div)`
	font-size: 20px;
	font-weight: bold;
	color: ${colors.black};
`;

const Buttons = styled.div`
	${row("S", "flex-end", "flex-end")}
`;

const Text = styled(motion.span)<{ textColor?: string }>`
	font-size: 13px;
	color: ${props => props.textColor?? "black"};
`;
