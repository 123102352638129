import { XHRUploadOptions } from "@uppy/xhr-upload";
import { Document, DocumentUpdateDTO, RejectedDocument } from "domain/document";
import { Patient } from "domain/patient";
import {
	CancerType,
	CCAM,
	DocCustom,
	FormField,
	FormValue,
	HealthcareProviderAgency,
	Hospital,
	Module,
	PostOperative,
	PostOperativeUpdateDTO,
	Practitioner,
	Secretary,
	Surgery,
	SurgeryStatus,
	SurgeryUpdateDTO,
} from "domain/surgery";
import { Map } from "immutable";
import { useComputedObservable, useObservable, WritableObservable } from "micro-observables";
import { useCallback, useEffect, useMemo, useState } from "react";
import { authService, useUser } from "services/auth-service";
import { Paginated, PaginationOptions, SortOption } from "utils/pagination";
import { isDateSameDay } from "utils/time";
import { api, apiConfig, buildPaginationParams, buildSearchParams } from "./api";
import { CredentialsUpdate } from "../domain/utils";
import { UserProfile } from "../domain/user-profile";
import {
	PractitionerAmount,
	PractitionerContact,
	SurgeryWithItsHpu,
	InputOptam,
	DocCustomLight,
} from "../domain/practitioner";
import { downloadBlob, secretaryService } from "./secretary-service";
import { CustomDpm } from "../components/sections/form-field-section-secretary";
import { AxiosResponse } from "axios";
import { FormParad } from "../domain/formParad";

export const PATIENT_PAGE_SIZE = 25;
export const SURGERY_PAGE_SIZE = 25;
export const FIRST_PATIENT_PAGE = { page: 0, size: PATIENT_PAGE_SIZE };
export const FIRST_SURGERY_PAGE = { page: 0, size: SURGERY_PAGE_SIZE };

export type PatientCreationDTO = Pick<Patient, "firstName" | "lastName" | "birthDate" | "email" | "phoneNumber1"> & {
	legalGuardian1Id?: string | null;
	legalGuardian2Id?: string | null;
	// email?: string;
	assign: boolean;
	addTutorAfter?: boolean | null;
	tutoredPatient?: Patient | null;
};

export type SecretaryCreationDTO = Pick<Secretary, "firstName" | "lastName"> & {
	email?: string;
	assign: boolean;
};

export type DocCustomDTO = {
	id: string;
	hospitalId?: string;
	practitionerId?: string;
	surgeryDocumentTypeId?: string;
	surgeryCodeFrId?: string;
	healthcareProviderId?: string;
	range?: string;
	name: string;
	whenCreated: string;
	whenUpdated?: string;
	whenDeleted?: string;
	enabled: boolean;
	target: string;
	formFields?: FormField[];
};

export type ResetDocCustomDTO = {
	surgeryDocumentTypeId: string;
};

export class PractitionerService {
	profile = new WritableObservable<Practitioner | null>(null);
	patients = new WritableObservable<Map<number, Paginated<Patient>>>(Map());
	notPaginatedPatients = new WritableObservable<Patient[]>([]);
	patientByIds = new WritableObservable<Map<string, Patient>>(Map());
	secretaries = new WritableObservable<Map<string, Secretary[]>>(Map());
	secretaryByIds = new WritableObservable<Map<string, Secretary>>(Map());
	surgeries = new WritableObservable<Map<number, Paginated<Surgery>>>(Map());
	surgeryByIds = new WritableObservable<Map<string, Surgery>>(Map());
	surgeriesByPatientId = new WritableObservable<Map<string, Surgery[]>>(Map());
	postOperativesBySurgeryId = new WritableObservable<Map<string, PostOperative[]>>(Map());
	modulesByPractitionerId = new WritableObservable<Map<string, Module[]>>(Map());
	cancerTypes = new WritableObservable<CancerType[]>([]);
	healthcareProviderByPractitionerId = new WritableObservable<Map<string, HealthcareProviderAgency[]>>(Map());
	formValue = new WritableObservable<FormValue | null>(null);
	formValues = new WritableObservable<FormValue[]>([]);
	public practitionerCredentials = new WritableObservable<{
		newPassword: string | undefined;
		newPasswordConfirmation: string | undefined;
		currentPassword: string | undefined;
	} | null>(null);
	public practitioner = new WritableObservable<Practitioner | null>(null);
	docCustoms = new WritableObservable<DocCustom[]>([]);
	ccams = new WritableObservable<CCAM[]>([]);
	hospitals = new WritableObservable<Hospital[]>([]);
	ccam = new WritableObservable<CCAM | null>(null);
	surgeriesWithHpus = new WritableObservable<Map<number, Paginated<SurgeryWithItsHpu>>>(Map());
	countSurgeriesWithHpus = new WritableObservable<number | null>(null);
	formParad = new WritableObservable<FormParad | null>(null);
	practitionerAmounts = new WritableObservable<PractitionerAmount[]>([]);
	practitionerContacts = new WritableObservable<PractitionerContact[]>([]);
	practitionerDocuments = new WritableObservable<DocCustomLight[]>([]);
	docCustomsPagined = new WritableObservable<Map<number, Paginated<DocCustom>>>(Map());

	async fetchProfile(): Promise<Practitioner> {
		const profile = await api.get<Practitioner>("practitioner/me");
		this.profile.update(() => profile.data);
		return profile.data;
	}

	async registerHCI(registrationCode: string) {
		const profile = await api.post<Practitioner>("practitioner/register-hospital", { registrationCode });
		this.profile.set(profile.data);
		return profile.data;
	}

	async createPatient(patient: PatientCreationDTO): Promise<Patient> {
		const newPatient = await api.post<Patient>("/practitioner/patient", patient);
		this.patientByIds.update(patientMap => patientMap.set(newPatient.data.id, newPatient.data));
		return newPatient.data;
	}

	async createSurgery(
		patientId: string,
		hospitalId: string,
		surgeryDate: string,
		visitType: string,
		classificationCode: string,
		laterality: string,
		mutual: string,
		healthcareProviderId: string | null,
		anesthesiaType: string,
		visitStartDate: string | null,
		visitEndDate: string | null,
		anesthesiaFormAnswers?: string,
		healthcareProviderReason?: string,
		cancerType?: string,
		cancerStade?: string,
	): Promise<Surgery> {
		const newSurgery = await api.post<Surgery>(`/practitioner/surgery`, {
			patientId,
			hospitalId,
			surgeryDate,
			visitType,
			visitStartDate,
			visitEndDate,
			classificationCode,
			laterality,
			mutual,
			healthcareProviderId,
			anesthesiaType,
			anesthesiaFormAnswers,
			healthcareProviderReason,
			cancerType,
			cancerStade,
		});
		this.surgeryByIds.update(surgeryMap => surgeryMap.set(newSurgery.data.id, newSurgery.data));
		return newSurgery.data;
	}

	async createSecretary(secretary: SecretaryCreationDTO): Promise<Secretary> {
		const newSecretary = await api.post<Secretary>("/practitioner/secretary", secretary);
		this.secretaryByIds.update(secretaryMap => secretaryMap.set(newSecretary.data.id, newSecretary.data));
		return newSecretary.data;
	}

	async fetchPatients(pageOptions: PaginationOptions, sortOptions?: SortOption[], search?: string): Promise<void> {
		const paramsObject = {
			...buildPaginationParams(pageOptions),
			search,
		};
		const params = buildSearchParams(paramsObject, sortOptions);

		const patientPage = await api.get<Paginated<Patient>>("/practitioner/patient", {
			params,
		});
		this.patients.update(patientPages => patientPages.set(patientPage.data.page, patientPage.data));
	}

	async fetchNotPaginatedPatients(): Promise<Patient[]> {
		const notPaginatedPatients = await api.get<Array<Patient>>("/practitioner/patients");
		this.notPaginatedPatients.update(() => notPaginatedPatients.data);
		return notPaginatedPatients.data;
	}

	async fetchPatient(patientId: string): Promise<Patient> {
		const patient = await api.get<Patient>(`practitioner/patient/${patientId}`);
		this.patientByIds.update(patientMap => patientMap.set(patient.data.id, patient.data));
		return patient.data;
	}

	async fetchSurgeries(
		pageOptions: PaginationOptions,
		sortOptions?: SortOption[],
		search?: string,
		active?: boolean,
		future?: boolean
	): Promise<void> {
		const paramsObject = {
			...buildPaginationParams(pageOptions),
			search,
			...(future ? { future: "true" } : {}),
			...(active ? { status: SurgeryStatus.Active } : {}),
		};
		const params = buildSearchParams(paramsObject, sortOptions);

		const surgeryPage = await api.get<Paginated<Surgery>>(`practitioner/surgery`, {
			params,
		});
		this.surgeries.update(surgeryPages => surgeryPages.set(surgeryPage.data.page, surgeryPage.data));
	}

	async fetchPatientSurgeries(patientId: string): Promise<Surgery[]> {
		const pageOptions = { page: 0, pageOptions: 100 };
		const sortOption: SortOption = { by: "surgeryDate", order: "ASC" };

		const patientSurgeryPage = await api.get<Paginated<Surgery>>(`practitioner/surgery`, {
			params: { ...buildPaginationParams(pageOptions, sortOption), patientId },
		});
		this.surgeriesByPatientId.update(surgeriesMap => surgeriesMap.set(patientId, patientSurgeryPage.data.content));
		return patientSurgeryPage.data.content;
	}

	async fetchSurgery(surgeryId: string): Promise<void> {
		const surgery = await api.get<Surgery>(`practitioner/surgery/${surgeryId}`);
		this.surgeryByIds.update(surgeryMap => surgeryMap.set(surgery.data.id, surgery.data));
	}
	async fetchCancerTypes(search?: string): Promise<CancerType[]> {
		const paramsObject = {
			search,
		};
		const params = buildSearchParams(paramsObject);
		const cancerTypeData = await api.get<CancerType[]>(`practitioner/cancerType`, {params});
		this.cancerTypes.update(() => cancerTypeData.data);
		return cancerTypeData.data;
	}

	async getDocument(surgeryId: string, documentId: string | undefined, zip = false): Promise<string | Blob | undefined> {
		if (documentId) {
			try {
				const response = await api.get(`/practitioner/surgery/${surgeryId}/document/${documentId}/download`, {
					responseType: 'blob'// Important: Indique que la réponse doit être traitée comme un Blob
				});

				return downloadBlob(response, zip)
			} catch (error) {
				console.error("Erreur lors du téléchargement du document:", error);
			}
		}
	}
	async getDocumentWithoutSurgery(documentId: string | undefined) {
		if (documentId) {
			try {
				const response = await api.get(`/practitioner/document/${documentId}/download`, {
					responseType: 'blob', // Important: Indique que la réponse doit être traitée comme un Blob
				});

				// Créer un Blob à partir de la réponse
				const blob = new Blob([response.data], { type: 'application/pdf' }); // Spécifiez le type MIME correct si nécessaire

				// Créer une URL pour le Blob
				const url = window.URL.createObjectURL(blob);

				// Ouvre un nouvel onglet avec le document
				window.open(url, '_blank');

				// Nettoyer l'URL du Blob après ouverture
				window.URL.revokeObjectURL(url);
			} catch (error) {
				console.error("Erreur lors du téléchargement du document:", error);
			}
		}
	}

	async deleteDocument(surgeryId: string, documentId: string): Promise<void> {
		await api.delete(`practitioner/surgery/${surgeryId}/document/${documentId}`);
	}

	async updateDocument(surgeryId: string, documentId: string, update: DocumentUpdateDTO): Promise<void> {
		await api.post(`practitioner/surgery/${surgeryId}/document/${documentId}`, update);
	}

	async addWeblink(surgeryId: string, url: string, description: string) {
		await api.post(`practitioner/surgery/${surgeryId}/weblink`, { url: url, description: description });
	}

	async updateSurgery(surgeryId: string, update: SurgeryUpdateDTO): Promise<void> {
		const surgery = await api.post<Surgery>(`/practitioner/surgery/${surgeryId}`, update);
		this.surgeryByIds.update(surgeryMap => surgeryMap.set(surgery.data.id, surgery.data));
	}

	getDocumentUploadEndpoint(surgeryId: string): XHRUploadOptions {
		const user = authService.user.get();
		return {
			endpoint: `${apiConfig.baseURL}/practitioner/surgery/${surgeryId}/document`,
			formData: true,
			method: "post",
			fieldName: "file",
			headers: {
				Authorization: `Bearer ${user?.tokens.accessToken}`,
				...apiConfig.headers,
			},
			timeout: 0,
		} as const;
	}
	async signUp(firstName: string, lastName: string, idCode: string, email: string, password: string) {
		const result = await api.post(`practitioner/register`, { email, firstName, lastName, rppsCode: idCode, password });
		return result.data;
	}

	async getAllPatientByIdentity(firstName: string, lastName: string, birthDate: string): Promise<Patient[]> {
		const patientPage = await api.get<Paginated<Patient>>("/practitioner/patient/search", {
			params: { firstName, lastName, birthDate, page: 0, size: 10 },
		});
		return patientPage.data.content;
	}

	async getAllPatientByEmail(email: string): Promise<Patient[]> {
		const patientPage = await api.get<Paginated<Patient>>("/practitioner/patient/search", {
			params: { email, page: 0, size: 10 },
		});
		return patientPage.data.content;
	}

	async assignPatient(id: string) {
		const patient = await api.post<Practitioner>("/practitioner/patient/assign", { id });
		return patient.data;
	}

	async assignSecretary(id: string) {
		const secretary = await api.post<Practitioner>("/practitioner/secretary/assign", { id });
		return secretary.data;
	}
	async sendOnboardingEmail(patient: Patient) {
		await api.post<Patient>(`practitioner/mailing/${patient.id}`);
	}

	async updatePassword(
		practitionerCredentials: CredentialsUpdate | null,
		patch: Partial<Omit<CredentialsUpdate, "assign">>
	) {
		const newCredentials = await api.post<CredentialsUpdate>("practitioner/password-update", {
			newPassword: practitionerCredentials?.newPassword,
			newPasswordConfirmation: practitionerCredentials?.newPasswordConfirmation,
			currentPassword: practitionerCredentials?.currentPassword,
			...patch,
		});
		this.practitionerCredentials.update(practitionerMap => (practitionerMap ? newCredentials.data : null));
	}

	async editPatient(
		patient: Patient,
		patch: Partial<Omit<PatientCreationDTO, "assign">>,
		addTutorAfter: number | null
	) {
		const newPatient = await api.post<Patient>(`/practitioner/patient/${patient.id}`, {
			firstName: patient.firstName,
			lastName: patient.lastName,
			birthDate: patient.birthDate,
			email: patient.email,
			phoneNumber1: patient.phoneNumber1,
			phoneNumber2: patient.phoneNumber2,
			gender: patient.gender,
			address: patient.address,
			legalGuardian1Id: patient.legalGuardian1?.id,
			legalGuardian2Id: patient.legalGuardian2?.id,
			addTutorAfter,
			...patch,
		});
		// This is a bit tricky but if we omit one these field, the api understands it as null and clean its value
		this.patientByIds.update(patientMap => patientMap.set(newPatient.data.id, newPatient.data));
	}

	async deleteSecretary(secretaryId: string) {
		return await api.delete<Secretary>(`/practitioner/secretary/${secretaryId}`);
	}

	async deleteSurgery(surgeryId: string) {
		const newSurgery = await api.delete<Surgery>(`/practitioner/surgery/${surgeryId}`);
		this.surgeryByIds.update(surgeryMap => surgeryMap.set(newSurgery.data.id, newSurgery.data));
		return newSurgery.data;
	}

	// On logge les actions du praticien sur les documents
	async log(surgeryId: string, documentId: string) {
		await api.get(`/practitioner/log/surgery/${surgeryId}/document/${documentId}`);
	}

	async getAllSecretaryByIdentity(firstName: string, lastName: string, delegation?: boolean): Promise<Secretary[]> {
		const secretaryPage = await api.get<Paginated<Secretary>>("/practitioner/secretary/search", {
			params: { firstName, lastName, delegation, page: 0, size: 10 },
		});
		return secretaryPage.data.content;
	}

	async getAllSecretaryByEmail(email: string): Promise<Secretary[]> {
		const secretaryPage = await api.get<Paginated<Secretary>>("/practitioner/secretary/search", {
			params: { email, page: 0, size: 10 },
		});
		return secretaryPage.data.content;
	}

	async fetchPostOperativeBySurgery(surgeryId: string): Promise<PostOperative[]> {
		const postOperatives = await api.get<PostOperative[]>(`/practitioner/surgery/${surgeryId}/postOperatives`);
		this.postOperativesBySurgeryId.update(postOperativesMap => postOperativesMap.set(surgeryId, postOperatives.data));
		return postOperatives.data;
	}

	async createSurgeryPostOperatives(
		surgeryId: string,
		postOperatives: PostOperativeUpdateDTO[]
	): Promise<PostOperative[]> {
		const arrayPostOperatives = await api.post<PostOperative[]>(
			`/practitioner/surgery/${surgeryId}/postOperatives`,
			postOperatives
		);
		this.postOperativesBySurgeryId.update(arrayPostOperativesMap =>
			arrayPostOperativesMap.set(surgeryId, arrayPostOperatives.data)
		);
		return arrayPostOperatives.data;
	}

	async updateSurgeryPostOperative(surgeryId: string, postOperativeId: string, update: PostOperativeUpdateDTO) {
		const postOperative = await api.post<PostOperative>(
			`/practitioner/surgery/${surgeryId}/postOperative/${postOperativeId}`,
			update
		);
		return postOperative.data;
	}

	async deleteSurgeryPostOperative(surgeryId: string, postOperativeId: string) {
		await api.delete(`/practitioner/surgery/${surgeryId}/postOperative/${postOperativeId}`);
	}
	async fetchPractitionerModulesByHospitalId(hospitalId: string | null): Promise<Module[]> {
		const modules = await api.get<Module[]>(`/practitioner/hospital/${hospitalId}/modules`);
		this.modulesByPractitionerId.update(modulesMap => modulesMap.set("practitionerId", modules.data));
		return modules.data;
	}
	async fetchPractitionerModules(): Promise<Module[]> {
		const modules = await api.get<Module[]>(`/practitioner/modules`);
		this.modulesByPractitionerId.update(modulesMap => modulesMap.set("practitionerId", modules.data));
		return modules.data;
	}

	async createCustomDocument(surgeryId: string, documentTypeId: string, data?: string) {
		await api.post<Document>(
			`/practitioner/surgery/${surgeryId}/documentType/${documentTypeId}/createCustomDocument`,
			data
		);
	}

	async fetchHealthcareProvider(): Promise<HealthcareProviderAgency[]> {
		const healthcareProvider = await api.get<HealthcareProviderAgency[]>(`/practitioner/healthcare-provider`);
		this.healthcareProviderByPractitionerId.update(healthcareProviderMap =>
			healthcareProviderMap.set("practitionerId", healthcareProvider.data)
		);
		return healthcareProvider.data;
	}
	async fetchDocCustom(surgeryId: string): Promise<DocCustom[]> {
		const docCustom = await api.get<DocCustom[]>(`/practitioner/surgery/${surgeryId}/docCustoms/forms`);
		this.docCustoms.update(() => docCustom.data);
		return docCustom.data;
	}

	async updateDelegation(secretary: Secretary): Promise<Secretary> {
		const secretaryUpdated = await api.post<Secretary>(`/practitioner/secretary/${secretary.id}/delegation`, secretary);
		return secretaryUpdated.data;
	}
	async saveSign(profile: Practitioner): Promise<Practitioner> {
		const practitionerUpdated = await api.post<Practitioner>(`/practitioner/saveSign`, profile);
		return practitionerUpdated.data;
	}
	async signDocument(surgeryId: string) {
		await api.post(`/practitioner/surgery/${surgeryId}/sign-documents`);
	}

	async rejectDocument(surgeryDocumentId: string, rejectDescription: string) {
		await api.post(`/practitioner/surgeryDocument/${surgeryDocumentId}/reject-document`, {
			rejectDescription: rejectDescription,
		});
	}

	async sendNotificationHpuRejectedDocs(surgeryId: string, documents: RejectedDocument[]) {
		await api.post<Document[]>(`/practitioner/surgery/${surgeryId}/sendNotifPsadRejected`, documents);
	}

	async updateDocCustom(surgeryId: string, update: DocCustomDTO[]): Promise<DocCustom[]> {
		const docCustom = await api.post<DocCustom[]>(`/practitioner/surgery/${surgeryId}/createCustomDocument`, update);
		return docCustom.data;
	}
	async fetchCCAMS(): Promise<CCAM[]> {
		const ccamData = await api.get<CCAM[]>(`/practitioner/ccam`);
		this.ccams.update(() => ccamData.data);
		return ccamData.data;
	}
	async assignUserProfile(practitionerId: string, userProfile: UserProfile | null) {
		const result = await api.post("practitioner/userprofile", userProfile);
		this.practitioner.set(result.data);
		return result.data;
	}
	async createFormValuesBySurgery(surgeryId: string) {
		const docCustom = await api.post(`/practitioner/surgery/${surgeryId}/createFormValues`);
		return docCustom.data;
	}
	async fetchHospitals(practitionerId: string) {
		const hospitals = await api.get<Hospital[]>(`/practitioner/${practitionerId}/hospitals`);
		this.hospitals.update(() => hospitals.data);
		return hospitals.data;
	}
	async fetchSurgeriesWithHpus(pageOptions: PaginationOptions, search?: string, hpState? : string): Promise<void> {
		const paramsObject = {
			...buildPaginationParams(pageOptions),
			search,
		};
		const params = buildSearchParams(paramsObject);
		let surgeriesWithHpus: AxiosResponse | null = null;
		if (hpState == "ARCHIVED") {
			surgeriesWithHpus = await api.get<Paginated<SurgeryWithItsHpu>>(`/practitioner/surgeriesWithHpus/archived`, {
				params,
			});
		} else {
			surgeriesWithHpus = await api.get<Paginated<SurgeryWithItsHpu>>(`/practitioner/surgeriesWithHpus`, {
				params,
			});
		}

		this.surgeriesWithHpus.update(surgeriesPages =>
			surgeriesPages.set(surgeriesWithHpus?.data.page, surgeriesWithHpus?.data)
		);
	}
	async fetchCCAMByDescription(description: string): Promise<CCAM[] | undefined> {
		if (description.length > 3) {
			const ccamData = await api.get<CCAM[]>(`/practitioner/ccam/description/${description}`);
			this.ccams.update(() => ccamData.data);
			return ccamData.data;
		}
	}

	async getCCAMByCode(ccam: string, practitionerId: string): Promise<CCAM | undefined> {
		if (ccam.length > 6) {
			const ccamData = await api.get<CCAM>(
				`/practitioner/ccam/${ccam}`
			);
			return ccamData.data;
		}
	}

	async getCCAMById(ccamId: string, practitionerId: string): Promise<CCAM | undefined> {
		if (ccamId.length > 7) {
			const ccamData = await api.get<CCAM>(
				`/practitioner/ccamId/${ccamId}`
			);
			return ccamData.data;
		}
	}

	async storeSign(file: Blob | undefined, nameFile: string | undefined) {
		const formData = new FormData();
		if (file != undefined) {
			formData.append("input", file, nameFile);
		}

		await api.post(`/practitioner/store/sign`, formData, {
			headers: {
				Accept: "*/*",
				"Content-Type": "multipart/form-data",
			},
		});
	}

	async startSignature(surgeryId: string) {
		const result = await api.post<Surgery>(`/practitioner/surgery/${surgeryId}/start-signature`);
		this.surgeryByIds.update(surgeryMap => surgeryMap.set(result.data.id, result.data));
		return result.data;
	}
	async countSurgeriesWithHpusAction() {
		const count = await api.get(`/practitioner/surgeriesWithHpus/count`);
		this.countSurgeriesWithHpus.update(() => count.data);
		return count.data;
	}
	async clickSuivi() {
		await api.get(`/practitioner/suivi`);
	}

	async updateDocCustomValues(surgeryId: string, valueInput: CustomDpm) {
		await api.post(`/practitioner/surgery/${surgeryId}/createCustomDpmDocument/values`, valueInput);
	}
	async updateCustomDpmValues(surgeryId: string, customDpmValues: CustomDpm) {
		await api.post(`/practitioner/surgery/${surgeryId}/updateCustomDpmValues`, customDpmValues);
	}

	async fetchParad(patientId: string, surgeryId: string) {
		try {
			const result = await api.get<FormParad>(`/practitioner/patient/${patientId}/surgery/${surgeryId}/parad`);
			this.formParad.set(result.data);
			return result.data;
		} catch (e) {
			console.log(e)
			console.log("Query fetchPatient aborted");
		}
		return null;
	}

	async fetchPractitionerAmount(practitionerId: string) {
		try {
			const result = await api.get<PractitionerAmount[]>(`/practitioner/${practitionerId}/practitioner-amount`);
			this.practitionerAmounts.set(result.data);
			return result.data;
		} catch (e) {
			console.log(e)
			console.log("Query fetchPractitionerAmount aborted");
		}
		return null;
	}

	async addPractitionerAmount(practitionerId: string, practitionerAmount: PractitionerAmount) {
		await api.post<PractitionerAmount>(`/practitioner/${practitionerId}/practitioner-amount/add`, practitionerAmount);
	}

	async updatePractitionerAmount(practitionerId: string, practitionerAmountId: string, practitionerAmount: PractitionerAmount) {
		await api.post<PractitionerAmount>(`/practitioner/${practitionerId}/practitioner-amount/${practitionerAmountId}/update`, practitionerAmount);
	}

	async deletePractitionerAmount(practitionerId: string, practitionerAmountId: string) {
		await api.delete<PractitionerAmount>(`/practitioner/${practitionerId}/practitioner-amount/${practitionerAmountId}/delete`);
	}

	async updateOptam(practitionerId: string, InputOptam: InputOptam) {
		await api.post(`/practitioner/${practitionerId}/optam/update`, InputOptam);
	}
	async fetchPractitionerContact() {
		try {
			const result = await api.get<PractitionerContact[]>(`/practitioner/contacts`);
			this.practitionerContacts.set(result.data);
			return result.data;
		} catch (e) {
			console.log(e)
			console.log("Query fetchPractitionerContact aborted");
		}
		return null;
	}

	async addPractitionerContact(practitionerContact: PractitionerContact) {
		await api.post<PractitionerContact>(`/practitioner/contact/new`, practitionerContact);
	}

	async updatePractitionerContact( practitionerContactId: string, practitionerContact: PractitionerContact) {
		await api.post<PractitionerContact>(`/practitioner/contact/${practitionerContactId}`, practitionerContact);
	}
	async deletePractitionerContact(practitionerContactId: string) {
		await api.delete<PractitionerContact>(`/practitioner/contact/${practitionerContactId}/delete`);
	}
	async fetchPractitionerDocCustom(pageOptions: PaginationOptions, search?: string,) {
		try {
			const paramsObject = {
				...buildPaginationParams(pageOptions),
				search,
			};
			const params = buildSearchParams(paramsObject);

			const result = await api.get<Paginated<DocCustom>>(`/practitioner/docCustomStatus`, {params});
			this.docCustomsPagined.update(surgeryPages => surgeryPages.set(result.data.page, result.data));
		} catch (e) {
			console.log(e)
			console.log("Query fetchPractitionerDocCustom aborted");
		}
		return null;
	}
	async fetchPractitionerDocCustomLight() {
		try {
			const result = await api.get<DocCustomLight[]>(`/practitioner/practitioner-document`);
			this.practitionerDocuments.set(result.data);
			return result.data;
		} catch (e) {
			console.log(e)
			console.log("Query fetchPractitionerAmount aborted");
		}
		return null;
	}

	async postDocCustom(formData: FormData, task: string): Promise<AxiosResponse> {
		return await api.post(`/practitioner/addDocCustom/${task}`, formData)
	}

	async deleteDocCustom(docCustomId: string): Promise<void> {
		await api.delete(`/practitioner/docCustom/${docCustomId}/delete`);
	}

	async getDocCustom(documentId: string | undefined, name: string): Promise<string | Blob | undefined> {
		if (documentId) {
			try {
				const response = await api.get(`/practitioner/document/${documentId}/downloadDocCustom`, {
					responseType: 'blob', // Important: Indique que la réponse doit être traitée comme un Blob
				});

				return downloadBlob(response)
			} catch (error) {
				console.error("Erreur lors du téléchargement du document:", error);
			}
		}
	}

	async addCustomCcam(formData: FormData): Promise<AxiosResponse> {
		return await api.post(`/practitioner/addCustomCcam`, formData)
	}
}

export const practitionerService = new PractitionerService();

export function usePatients(
	pageOptions: PaginationOptions,
	sortOption: SortOption | null,
	search?: string
): { loading: boolean; patients: Paginated<Patient> | undefined; refresh: () => Promise<void> } {
	const user = useUser();
	const patients = useComputedObservable(() => practitionerService.patients.get().get(pageOptions.page), [
		user,
		pageOptions,
		sortOption,
	]);
	const [loading, setLoading] = useState(true);
	const fetchPatients = useCallback(async () => {
		try {
			if (user) {
				setLoading(true);
				await practitionerService.fetchPatients(pageOptions, sortOption ? [sortOption] : undefined, search);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [pageOptions, sortOption, user, search]);

	useEffect(() => {
		fetchPatients();
	}, [fetchPatients]);

	return {
		loading,
		patients,
		refresh: fetchPatients,
	};
}

export function useNotPaginatedPatients(): { notPaginatedPatients: Patient[]; refresh: () => Promise<void> } {
	const user = useUser();

	const notPaginatedPatients = useComputedObservable(() => practitionerService.notPaginatedPatients.get());
	const fetchNotPaginatedPatients = useCallback(async () => {
		try {
			if (user) {
				await practitionerService.fetchNotPaginatedPatients();
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);

	useEffect(() => {
		fetchNotPaginatedPatients();
	}, [fetchNotPaginatedPatients]);

	return {
		notPaginatedPatients,
		refresh: fetchNotPaginatedPatients,
	};
}

export function useSurgeries(
	pageOptions: PaginationOptions,
	sortOption: SortOption | null,
	search?: string,
	active?: boolean,
	future?: boolean
): { loading: boolean; surgeries: Paginated<Surgery> | undefined; refresh: () => Promise<void> } {
	const user = useUser();
	const surgeries = useComputedObservable(() => practitionerService.surgeries.get().get(pageOptions.page), [
		user,
		pageOptions,
		sortOption,
	]);
	const [loading, setLoading] = useState(true);
	const fetchSurgeries = useCallback(async () => {
		try {
			if (user) {
				setLoading(true);
				const implicitSortOption: SortOption = { by: "surgeryDate", order: "ASC" };
				await practitionerService.fetchSurgeries(
					pageOptions,
					sortOption ? [sortOption, implicitSortOption] : [implicitSortOption],
					search,
					active,
					future
				);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [user, pageOptions, sortOption, search, active, future]);

	useEffect(() => {
		fetchSurgeries();
	}, [fetchSurgeries]);

	return {
		loading,
		surgeries,
		refresh: fetchSurgeries,
	};
}

export function usePractitionerProfile() {
	return useObservable(practitionerService.profile);
}

export function usePatient(patientId: string): { loading: boolean; patient: Patient | undefined } {
	const user = useUser();
	const patient = useComputedObservable(() => practitionerService.patientByIds.get().get(patientId), [user, patientId]);
	const [loading, setLoading] = useState(true);
	const fetchPatient = useCallback(async () => {
		try {
			if (user && patientId) {
				setLoading(true);
				await practitionerService.fetchPatient(patientId);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [user, patientId]);

	useEffect(() => {
		fetchPatient().then();
	}, [fetchPatient]);

	return {
		loading,
		patient,
	};
}

export function useSurgery(
	surgeryId?: string,
	practitionerId?: string
): { loading: boolean; surgery: Surgery | undefined } {
	const user = useUser();
	const surgery = useComputedObservable(
		() =>
			surgeryId
				? practitionerId
					? secretaryService.surgeryByIds.get().get(surgeryId)
					: practitionerService.surgeryByIds.get().get(surgeryId)
				: undefined,
		[user, surgeryId]
	);
	const [loading, setLoading] = useState(true);
	const fetchSurgeries = useCallback(async () => {
		try {
			if (user && surgeryId) {
				setLoading(true);
				if (practitionerId as string) {
					await secretaryService.fetchSurgery(surgeryId, practitionerId as string);
				} else {
					await practitionerService.fetchSurgery(surgeryId);
				}
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [user, surgeryId]);

	useEffect(() => {
		fetchSurgeries();
	}, [fetchSurgeries]);

	return {
		loading,
		surgery,
	};
}

export function isSurgeryPast(surgery: Surgery) {
	const actualDate = new Date();
	const surgeryDate = new Date(surgery.surgeryDate);
	return !(isDateSameDay(surgeryDate, actualDate) || surgeryDate > actualDate);
}

export function usePatientSurgeries(
	patientId: string
): {
	loading: boolean;
	surgeries: Surgery[] | undefined;
	pastSurgeries: Surgery[];
	futureSurgeries: Surgery[];
} {
	const user = useUser();
	const surgeries = useComputedObservable(() => practitionerService.surgeriesByPatientId.get().get(patientId), [
		user,
		patientId,
	]);
	const [loading, setLoading] = useState(true);
	const [pastSurgeries, futureSurgeries] = useMemo(() => {
		const actualDate = new Date();
		return (
			surgeries?.reduce(
				(acc: [Surgery[], Surgery[]], surgery) => {
					const surgeryDate = new Date(surgery.surgeryDate);
					return isDateSameDay(surgeryDate, actualDate) || surgeryDate > actualDate
						? [acc[0], [...acc[1], surgery]]
						: [[...acc[0], surgery], acc[1]];
				},
				[[], []]
			) ?? [[], []]
		);
	}, [surgeries]);

	const fetchPatientSurgeries = useCallback(async () => {
		try {
			if (user && patientId) {
				setLoading(true);
				await practitionerService.fetchPatientSurgeries(patientId);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [user, patientId]);

	useEffect(() => {
		fetchPatientSurgeries();
	}, [fetchPatientSurgeries]);

	return {
		loading,
		surgeries,
		pastSurgeries,
		futureSurgeries,
	};
}

export function findClosestSurgery(surgeries: Surgery[]) {
	const now = new Date();
	return surgeries.reduce<Surgery | null>((closestSurgery, surgery) => {
		const closestSurgeryDate = closestSurgery ? new Date(closestSurgery.surgeryDate) : null;
		const surgeryDate = new Date(surgery.surgeryDate);
		if (surgeryDate < now) {
			if (!closestSurgeryDate || surgeryDate > closestSurgeryDate) {
				return surgery;
			} else {
				return closestSurgery;
			}
		} else {
			if (!closestSurgeryDate) {
				return surgery;
			} else if (closestSurgeryDate < now) {
				return surgery;
			} else if (surgeryDate < closestSurgeryDate) {
				return surgery;
			} else {
				return closestSurgery;
			}
		}
	}, null);
}

export function usePostOperatives(surgeryId: string) {
	const user = useUser();
	const postOperatives = useComputedObservable(
		() => practitionerService.postOperativesBySurgeryId.get().get(surgeryId),
		[user, surgeryId]
	);
	const fetchPostOperatives = useCallback(async () => {
		try {
			if (user && surgeryId) {
				await practitionerService.fetchPostOperativeBySurgery(surgeryId);
			}
		} catch (e) {
			console.log(e);
		}
	}, [user, surgeryId]);
	useEffect(() => {
		fetchPostOperatives().then();
	}, [fetchPostOperatives]);

	return postOperatives;
}

export function useModulesByPractitionerId() {
	const user = useUser();
	const modules = useComputedObservable(() => practitionerService.modulesByPractitionerId.get().get("practitionerId"));
	const fetchModules = useCallback(async () => {
		try {
			if (user) {
				await practitionerService.fetchPractitionerModules();
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);
	useEffect(() => {
		fetchModules().then();
	}, [fetchModules]);

	return modules;
}

export function useHealthCareProviders(): { healthcareProvidersAgencies: HealthcareProviderAgency[] | undefined } {
	const user = useUser();
	const healthcareProvidersAgencies = useComputedObservable(() =>
		practitionerService.healthcareProviderByPractitionerId.get().get("practitionerId")
	);
	const fetchHealthcareProvider = useCallback(async () => {
		try {
			if (user) {
				await practitionerService.fetchHealthcareProvider();
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);
	useEffect(() => {
		fetchHealthcareProvider().then();
	}, [fetchHealthcareProvider]);
	return {healthcareProvidersAgencies};
}

export function useDocs(surgeryId: string): { docCustomForms: DocCustom[] } {
	const user = useUser();
	const docCustomForms = useComputedObservable(() => practitionerService.docCustoms.get(), []);
	const fetchDocs = useCallback(async () => {
		try {
			if (user) {
				await practitionerService.fetchDocCustom(surgeryId);
			}
		} catch (e) {
			console.log("Query fetch DocCustomForm aborted");
		}
	}, [user]);
	useEffect(() => {
		fetchDocs().then();
	}, [fetchDocs]);
	return { docCustomForms };
}

export function useHospitalsPractitioner(practitionerId: string | undefined): { hospitalList: Hospital[] } {
	const user = useUser();
	const hospitalList = useComputedObservable(() => practitionerService.hospitals.get(), []);
	const fetchHospitals = useCallback(async () => {
		try {
			if (user && practitionerId as string) {
				await practitionerService.fetchHospitals(practitionerId as string);
			}
		} catch (e) {
			console.log("Query fetch DocCustomForm aborted");
		}
	}, []);
	useEffect(() => {
		fetchHospitals().then();
	}, []);
	return { hospitalList };
}

export function useCancerType(search?: string): { cancerTypeList: CancerType[] } {
	const user = useUser();
	const cancerTypeList = useComputedObservable(() => practitionerService.cancerTypes.get(), []);
	const fetchCancerTypes = useCallback(async () => {
		try {
			if (user) {
				await practitionerService.fetchCancerTypes(search);
			}
		} catch (e) {
			console.log("Query fetch CancerType aborted");
		}
	}, []);
	useEffect(() => {
		fetchCancerTypes().then();
	}, []);
	return { cancerTypeList };
}

export function useCCAMDatabasePractitioner(): { ccamList: CCAM[] } {
	const user = useUser();
	const ccamList = useComputedObservable(() => practitionerService.ccams.get(), []);
	const fetchCCAMS = useCallback(async () => {
		try {
			if (user) {
				await practitionerService.fetchCCAMS();
			}
		} catch (e) {
			console.log("Query fetch DocCustomForm aborted");
		}
	}, []);
	useEffect(() => {
		fetchCCAMS().then();
	}, []);
	return { ccamList };
}

export function useSurgeriesWithHpusAndPractitioner(
	pageOptions: PaginationOptions,
	search?: string,
	practitionerId?: string,
	hpState? : string,
): {
	loadingSurgeriesWithHpus: boolean;
	surgeriesWithHpus: Paginated<SurgeryWithItsHpu> | undefined;
	refresh: () => Promise<void>;
} {
	const user = useUser();
	const surgeriesWithHpus = useComputedObservable(
		() => practitionerService.surgeriesWithHpus.get().get(pageOptions.page),
		[user, pageOptions]
	);
	const [loadingSurgeriesWithHpus, setLoadingSurgeriesWithHpus] = useState(true);
	const fetchSurgeriesWithHpus = useCallback(async () => {
		try {
			if (user) {
				setLoadingSurgeriesWithHpus(true);
				if (practitionerId as string) {
					await secretaryService.fetchSurgeriesWithHpus(pageOptions, search, practitionerId as string, hpState);
				} else {
					await practitionerService.fetchSurgeriesWithHpus(pageOptions, search, hpState);
				}
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoadingSurgeriesWithHpus(false);
		}
	}, [pageOptions, user, search]);

	useEffect(() => {
		fetchSurgeriesWithHpus().then();
	}, [fetchSurgeriesWithHpus]);

	return {
		loadingSurgeriesWithHpus,
		surgeriesWithHpus,
		refresh: fetchSurgeriesWithHpus,
	};
}

export function useCountSurgeriesWithHpusAndPractitioner(
	practitionerId?: string
): {
	count: number | null;
	loadingSurgeriesWithHpus: boolean;
} {
	const user = useUser();
	const count = useComputedObservable(() => practitionerService.countSurgeriesWithHpus.get(), []);
	const [loadingSurgeriesWithHpus, setLoadingSurgeriesWithHpus] = useState(true);
	const fetchCountSurgeriesWithHpus = useCallback(async () => {
		try {
			if (user) {
				setLoadingSurgeriesWithHpus(true);
				if (practitionerId as string) {
					await secretaryService.countSurgeriesWithHpusAction(practitionerId as string);
				} else {
					await practitionerService.countSurgeriesWithHpusAction();
				}
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoadingSurgeriesWithHpus(false);
		}
	}, [user]);

	useEffect(() => {
		fetchCountSurgeriesWithHpus().then();
	}, [fetchCountSurgeriesWithHpus]);

	return {
		loadingSurgeriesWithHpus,
		count,
	};
}

export function usePractitionerFormParad(patientId: string, surgeryId: string, audience: string): { practitionerFormParad: FormParad | null } {
	const user = useUser();
	const practitionerFormParad = useComputedObservable(() => practitionerService.formParad.get(), [])
	const fetchFormParad = useCallback(async () => {
		try {
			if (user && audience == "practitioner") {
				await practitionerService.fetchParad(patientId, surgeryId);
			}
		} catch (e) {
			console.log(e);
		}
	}, [user, patientId, surgeryId, audience]);
	useEffect(() => {
		fetchFormParad().then();
	}, [fetchFormParad]);
	return {practitionerFormParad};
}

export function usePractitionerAmount(): PractitionerAmount[] {
	return useObservable(practitionerService.practitionerAmounts);
}

export function usePractitionerContact(): PractitionerContact[] {
	return useObservable(practitionerService.practitionerContacts);
}
export function usePractitionerDocCustom(
	pageOptions: PaginationOptions,
	search?: string,
): { loading: boolean; practitionerDocCustoms: Paginated<DocCustom> | undefined; refresh: () => Promise<void> } {
	const user = useUser();
	const practitionerDocCustoms = useComputedObservable(() => practitionerService.docCustomsPagined.get().get(pageOptions.page), [
		user,
		pageOptions,
	]);
	const [loading, setLoading] = useState(true);
	const fetchDocCustom = useCallback(async () => {
		try {
			if (user) {
				setLoading(true);
				await practitionerService.fetchPractitionerDocCustom(
					pageOptions,
					search,
				);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [user, pageOptions, search]);

	useEffect(() => {
		fetchDocCustom();
	}, [fetchDocCustom]);

	return {
		loading,
		practitionerDocCustoms,
		refresh: fetchDocCustom,
	};
}

export function usePractitionerDocument() {
	const user = useUser();
	const docCustomLights = useComputedObservable(() => practitionerService.practitionerDocuments.get(), []);
	const fetchDocCustomLight = useCallback(async () => {
		try {
			if (user) {
				await practitionerService.fetchPractitionerDocCustomLight();
			}
		} catch (e) {
			console.log("Query fetch DocCustomForm aborted");
		}
	}, [user]);
	useEffect(() => {
		fetchDocCustomLight().then();
	}, [fetchDocCustomLight]);
	return { docCustomLights };
}
