import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { useI18n } from "../../utils/i18n";
import { colors, mobile, row, sizes, Spacer, stack } from "../../style";
import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";
import { secretaryService, useSecretaryProfile } from "../../services/secretary-service";
import { createModal } from "../../services/modal-service";
import { IdeaModal } from "../modals/idea-modal";
import { Bulb, ButtonSupport, ToolTipIdeas } from "./practitioner-nav-bar";
import { Location } from "history";

export const SecretarySubNavBar: React.FC<{ className?: string; location?: Location | null }> = ({
	className,
	location: locationProp,
}) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const location = locationProp || useLocation(); // This should not cause ESLint error
	const { format } = useI18n();
	const { practitionerId } = useParams<{ practitionerId: string }>();
	const [showToolTip, setShowToolTip] = useState<boolean>(false);
	const [bounce, setBounces] = useState<boolean>(
		!(
			!!location.pathname.match(`/secretary/practitioner/${practitionerId}/dashboard-follow-ups`) ||
			!!location.pathname.match(
				/^\/secretary\/practitioner\/[a-zA-Z0-9]+\/patient\/[a-zA-Z0-9]+\/surgery\/[a-zA-Z0-9]+\/forensic-healthcare-provider-documents+$/i
			)
		)
	);
	const profile = useSecretaryProfile();
	const history = useNavigate();

	useEffect(() => {
		secretaryService.fetchProfile();
	}, []);

	return (
		<Container className={className}>
			<Spacer />
			<Tab
				selected={
					!!location.pathname.match(`/secretary/practitioner/${practitionerId}/dashboard-follow-ups`) ||
					!!location.pathname.match(
						/^\/secretary\/practitioner\/[a-zA-Z0-9]+\/patient\/[a-zA-Z0-9]+\/surgery\/[a-zA-Z0-9]+\/forensic-healthcare-provider-documents+$/i
					)
				}
				onClick={() => {
					history( `/secretary/practitioner/${practitionerId}/dashboard-follow-ups`, {
						state: { practitionerId: practitionerId, fromLogin: false },
					});
					setBounces(false);
					// afficher/masquer la notification pour l'onglet suivi
					secretaryService.clickSuivi(practitionerId as string).then();
				}}
			>
				{format("secretary.header.accepted")}
			</Tab>
			<Tab
				selected={
					!!location.pathname.match(`/secretary/practitioner/${practitionerId}/hpu-archived`) ||
					!!location.pathname.match(
						/^\/secretary\/practitioner\/[a-zA-Z0-9]+\/patient\/[a-zA-Z0-9]+\/surgery\/[a-zA-Z0-9]+\/forensic-healthcare-provider-documents+$/i
					)
				}
				onClick={() => {
					history(`/secretary/practitioner/${practitionerId}/hpu-archived`, {
						state: { practitionerId: practitionerId, fromLogin: false },
					});
					setBounces(false);
				}}
			>
				{format("secretary.header.archived")}
			</Tab>
			<Spacer />
			<FloatingButton
				onMouseOver={() => setShowToolTip(true)}
				onMouseOut={() => {
					setShowToolTip(false);
				}}
				onClick={() => {
					createModal(({ onClose }) => <IdeaModal onClose={onClose} profile={profile} />);
				}}
			>
				<ButtonSupport>
					<Bulb />
				</ButtonSupport>
				<ToolTipIdeas>{format("ideas.box.button.tooltip")}</ToolTipIdeas>
			</FloatingButton>
		</Container>
	);
};

const Container = styled.div`
	${row("L", "flex-start", "center")};
	padding: ${sizes.L};
	background-color: ${colors.white};
	border-radius: 26px;
	box-shadow: 0 22px 64px 0 rgba(0, 0, 0, 0.05);
	font-size: 15px;
	font-weight: bold;

	@media ${mobile} {
		${stack("S", "center", "center")}
	}
`;

const Tab = styled.div<{ selected: boolean }>`
	position: relative;
	cursor: pointer;
	color: ${colors.black};
	opacity: 0.3;

	${({ selected }) =>
		selected &&
		css`
			opacity: 1;
			:after {
				position: absolute;
				bottom: -15px;
				left: 50%;
				transform: translateX(-50%);
				content: "";
				height: 9px;
				width: 9px;
				border-radius: 5px;
				background-color: ${colors.pink};

				@media ${mobile} {
					left: -15px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		`}
`;
const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
`;
export const Counter = styled.div<{ fontSize: number; bounce: boolean }>`
	color: white;
	font-size: ${props => props.fontSize}px;
	position: absolute;
	opacity: 0.8;
	bottom: 5px;
	${props =>
		props.bounce
			? css`
					left: 44px;
			  `
			: css`
					left: 58px;
			  `};
	transform: translateX(-50%);
	text-align: center;
	height: 24px;
	width: 24px;
	border-radius: 50%;
	background-color: ${colors.pink};
	animation: ${props =>
		props.bounce
			? css`
					${bounceAnimation} 1s infinite;
			  `
			: "none"};
`;
const FloatingButton = styled.div``;
