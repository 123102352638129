import React, { useState, useCallback, useMemo, useEffect, useRef } from "react";
import styled from '@emotion/styled';
import { colors, row } from "../../style";
import { css } from "@emotion/react";

interface CheckboxMultiSelectProps<T> {
	innerId: string;
	defaultValue: string;
	values: T[] | null;
	onChange: (values: T[]) => void;
	options: readonly T[];
	itemRenderer?: (item: T) => string;
	itemNumber?: number;
	uniqueChoice?: boolean;
}

export type SelectInputType<K> = React.FC<CheckboxMultiSelectProps<K>>;

export function CheckboxMultiSelect<T>({
	 innerId: id,
	 defaultValue,
	 values,
	 onChange,
	 options,
	 itemRenderer = v => `${v}`,
	 itemNumber = options.length,
	 uniqueChoice,
 }: CheckboxMultiSelectProps<T>): JSX.Element {
	const [isOpen, setIsOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const containerRef = useRef<HTMLDivElement>(null);

	const handleToggleMenu = useCallback(() => {
		setIsOpen(isOpen => !isOpen);
	}, []);
	const handleTagClick = useCallback((value: T) => {
		const updatedValues = values?.filter(val => val !== value) || [];
		onChange(updatedValues);
	}, [values, onChange]);

	const handleOptionClick = useCallback(
		(value: T) => {
			let updatedValues;
			if (uniqueChoice) {
				// Si uniqueChoice est activé, ne garder que la nouvelle valeur
				updatedValues = [value];
				setIsOpen(false);
			} else {
				// Sinon, ajouter ou retirer la valeur dans la sélection multiple
				const isChecked = values?.includes(value);
				updatedValues = isChecked
					? values?.filter((val) => val !== value) || []
					: [...(values || []), value];
			}

			onChange(updatedValues);
		},
		[values, onChange, uniqueChoice]
	);

	const filteredOptions = useMemo(() => {
		const normalizeString = (str: string) =>
			str.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // Retirer les accents

		let filtered = options.slice(0, itemNumber);
		if (searchTerm.trim().length > 3) {
			const normalizedSearchTerm = normalizeString(searchTerm).toLowerCase();
			filtered = options.filter(option =>
				normalizeString(itemRenderer(option)).toLowerCase().includes(normalizedSearchTerm)
			);
		}
		return filtered;
	}, [searchTerm, itemRenderer, itemNumber, options]);

	const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value);
	}, []);
	const handleClickOutside = useCallback((e: MouseEvent) => {
		if (isOpen) {
			const target = e.target as HTMLElement;
			if (!target.closest("#" + id)) {
				setIsOpen(false);
			}
		}
	}, [isOpen, id]);

	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [handleClickOutside]);
	const handleClearAll = useCallback(() => {
		onChange([]);
	}, [onChange]);

	const handleClearSearch = useCallback(() => {
		setSearchTerm('');
	}, []);

	return (
		<CheckboxMultiSelectContainer id={id} ref={containerRef}>
			<CheckboxMultiSelectWrapper>
				<CheckboxMultiSelectTrigger onClick={handleToggleMenu}>
					{values && values.length > 0 ? (
						values.map((v, index) => (
							<span onClick={()=>handleTagClick(v)} key={index} className="selected-tag">{itemRenderer(v)}</span>
						))
					) : (
						defaultValue
					)}
				</CheckboxMultiSelectTrigger>
				{isOpen && (
					<CheckboxMultiSelectMenu>
						<SearchWrapper>
							<SearchInput
								type="text"
								placeholder="Rechercher..."
								value={searchTerm.toUpperCase()}
								onChange={handleSearchChange}
							/>
							{searchTerm !== '' && (
								<ClearSearchButton onClick={handleClearSearch} src="/icons/close.svg" />
							)}
						</SearchWrapper>
						{filteredOptions.map((option, index) => (
							<CheckboxMultiSelectOptionWrapper key={index}>
								<InputWrapper onClick={() => handleOptionClick(option)}>
									<CheckedInput type="checkbox" isChecked={values ? values.includes(option) : false} required/>
									<OptionLabel onClick={() => handleOptionClick(option)}>{itemRenderer(option)}</OptionLabel>
								</InputWrapper>
							</CheckboxMultiSelectOptionWrapper>
						))}
					</CheckboxMultiSelectMenu>
				)}
				{values && values.length > 0 ? <ClearAllButton disabled={false} onClick={handleClearAll} src="/icons/close.svg" /> : <ArrowDownIcon src="/icons/dropdown.svg" />}
			</CheckboxMultiSelectWrapper>
		</CheckboxMultiSelectContainer>
	);
}
const CheckboxMultiSelectContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    flex-shrink: 1;
    width: 100%; /* Utilisez une largeur de 100% pour s'adapter à la largeur du conteneur parent */
`;

const CheckboxMultiSelectWrapper = styled.div`
    position: relative;
    flex-shrink: 1;
    width: 100%; /* Utilisez une largeur de 100% pour s'adapter à la largeur du conteneur parent */
`;

const CheckboxMultiSelectTrigger = styled.div`
    overflow: hidden;
    font-family: inherit;
    appearance: none;
    -webkit-appearance: none;
    font-size: 15px;
    color: ${colors.black};
    padding: 8px 30px 10px 16px;
    border-radius: 8px;
    border: none;
    box-shadow: 0px 0px 0px 1px #c8efec inset;
    background-color: ${colors.white};
    outline: 0;
    cursor: pointer;

    &:disabled {
        color: ${colors.grey2};
        background: #f4f4f4;
        cursor: not-allowed;
    }

    &:focus {
        box-shadow: 0 0 0 2px ${colors.green} inset;
    }

    .selected-tag {
        display: inline-block;
        background-color: ${colors.green}; // Change the background color as needed
        color: ${colors.white}; // Change the text color as needed
        border-radius: 16px;
        padding: 4px 4px; // Adjust padding as needed
        margin-right: 4px;
				margin-bottom: 8px;// Adjust margin between tags as needed
    }
`;

const ArrowDownIcon = styled.img`
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 15px;
`;

const CheckboxMultiSelectMenu = styled.div`
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    z-index: 1;
    box-shadow: 0px 0px 0px 1px #c8efec inset;
    background-color: white;
    border: 1px solid #e1e1e1;
    border-radius: 16px;
    max-height: 200px;
    overflow-y: auto;
    width: 100%; /* Utilisez une largeur de 100% pour s'adapter à la largeur du conteneur parent */
`;

const ClearAllButton = styled.img<{ disabled: boolean }>`
    pointer-events: ${props => props.disabled ? "none" : "auto"};
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 15px;
    z-index: 10;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`;

const CheckboxMultiSelectOptionWrapper = styled.div`
    display: flex;
    align-items: center;
		margin-left: 8px;
`;

const OptionLabel = styled.span`
    padding: 6px 12px;
    cursor: pointer;
`;

const SearchWrapper = styled.div`
    position: relative;
    width: 90%; /* Utilisez une largeur de 100% pour s'adapter à la largeur du conteneur parent */
`;


const SearchInput = styled.input`
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 8px;
    margin-top: 10px;
    margin-left: 3px;
    width: 90%; /* Utilisez une largeur de 100% pour s'adapter à la largeur du conteneur parent */
`;
const ClearSearchButton = styled.img`
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 15px;
    z-index: 10;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`;

const InputWrapper = styled.div`
	${row("S", "flex-start", "center")};
	cursor: pointer;
`;
const CheckedInput = styled.input<{ isChecked: boolean }>`
    appearance: none;
    position: relative;
    width: 25px;
    height: 25px;
    border-radius: 6px;
    border: solid 1px ${colors.grey};
    background-color: ${colors.white};
    outline: none;
    margin: 0;
    cursor: pointer;

    ${({ isChecked }) =>
            isChecked &&
            css`
                :after {
                    content: "";
                    position: absolute;
                    height: 15px;
                    width: 15px;
                    top: 4px;
                    left: 4px;
                    background-color: ${colors.pink};
                    border-radius: 3px;
                }
            `};

    @media (max-width: 768px) {
        width: 20px; /* Réduisez la taille de la case à cocher pour les appareils mobiles */
        height: 20px; /* Réduisez la taille de la case à cocher pour les appareils mobiles */
    }

    &:focus-visible {
        border: solid 1px transparent;
        box-shadow: 0 0 0 2px ${colors.green} inset;
        outline: 0;
        bottom: 4px;
    }
`;
