import styled from "@emotion/styled";
import { Page } from "components/page";
import { UserType } from "domain/user-type";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useI18n } from "utils/i18n";
import { useRequireAuth } from "utils/navigation";
import { colors, mobile, row, stack } from "../../style";
import { Section, SectionTitle } from "../../components/section";
import { FormField } from "../../components/form-field";
import { fullName } from "../../domain/utils";
import { Button } from "../../components/button";
import { CreatePractitionerAmountModal } from "../../components/modals/practitioner/create-practitionerAmount-modal";
import { createModal } from "../../services/modal-service";
import { CCAM } from "../../domain/surgery";
import PencilIcon from "@assets/icons/pen-filled.svg";
import EffacerIcon from "@assets/icons/effacer.svg";
import { ConfirmModal } from "../../components/modals/secretary/confirm-modal";
import {
	practitionerService,
	usePractitionerAmount,
	usePractitionerProfile,
	useCCAMDatabasePractitioner,
} from "../../services/practitioner-service";
import { PractitionerNavBar } from "../../components/navigation/practitioner-nav-bar";
import { InputOptam } from "../../domain/practitioner";
import { CheckboxInput } from "../../components/inputs/checkbox-input";
import { SearchCCAMModal } from "../../components/modals/search-ccam-modal";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";

export default function PractitionerCcam(): JSX.Element {
	const practitioner = usePractitionerProfile();
	useRequireAuth(UserType.Practitioner);
	const [optam, setOptam] = useState<number|undefined>(practitioner?.isOptam);
	const practitionerAmounts = usePractitionerAmount();
	useEffect(() => {
		if (practitioner) {
			practitionerService.fetchPractitionerAmount(practitioner?.id).then();
			setOptam(practitioner?.isOptam)
		}
	}, [practitioner]);
	const { format, formatDpmMode } = useI18n();
	const profile = usePractitionerProfile();
	const { ccamList } = useCCAMDatabasePractitioner();
	const saveOptam = useCallback(async (optamEvent: boolean) => {
		try {
			if (practitioner) {
				const inputOptam: InputOptam = {
					optam
				}
				inputOptam.optam = optamEvent ? 1 : 0
				setOptam(optamEvent ? 1 : 0)
				await practitionerService.updateOptam(practitioner.id, inputOptam);
				practitionerService.fetchProfile().then()
				practitionerService.fetchPractitionerAmount(practitioner.id).then();
			}
		} catch (e: any) {
			alert(e.message);
			throw e.message;
		}
	}, [optam, practitioner])

	return (
		<Paged>
			<Helmet>
				<title>{format("secretary.account.pageTitle")}</title>
			</Helmet>
			<NavBarPractitioner />

			<Title>{format("parameter")}</Title>

			{profile && practitioner &&
				<>
				<SubMenu>
					<LinkMenu
						to={`/practitioner/practitionerCcam`}
						selected={!!location.pathname.match(`/practitioner/practitionerCcam`)}>
						{format("general")}
					</LinkMenu>
					<LinkMenu
						to={`/practitioner/customCcam`}
						selected={!!location.pathname.match(`/secretary/practitioner/customCcam`)}>
						{format("customCcam")}
					</LinkMenu>
					<LinkMenu
						to={`/practitioner/practitionerDocument`}
						selected={!!location.pathname.match(`/practitioner/practitionerDocument`)}>
						{format("documents")}
					</LinkMenu>
					<LinkMenu
						to={`/practitioner/practitionerAddDocument`}
						selected={!!location.pathname.match(`/practitioner/practitionerAddDocument`)}>
						{format("ticketing")}
					</LinkMenu>
				</SubMenu>

				<Sections>
					<Section layout>
						<SectionTitle layout>{format("dpmCustom")}</SectionTitle>
						<ProfileInfo>
							<FormField label={format("dr")}>
								{fullName(practitioner)}
							</FormField>
							<FormField label={format("optam")}>
								<CheckboxInput value={optam == 1} onChange={(e) => saveOptam(e)} />
							</FormField>
						</ProfileInfo>

						<FieldStack style={{ gridArea: "searchClassificationCode" }}>
							<LinkButton
								onClick={async () => {
									await createModal<string | null>(({ onClose }) => (
										<SearchCCAMModal onClose={onClose} />
									));
									practitionerService.fetchCCAMS().then();
								}}
							>
								{format("surgerySection.searchCCAM.link2")}
							</LinkButton>
						</FieldStack>

						<CustomButton type="button" onClick={
							async () => await createModal(({ onClose }) => (
									<CreatePractitionerAmountModal
										practitionerId={practitioner.id}
										onClose={onClose}/>
							))
						} style={{ alignSelf: "flex-start" }}>
							<div style={{ fontSize: "13px", lineHeight: "1em" }}>Ajouter un montant</div>
						</CustomButton>

						{
							practitionerAmounts.map((item, index) => {
								const foundItem = ccamList.find(it => it.id === item.codeCcamId);
								// Appel de calcDpm si foundItem existe
								if (foundItem) {
									return (
											<Stack key={`edit-${index}`}>
												<Row>
													{foundItem?.codeCCAM}
												</Row>
												<Row>
													Mode : &quot;{formatDpmMode(item.mode)}&quot;
												</Row>
												<Row>
													Base : {optam == 1 ? foundItem?.price : foundItem?.priceOptam }
												</Row>
													{formatDpmMode(item.mode) === "Pourcentage" ? (
												<Row>
													Pourcentage : {item.amount} %
												</Row>
											) : (
												<Row>
													Suppléments : {item.amount} €
												</Row>
											)}
												<Row>
													Total : {calcDpm(item.mode, foundItem, item.amount, optam)} €
												</Row>

												<Row>
													<LogoLink
														key={`update-${index}`}
														onClick={
															async () => await createModal(({ onClose }) => (
																<CreatePractitionerAmountModal
																	practitionerId={practitioner.id}
																	practitionerAmountId={item.id}
																	onClose={onClose}
																/>
															))
														} style={{ alignSelf: "flex-start" }}
													>
														<StyledPencilIcon />
													</LogoLink>
													<LogoLink
														key={`delete-${index}`}
														onClick={
															async () => {
																const action = await createModal<string | null>(({ onClose }) => (
																	<ConfirmModal onClose={onClose} object={foundItem}/>
																));
																if (item && item.id != undefined && action === "dismissal") {
																	await practitionerService.deletePractitionerAmount(practitioner.id, `${item.id}`.valueOf());
																	practitionerService.fetchPractitionerAmount(practitioner.id).then();
																}
															}
														}
														style={{ alignSelf: "flex-start" }}
													>
														<StyledEffacerIcon />
													</LogoLink>
												</Row>
											</Stack>
									);
								}
							})
						}
					</Section>
				</Sections>
			</>
			}
		</Paged>
	);
}

const Title = styled.h1`
	color: ${colors.black};
`;

const Paged = styled(Page)`
    width: 80vw;
`;

const Sections = styled.div`
    ${row("M", "center", "stretch")};
`;

const Stack = styled.div`
    border: 1px solid lightgray;
		padding: 7px;
    ${row("M", "space-between", "flex-start")};
`;

const Row = styled.div`
    ${row("M", "center", "flex-start")};
`;

const ProfileInfo = styled.div`
    ${stack("M")};
`;

const NavBarPractitioner = styled(PractitionerNavBar)`
    width: 100%;
`;

const CustomButton = styled(Button)`
    @media ${mobile} {
        max-width: 64px;
    }
`;

const LogoLink = styled.div`
    ${row("M", "center", "stretch")};
    cursor: pointer;
`;

const StyledPencilIcon = styled(PencilIcon)<{ selected: boolean }>`
    width: 29px;
    height: 29px;
    path {
        fill: ${colors.black}; /* Couleur initiale */
    }

    &:hover {
        path {
            fill: ${colors.pink}; /* Couleur au survol */
        }
    }
`;

const StyledEffacerIcon = styled(EffacerIcon)<{ selected: boolean }>`
    width: 29px;
    height: 29px;
    path {
        fill: ${colors.black}; /* Couleur initiale */
    }

    &:hover {
        path {
            fill: ${colors.pink}; /* Couleur au survol */
        }
    }
`;

const FieldStack = styled.div`
	${stack("S")}
`;

const LinkButton = styled.div`
	display: inline-block;
	background-color: transparent;
	color: black;
	text-decoration: underline;
	cursor: pointer;
	&:hover {
		color: ${colors.pink};
	}
`;

const SubMenu = styled.div`
	${row("S", "flex-start", "flex-start")}
`;

const LinkMenu = styled(Link)<{ selected: boolean }>`
  ${row("S", "flex-start", "flex-start")}
	cursor: pointer;
	color: ${colors.black};
	opacity: 0.3;

	${({ selected }) =>
	selected &&
	css`
			opacity: 1;
			:after {
				position: relative;
				bottom: -25px;
				left: -45%;
				transform: translateX(-50%);
				content: "";
				height: 9px;
				width: 9px;
				border-radius: 5px;
				background-color: ${colors.pink};
			}
		`}
`;

export function calcDpm(dpmMode: string, ccam: CCAM, amount: string, optam: number|undefined) {
	// console.log("$$$$$$$$$$$$$$$");
	// console.log("OPTAM", optam);
	let total = 0;
	if (optam != undefined) {
		const tbSomme = optam ? Number(ccam.price) : Number(ccam.priceOptam);
		//console.log("initial tbSomme:", tbSomme);
		// console.log("initial total:", total);
		// console.log("tbSomme:", tbSomme);
		// console.log("dpmMode: ", dpmMode);
		// console.log("dpmAmount: ", amount);
		if (tbSomme) {
			switch (dpmMode) {
				// supplement
				case "Fixed_amount": {
					total = Number(amount) + tbSomme;
					// console.log("total: " + Number(amount) + " + " + tbSomme + " = " + total);
					break;
				}
				case "Percentage": {
					total = tbSomme * (Number(amount) / 100);
					// console.log("total: " + tbSomme + " * (" + Number(amount) + " / 100) = " + total);
					break;
				}
				case "Total_amount": {
					total = Number(amount) - tbSomme;
					// console.log("total: " + Number(amount) + " - " + tbSomme + " = " + total);
					break;
				}
			}
		}
	}
	// console.log("dpmResult:", total);
	// console.log("$$$$$$$$$$$$$$$");
	return total.toFixed(2)
}
