import { css, Global } from "@emotion/react";
import React, { lazy } from "react";
import { IntlProvider } from "react-intl";
import { Route, Routes } from "react-router-dom";
import { wording } from "../wording";
import { ModalContainer } from "./services/modal-service";

const globalStyles = css`
	html,
	body {
		padding: 0;
		margin: 0;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		background-color: #f4f4f4;
	}

	* {
		box-sizing: border-box;
		text-edge: cap alphabetic;
		leading-trim: both;
		font-family: sofia-pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
			Droid Sans, Helvetica Neue, sans-serif;
	}

	a {
		color: black;
		text-decoration: none;
	}

	input {
		font-feature-settings: unset;
		font-size: 0.8rem;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
	}
`;

export function App() {
	return (
		<IntlProvider locale="fr" defaultLocale="fr" messages={wording.fr}>
			<Global styles={globalStyles} />
			<React.Suspense fallback={null}>
					<Routes>
						<Route  path="/" Component={lazy(() => import("./pages/index"))} />
						<Route  path="/patient" Component={lazy(() => import("./pages/patient/index"))} />
						<Route  path="/patient/login" Component={lazy(() => import("./pages/patient/login"))} />
						<Route
							
							path="/patient/password-recovery"
							Component={lazy(() => import("./pages/patient/password-recovery"))}
						/>
						<Route
							
							path="/patient/password-reset"
							Component={lazy(() => import("./pages/patient/password-reset"))}
						/>
						<Route
							
							path="/patient/password-reset/tutor"
							Component={lazy(() => import("./pages/patient/password-reset"))}
						/>
						<Route
							
							path="/patient/password-update"
							Component={lazy(() => import("./pages/patient/password-update"))}
						/>
						<Route
							
							path="/practitioner/password-update"
							Component={lazy(() => import("./pages/practitioner/password-update"))}
						/>
						<Route
							
							path="/secretary/password-update"
							Component={lazy(() => import("./pages/secretary/password-update"))}
						/>
						<Route  path="/hpu/password-update" Component={lazy(() => import("./pages/hpu/password-update"))} />
						<Route
							
							path="/hospital/password-update"
							Component={lazy(() => import("./pages/hospital/password-update"))}
						/>
						<Route
							
							path="/patient/sign-redirect"
							Component={lazy(() => import("./pages/patient/sign-redirect"))}
						/>
						<Route
							
							path="/patient/surgery/:surgeryId/anesthesia"
							Component={lazy(() => import("./pages/patient/surgery/surgeryId/anesthesia"))}
						/>
						<Route
							
							path="/patient/surgery/:surgeryId/forensic-documents"
							Component={lazy(() => import("./pages/patient/surgery/surgeryId/forensic-documents"))}
						/>
						<Route
							
							path="/patient/surgery/:surgeryId/patient-info"
							Component={lazy(() => import("./pages/patient/surgery/surgeryId/patient-info"))}
						/>
						<Route
							
							path="/patient/surgery/:surgeryId/patient-documents"
							Component={lazy(() => import("./pages/patient/surgery/surgeryId/patient-documents"))}
						/>
						<Route
							
							path="/patient/surgery/:surgeryId/surgery"
							Component={lazy(() => import("./pages/patient/surgery/surgeryId/surgery"))}
						/>
						<Route  path="/practitioner/account" Component={lazy(() => import("./pages/practitioner/account"))} />
						<Route
							
							path="/practitioner/dashboard-patients"
							Component={lazy(() => import("./pages/practitioner/dashboard-patients"))}
						/>
						<Route
							
							path="/practitioner/dashboard-surgeries"
							Component={lazy(() => import("./pages/practitioner/dashboard-surgeries"))}
						/>
						<Route
							
							path="/practitioner-secretary"
							Component={lazy(() => import("./pages/practitioner/practitioner-secretary"))}
						/>
						<Route  path="/practitioner" Component={lazy(() => import("./pages/practitioner/index"))} />
						<Route  path="/practitioner/login" Component={lazy(() => import("./pages/practitioner/login"))} />
						<Route
							
							path="/practitioner/patient/:patientId"
							Component={lazy(() => import("./pages/practitioner/patient/patientId"))}
						/>
						<Route
							
							path="/practitioner/patient/:patientId/surgery/:surgeryId/anesthesia"
							Component={lazy(() => import("./pages/practitioner/patient/patientId/surgery/surgeryId/anesthesia"))}
						/>
						<Route
							
							path="/practitioner/patient/:patientId/surgery/:surgeryId/forensic-documents"
							Component={lazy(
								() => import("./pages/practitioner/patient/patientId/surgery/surgeryId/forensic-documents")
							)}
						/>
						<Route
							
							path="/practitioner/patient/:patientId/surgery/:surgeryId/patient-info"
							Component={lazy(() => import("./pages/practitioner/patient/patientId/surgery/surgeryId/patient-info"))}
						/>
						<Route
							
							path="/practitioner/patient/:patientId/surgery/:surgeryId/surgery"
							Component={lazy(() => import("./pages/practitioner/patient/patientId/surgery/surgeryId/surgery"))}
						/>
						<Route
							
							path="/practitioner/patient/:patientId/surgery/:surgeryId/post-operative"
							Component={lazy(() => import("./pages/practitioner/patient/patientId/surgery/surgeryId/post-operative"))}
						/>
						<Route  path="/practitioner/sign-up" Component={lazy(() => import("./pages/practitioner/sign-up"))} />
						<Route
							
							path="/practitioner/password-recovery"
							Component={lazy(() => import("./pages/practitioner/password-recovery"))}
						/>
						<Route
							
							path="/practitioner/password-reset"
							Component={lazy(() => import("./pages/practitioner/password-reset"))}
						/>
						<Route
							
							path="/practitioner/patient/:patientId/surgery/create"
							Component={lazy(() => import("./pages/practitioner/create-surgery"))}
						/>
						<Route
							
							path="/practitioner/patient/:patientId/surgery/createEvaluation"
							Component={lazy(() => import("./pages/practitioner/create-evaluation"))}
						/>
						<Route
							
							path="/practitioner/patient/:patientId/surgery/:surgeryId/forensic-evaluation-documents"
							Component={lazy(() => import("./pages/practitioner/patient/patientId/surgery/surgeryId/forensic-evaluation-documents"))}
						/>
						<Route
							
							path="/practitioner/patient/:patientId/surgery/:surgeryId/evaluation"
							Component={lazy(() => import("./pages/practitioner/patient/patientId/surgery/surgeryId/evaluation"))}
						/>
						<Route
							
							path="/practitioner/dashboard-follow-ups"
							Component={lazy(() => import("./pages/practitioner/dashboard-follow-ups"))}
						/>
						<Route
							
							path="/practitioner/hpu-archived"
							Component={lazy(() => import("./pages/practitioner/dashboard-hpu-archived"))}
						/>
						<Route
							
							path="/practitioner/patient/:patientId/surgery/:surgeryId/forensic-healthcare-provider-documents"
							Component={lazy(
								() =>
									import(
										"./pages/practitioner/patient/patientId/surgery/surgeryId/forensic-healthcare-provider-documents"
									)
							)}
						/>
						<Route  path="/secretary" Component={lazy(() => import("./pages/secretary/index"))} />
						<Route  path="/secretary/login" Component={lazy(() => import("./pages/secretary/login"))} />
						<Route  path="/secretary/account" Component={lazy(() => import("./pages/secretary/account"))} />
						<Route
							
							path="/secretary/practitioner/:practitionerId/dashboard-patients"
							Component={lazy(() => import("./pages/secretary/dashboard-patients"))}
						/>
						<Route
							
							path="/secretary/practitioner/:practitionerId/dashboard-surgeries"
							Component={lazy(() => import("./pages/secretary/dashboard-surgeries"))}
						/>
						<Route
							
							path="/secretary/practitioner/:practitionerId/patient/:patientId/surgery/:surgeryId/patient-info"
							Component={lazy(() => import("./pages/secretary/patient/patientId/surgery/surgeryId/patient-info"))}
						/>
						<Route
							
							path="/secretary/practitioner/:practitionerId/patient/:patientId"
							Component={lazy(() => import("./pages/secretary/patient/patientId"))}
						/>
						<Route
							
							path="/secretary/practitioner/:practitionerId/patient/:patientId/surgery/:surgeryId/surgery"
							Component={lazy(() => import("./pages/secretary/patient/patientId/surgery/surgeryId/surgery"))}
						/>
						<Route
							
							path="/secretary/practitioner/:practitionerId/patient/:patientId/surgery/:surgeryId/forensic-documents"
							Component={lazy(() => import("./pages/secretary/patient/patientId/surgery/surgeryId/forensic-documents"))}
						/>
						<Route
							
							path="/secretary/practitioner/:practitionerId/patient/:patientId/surgery/:surgeryId/anesthesia"
							Component={lazy(() => import("./pages/secretary/patient/patientId/surgery/surgeryId/anesthesia"))}
						/>
						<Route
							
							path="/secretary/password-recovery"
							Component={lazy(() => import("./pages/secretary/password-recovery"))}
						/>
						<Route
							
							path="/secretary/password-reset"
							Component={lazy(() => import("./pages/secretary/password-reset"))}
						/>
						<Route
							
							path="/secretary/practitioner/:practitionerId/patient/:patientId/surgery/:surgeryId/post-operative"
							Component={lazy(() => import("./pages/secretary/patient/patientId/surgery/surgeryId/post-operative"))}
						/>
						<Route
							
							path="/secretary/practitioner/:practitionerId/patient/:patientId/surgery/create"
							Component={lazy(() => import("./pages/secretary/create-surgery"))}
						/>
						<Route
							
							path="/secretary/practitioner/:practitionerId/patient/:patientId/surgery/createEvaluation"
							Component={lazy(() => import("./pages/secretary/create-evaluation"))}
						/>
						<Route
							
							path="/secretary/practitioner/:practitionerId/patient/:patientId/surgery/:surgeryId/evaluation"
							Component={lazy(() => import("./pages/secretary/patient/patientId/surgery/surgeryId/evaluation"))}
						/>
						<Route
							
							path="/secretary/practitioner/:practitionerId/dashboard-follow-ups"
							Component={lazy(() => import("./pages/practitioner/dashboard-follow-ups"))}
						/>
						<Route
							
							path="/secretary/practitioner/:practitionerId/hpu-archived"
							Component={lazy(() => import("./pages/practitioner/dashboard-hpu-archived"))}
						/>
						<Route
							
							path="/secretary/practitioner/:practitionerId/patient/:patientId/surgery/:surgeryId/forensic-healthcare-provider-documents"
							Component={lazy(
								() =>
									import("./pages/secretary/patient/patientId/surgery/surgeryId/forensic-healthcare-provider-documents")
							)}
						/>
						<Route
							
							path="/secretary/practitioner/:practitionerId/patient/:patientId/surgery/:surgeryId/forensic-evaluation-documents"
							Component={lazy(
								() =>
									import("./pages/secretary/patient/patientId/surgery/surgeryId/forensic-evaluation-documents")
							)}
						/>
						<Route
							
							path="/hospital/dashboard-admittance/practitioner/:practitionerId/page/:pageParam"
							Component={lazy(() => import("./pages/hospital/dashboard-admittance"))}
						/>
						<Route
							
							path="/hospital/admittance/surgery/:surgeryId/practitioner/:practitionerId"
							Component={lazy(() => import("./pages/hospital/admittance/surgery/surgeryId"))}
						/>
						<Route
							
							path="/hospital/anesthesia/surgery/:surgeryId"
							Component={lazy(() => import("./pages/hospital/anesthesia/surgery/surgeryId"))}
						/>
						<Route  path="/hospital" Component={lazy(() => import("./pages/hospital/dashboard-admittance"))} />
						<Route
							
							path="/hospital/dashboard-anesthesia"
							Component={lazy(() => import("./pages/hospital/dashboard-anesthesia"))}
						/>
						<Route  path="/hospital/login" Component={lazy(() => import("./pages/hospital/login"))} />
						<Route
							
							path="/trusted-person/:trustedPersonId/surgery/:surgeryId/patient/:patientId"
							Component={lazy(() => import("./pages/trusted-person/index"))}
						/>
						<Route  path="/404" Component={lazy(() => import("./pages/http/404"))} />
						<Route  path="/404-link-tutor" Component={lazy(() => import("./pages/http/404-link-tutor"))} />
						<Route  path="/hpu" Component={lazy(() => import("./pages/hpu/index"))} />
						<Route  path="/hpu/login" Component={lazy(() => import("./pages/hpu/login"))} />
						<Route
							
							path="/hpu/password-recovery"
							Component={lazy(() => import("./pages/hpu/password-recovery"))}
						/>
						<Route  path="/hpu/password-reset" Component={lazy(() => import("./pages/hpu/password-reset"))} />
						<Route
							
							path="/hpu/dashboard-practitioners"
							Component={lazy(() => import("./pages/hpu/dashboard-practitioners"))}
						/>
						<Route
							
							path="/hpu/dashboard-patients-accepted"
							Component={lazy(() => import("./pages/hpu/dashboard-patients-accepted"))}
						/>
						<Route
							
							path="/hpu/dashboard-patients-archived"
							Component={lazy(() => import("./pages/hpu/dashboard-patients-archived"))}
						/>
						<Route
							
							path="/hpu/dashboard-patients-refused"
							Component={lazy(() => import("./pages/hpu/dashboard-patients-refused"))}
						/>
						<Route
							
							path="/hpu/patient/:patientId/surgery/:surgeryId/patient-info"
							Component={lazy(() => import("./pages/hpu/surgery/surgeryId/patient-info"))}
						/>
						<Route
							
							path="/hpu/patient/:patientId/surgery/:surgeryId/forensic-hpu-documents"
							Component={lazy(() => import("./pages/hpu/surgery/surgeryId/forensic-hpu-documents"))}
						/>
						<Route
							path="/hpu/add-documents"
							Component={lazy(() => import("./pages/hpu/add-practitioner-document"))}
						/>
						<Route
							path="/hpu/docCustom"
							Component={lazy(() => import("./pages/hpu/custom-document"))}
						/>

						<Route  path="/legal-notice" Component={lazy(() => import("./pages/legal-notice"))} />
						<Route  path="/contact" Component={lazy(() => import("./pages/contact/index"))} />

						<Route  path="/support-technical/login" Component={lazy(() => import("./pages/support-technical/login"))} />
						<Route  path="/support-technical" Component={lazy(() => import("./pages/support-technical/index"))} />
						<Route  path="/support-technical/user/:supportTechnicalId" Component={lazy(() => import("./pages/support-technical/patient"))} />
						<Route  path="/support-technical/account" Component={lazy(() => import("./pages/support-technical/account"))} />
						<Route
							
							path="/support-technical/password-recovery"
							Component={lazy(() => import("./pages/support-technical/password-recovery"))}
						/>
						<Route
							
							path="/support-technical/password-reset"
							Component={lazy(() => import("./pages/support-technical/password-reset"))}
						/>
						<Route
							
							path="/support-technical/password-update"
							Component={lazy(() => import("./pages/support-technical/password-update"))}
						/>
						<Route
							path="/support-technical/doc-custom"
							Component={lazy(() => import("./pages/support-technical/docCustom"))}
						/>
						<Route
							path="/support-technical/add-practitioner-document"
							Component={lazy(() => import("./pages/support-technical/add-practitioner-document"))}
						/>
						<Route
							path="/support-technical/create-practitioner"
							Component={lazy(() => import("./pages/support-technical/create-practitioner"))}
						/>
						<Route
							path="/support-technical/create-secretary"
							Component={lazy(() => import("./pages/support-technical/create-secretary"))}
						/>
						<Route
							path="/support-technical/create-hospital"
							Component={lazy(() => import("./pages/support-technical/create-hospital"))}
						/>
						<Route
							path="/support-technical/create-cmt"
							Component={lazy(() => import("./pages/support-technical/create-hpu"))}
						/>
						<Route
							path="/support-technical/create-agency"
							Component={lazy(() => import("./pages/support-technical/create-agency"))}
						/>
						<Route
							path="/secretary/practitioner/:practitionerId/practitionerCcam"
							Component={lazy(() => import("./pages/secretary/practitioner-ccam"))}
						/>
						<Route
							
							path="/practitioner/practitionerCcam"
							Component={lazy(() => import("./pages/practitioner/practitioner-ccam"))}
						/>
						<Route
							
							path="/practitioner/practitionerContact"
							Component={lazy(() => import("./pages/practitioner/practitioner-contact"))}
						/>
						<Route
							
							path="/secretary/practitioner/:practitionerId/practitionerContact"
							Component={lazy(() => import("./pages/secretary/practitioner-contact"))}
						/>
						<Route

							path="/secretary/practitioner/:practitionerId/customCcam"
							Component={lazy(() => import("./pages/common/custom-ccam"))}
						/>
						<Route

							path="/practitioner/customCcam"
							Component={lazy(() => import("./pages/common/custom-ccam"))}
						/>
						<Route
							
							path="/secretary/practitioner/:practitionerId/practitionerDocument"
							Component={lazy(() => import("./pages/secretary/practitioner-document"))}
						/>
						<Route
							
							path="/practitioner/practitionerDocument"
							Component={lazy(() => import("./pages/practitioner/practitioner-document"))}
						/>
						<Route
							
							path="/secretary/practitioner/:practitionerId/practitionerAddDocument"
							Component={lazy(() => import("./pages/secretary/practitioner-addDocument"))}
						/>
						<Route
							
							path="/practitioner/practitionerAddDocument"
							Component={lazy(() => import("./pages/practitioner/practitioner-addDocument"))}
						/>
					</Routes>
			</React.Suspense>
			<ModalContainer />
		</IntlProvider>
	);
}
