import styled from "@emotion/styled";
import { SurgeryStepIcon } from "components/surgery-step-icon";
import { Patient } from "domain/patient";
import { allEvaluationSteps, SurgeryStep } from "domain/surgery-step";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { colors, row } from "style";
import { SurgeryDocumentSource } from "../../domain/document";
import { useSurgery } from "../../services/practitioner-service";
import { Practitioner } from "domain/surgery";
import { css, keyframes } from "@emotion/react";

interface PractitionerEvaluationTimelineProps {
	practitioner: Practitioner;
	step: SurgeryStep;
	patient: Patient;
	surgeryId?: string;
	modules?: string[];
}

export const PractitionerEvaluationTimeline: React.FC<PractitionerEvaluationTimelineProps> = ({
	practitioner,
	step: currentStep,
	patient,
	surgeryId,
	modules,
}) => {
	// region Modules - on ajoute le module post operative
	let arraySteps = allEvaluationSteps;
	const { surgery } = useSurgery(surgeryId);

	if (modules) {
		for (let i = 0; i <= modules.length; i++) {
			switch (true) {
				case !arraySteps.some(item => SurgeryStep.Evaluation == item):
					arraySteps = [...arraySteps, SurgeryStep.Evaluation];
					break;
				default:
					break;
			}
		}
	}
	// endregion

	return (
		<Steps>
			{arraySteps.map((step, index) => (
				<Fragment key={index}>
					<Link
						key={step}
						to={`/practitioner/patient/${patient.id}/surgery/${surgeryId}/${step}`}
					>
						{
							practitioner.evaluation == 1 &&
							step == SurgeryStep.ForensicEvaluationDocuments &&
							currentStep != SurgeryStep.ForensicEvaluationDocuments &&
							surgery &&
							surgery.surgeryDocuments.filter(
						(doc: any) =>
						doc.documentType?.source == SurgeryDocumentSource.HPU &&
						doc.needSignature &&
						doc.isSigned
						).length !=
							surgery.surgeryDocuments.filter(
								(doc: any) => doc.documentType?.source == SurgeryDocumentSource.HPU && doc.needSignature
							).length ? (

							<Counter fontSize={14} bounce={true}>
								{
									surgery.surgeryDocuments.filter(
										(doc: any) =>
											doc.documentType?.source == SurgeryDocumentSource.HPU &&
											doc.needSignature &&
											doc.isSigned == 0
									).length
								}
							</Counter>

						) : ""}

						<SurgeryStepIcon step={step} active={true} focused={currentStep === step} />
					</Link>
					{index < arraySteps.length - 1 ? <Progress pending={true} /> : null}
				</Fragment>
			))}
		</Steps>
	);
};

const Progress = styled.div<{ pending: boolean | undefined }>`
	width: 31px;
	height: 4px;
	border-radius: 2.5px;
	background-color: ${colors.grey};
	flex-shrink: 1;
	flex-grow: 1;

	div {
		background-color: ${props => (props.pending ? colors.orange : colors.green)};
	}
`;
const Steps = styled.div`
	${row("S", "center", "center")};
	position: relative;
`;


const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

const Counter = styled.div<{ fontSize: number; bounce: boolean }>`
	position: absolute;
	left: 115px;
	color: white;
	font-size: ${props => props.fontSize}px;
	opacity: 0.8;
	transform: translateX(-50%);
	text-align: center;
	height: 24px;
	width: 24px;
	border-radius: 50%;
	background-color: ${colors.pink};
	animation: ${props =>
	props.bounce
		? css`
					${bounceAnimation} 1s infinite;
			  `
		: "none"};
`;
