import { Patient } from "domain/patient";
import { useComputedObservable, useObservable, WritableObservable } from "micro-observables";
import { api, buildPaginationParams, buildSearchParams } from "./api";
import { UserProfile } from "../domain/user-profile";
import { CredentialsUpdate } from "../domain/utils";
import { HealthCareProviderUser, Module, Rectangle, SupportTechnical } from "../domain/support-technical";
import { Agency, Hospital } from "../domain/hpu";
import { useUser } from "./auth-service";
import { useCallback, useEffect, useState } from "react";
import { CCAM, DocCustom, HealthcareProvider, Practitioner, Secretary, Speciality } from "../domain/surgery";
import { AxiosResponse } from "axios";
import { Map } from "immutable";
import { Paginated, PaginationOptions, SortOption } from "../utils/pagination";

export class SupportTechnicalService {
	profile = new WritableObservable<SupportTechnical | null>(null);
	public supportTechnical = new WritableObservable<SupportTechnical | null>(null);
	public supportTechnicalCredentials = new WritableObservable<{
		newPassword: string | undefined;
		newPasswordConfirmation: string | undefined;
		currentPassword: string | undefined;
	} | null>(null);
	practitionersSettings = new WritableObservable<Practitioner[]>([]);
	ccams = new WritableObservable<CCAM[]>([]);
	hospitals = new WritableObservable<Hospital[]>([]);
	modules = new WritableObservable<Module[]>([]);
	secretaries = new WritableObservable<Secretary[]>([]);
	specialities = new WritableObservable<Speciality[]>([]);
	agencies = new WritableObservable<Agency[]>([]);
	healthcareProviders = new WritableObservable<HealthcareProvider[]>([]);
	cmts = new WritableObservable<HealthCareProviderUser[]>([]);
	docCustomsPagined = new WritableObservable<Map<number, Paginated<DocCustom>>>(Map());
	practitioner = new WritableObservable<Practitioner | null>(null)

	async fetchProfile(): Promise<SupportTechnical> {
		const profile = await api.get<SupportTechnical>("/support-technical/me");
		this.profile.update(() => profile.data);
		return profile.data;
	}
	async assignUserProfile(userProfile: UserProfile | null) {
		const result = await api.post("/support-technical/userprofile", userProfile);
		this.supportTechnical.set(result.data);
		return result.data;
	}
	async searchByLastNamePatient(firstName: string|null, lastName: string|null): Promise<Patient[]> {
		const newPatients = await api.post<Patient[]>("/support-technical/patients", null, {
			params: { firstName: firstName, lastName: lastName},
		});
		return newPatients.data;
	}
	async updatePassword(
		supportCredentialCredentials: CredentialsUpdate | null,
		patch: Partial<Omit<CredentialsUpdate, "assign">>
	) {
		const newCredentials = await api.post<CredentialsUpdate>("/support-technical/password-update", {
			newPassword: supportCredentialCredentials?.newPassword,
			newPasswordConfirmation: supportCredentialCredentials?.newPasswordConfirmation,
			currentPassword: supportCredentialCredentials?.currentPassword,
			...patch,
		});
		this.supportTechnicalCredentials.update(item => (item ? newCredentials.data : null));
	}
	async generateDocument(rectangles: Rectangle[]) {
	return await api.post("/support-technical/docCustom", rectangles)
	}
	async fetchListPractitioners(): Promise<Practitioner[]>{
		const listPractitioners = await api.get<Practitioner[]>("/support-technical/list-practitioners");
		this.practitionersSettings.update(() => listPractitioners.data);
		return listPractitioners.data
	}
	async fetchCCAMS(): Promise<CCAM[]> {
		const ccamData = await api.get<CCAM[]>(`/support-technical/ccam`);
		this.ccams.update(() => ccamData.data);
		return ccamData.data;
	}
	async fetchHospitals(): Promise<Hospital[]> {
		const hospitals = await api.get<Hospital[]>("/support-technical/hospitals");
		this.hospitals.set(hospitals.data);
		return hospitals.data;
	}
	async fetchSecretaries(): Promise<Secretary[]> {
		const secretaries = await api.get<Secretary[]>("/support-technical/secretaries");
		this.secretaries.set(secretaries.data);
		return secretaries.data;
	}
	async fetchSpecialities(): Promise<Speciality[]> {
		const specialities = await api.get<Speciality[]>("/support-technical/specialities");
		this.specialities.set(specialities.data);
		return specialities.data;
	}
	async fetchAgencies(): Promise<Agency[]> {
		const agencies = await api.get<Agency[]>("/support-technical/agencies");
		this.agencies.set(agencies.data);
		return agencies.data;
	}
	async fetchCmts(healthcareProviderAgencyId: string): Promise<HealthCareProviderUser[]> {
		const cmts = await api.get<HealthCareProviderUser[]>("/support-technical/healthcareProviderUsers", {params : {healthcareProviderAgencyId}});
		this.cmts.set(cmts.data);
		return cmts.data;
	}
	async fetchModules(): Promise<Module[]> {
		const module = await api.get<Module[]>(`/support-technical/modules`);
		this.modules.update(() => module.data);
		return module.data;
	}
	async fetchHealthcareProviders(): Promise<HealthcareProvider[]> {
		const healthcareProviders = await api.get<HealthcareProvider[]>("/support-technical/healthcareProviders");
		this.healthcareProviders.set(healthcareProviders.data);
		return healthcareProviders.data;
	}
	async postDocCustom(formData: FormData):Promise<AxiosResponse>{
		return await api.post(`/support-technical/addDocCustom`, formData)
	}
	async fetchPractitionerDocCustom(pageOptions: PaginationOptions, search?: string,) {
		try {
			const paramsObject = {
				...buildPaginationParams(pageOptions),
				search,
			};
			const params = buildSearchParams(paramsObject);

			const result = await api.get<Paginated<DocCustom>>(`/support-technical/docCustomStatus`, {params});
			this.docCustomsPagined.update(surgeryPages => surgeryPages.set(result.data.page, result.data));
		} catch (e) {
			console.log(e)
			console.log("Query fetchPractitionerDocCustom aborted");
		}
		return null;
	}
	async updateDocCustomStatus(id: string, status: string) {
		try {
			await api.post(`/support-technical/docCustom/${id}/status/update`, {status: status})
		} catch (e){
			console.log(e)
			console.log("Query updateDocCustomStatus aborted");
		}
	}
	async getDocumentUrl(name: string, dlConnectorId?: string, zip = false) {
		if (dlConnectorId) {
			try {
				const response = await api.get(`/support-technical/docCustom/${dlConnectorId}/download`, {
					responseType: 'blob', // Important: Indique que la réponse doit être traitée comme un Blob
				});

				if (!zip) {
					// Créer un Blob à partir de la réponse
					const blob = new Blob([response.data], { type: 'application/pdf' }); // Spécifiez le type MIME correct si nécessaire

					// Créer une URL pour le Blob
					const url = window.URL.createObjectURL(blob);

					// Ouvre un nouvel onglet avec le document
					window.open(url, '_blank');

					// Nettoyer l'URL du Blob après ouverture
					window.URL.revokeObjectURL(url);
				} else {
					return new Blob([response.data], { type: 'application/pdf' }); // Spécifiez le type MIME correct si nécessaire
				}
			} catch (error) {
				console.error("Erreur lors de l'ouverture du document:", error);
			}
		}
	}
	async createPractitioner(
		fistName: string,
		lastName: string,
		email: string,
		rppsCode: string,
		hospitalsValue: string[],
		secretariesValue: string[],
		specialitiesValue: string[],
		evaluation: boolean,
		sendOnBoardingMail: boolean,
		cmtValues: string[],
		agenciesValue: string[]) {
		return await api.post(`/support-technical/createPractitioner`,
				{
					firstName: fistName,
					lastName: lastName,
					email: email,
					rppsCode: rppsCode,
					hospitalsValue: hospitalsValue,
					secretariesValue: secretariesValue,
					specialitiesValue: specialitiesValue,
					evaluation: evaluation,
					sendOnBoardingMail: sendOnBoardingMail,
					healthcareProviderUserId: cmtValues,
					healthcareProviderAgencyId: agenciesValue,
				})
	}

	async createSecretary(
		fistName: string,
		lastName: string,
		email: string,
		practitionersValue: string[],
		sendOnBoardingMail: boolean,
		) {
		return await api.post(`/support-technical/createSecretary`,
			{
				firstName: fistName,
				lastName: lastName,
				email: email,
				practitionerId: practitionersValue,
				sendOnBoardingMail: sendOnBoardingMail,
			})
	}
	async createHospital(
		phoneNumber: string,
		name: string,
		city: string,
		practitionersValue: string[],
		modulesValue: string[],
	) {
		return await api.post(`/support-technical/createHospital`,
			{
				name: name,
				phoneNumber: phoneNumber,
				city: city,
				practitionerId: practitionersValue,
				modules: modulesValue
			})
	}
	async createHpu(
		firstName: string,
		lastName: string,
		email: string,
		healthcareProvidersValue: string[],
		healthcareProviderAgenciesValue: string[],
		sendOnBoardingMail: boolean
	) {
		console.log()
		return await api.post(`/support-technical/createHpu`,
			{
				firstName: firstName,
				lastName: lastName,
				email: email,
				healthcareProviders: healthcareProvidersValue,
				healthcareProviderAgencies: healthcareProviderAgenciesValue,
				sendOnBoardingMail: sendOnBoardingMail
			})
	}
	async createAgency(
		name: string,
		phoneNumber: string,
		email: string,
		healthcareProvidersValue: string[],
		practitioners: string[],
		street: string,
		city: string,
		postalCode: string,
	) {
		console.log()
		return await api.post(`/support-technical/createAgency`,
			{
				name: name,
				phoneNumber: phoneNumber,
				email: email,
				healthcareProviders: healthcareProvidersValue,
				practitioners: practitioners,
				street: street,
				city: city,
				postalCode: postalCode
			})
	}
}

export const supportTechnicalService = new SupportTechnicalService();

export function useSupportTechicalProfile() {
	return useObservable(supportTechnicalService.profile);
}
export function useHospitals(): { hospitals: Hospital[] } {
	const user = useUser();
	const hospitals = useComputedObservable(() => supportTechnicalService.hospitals.get(), []);
	const fetchHospitals = useCallback(() => {
		try {
			if (user) {
				supportTechnicalService.fetchHospitals();
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);

	useEffect(() => {
		fetchHospitals();
	}, [fetchHospitals]);
	return { hospitals };
}

export function useCCAMDatabase(): { ccamList: CCAM[] } {
	const user = useUser();
	const ccamList = useComputedObservable(() => supportTechnicalService.ccams.get(), []);
	const fetchCCAMS = useCallback(async () => {
		try {
			if (user) {
				await supportTechnicalService.fetchCCAMS();
			}
		} catch (e) {
			console.log("Query fetch DocCustomForm aborted");
		}
	}, []);
	useEffect(() => {
		fetchCCAMS().then();
	}, []);
	return { ccamList };
}

export function usePractitionersForSettings() : {loading: boolean, practitioners: Practitioner[] | undefined;} {
	const user = useUser();
	const [loading, setLoading] = useState(true);
	const practitioners = useComputedObservable(() => supportTechnicalService.practitionersSettings.get(), [user]);
	const fetchPractitioners = useCallback(async () => {
		try {
			if (user) {
				setLoading(true);
				await supportTechnicalService.fetchListPractitioners();
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, []);
	useEffect(() => {
		fetchPractitioners();
	}, [fetchPractitioners]);
	return {
		loading,
		practitioners,
	};
}
export function usePractitionerDocCustom(
	pageOptions: PaginationOptions,
	search?: string,
): { loading: boolean; practitionerDocCustoms: Paginated<DocCustom> | undefined; refresh: () => Promise<void> } {
	const user = useUser();
	const practitionerDocCustoms = useComputedObservable(() => supportTechnicalService.docCustomsPagined.get().get(pageOptions.page), [
		user,
		pageOptions,
	]);
	const [loading, setLoading] = useState(true);
	const fetchDocCustom = useCallback(async () => {
		try {
			if (user) {
				setLoading(true);
				const implicitSortOption: SortOption = { by: "whenCreated", order: "ASC" };
				await supportTechnicalService.fetchPractitionerDocCustom(
					pageOptions,
					search,
				);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [user, pageOptions, search]);

	useEffect(() => {
		fetchDocCustom();
	}, [fetchDocCustom]);

	return {
		loading,
		practitionerDocCustoms,
		refresh: fetchDocCustom,
	};
}
export function useSecretaries(): { secretaries: Secretary[] } {
	const user = useUser();
	const secretaries = useComputedObservable(() => supportTechnicalService.secretaries.get(), []);
	const fetchSecretaries = useCallback(() => {
		try {
			if (user) {
				supportTechnicalService.fetchSecretaries();
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);

	useEffect(() => {
		fetchSecretaries();
	}, [fetchSecretaries]);
	return { secretaries };
}
export function useSpecialities(): { specialities: Speciality[] } {
	const user = useUser();
	const specialities = useComputedObservable(() => supportTechnicalService.specialities.get(), []);
	const fetchSpecialities = useCallback(() => {
		try {
			if (user) {
				supportTechnicalService.fetchSpecialities();
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);

	useEffect(() => {
		fetchSpecialities();
	}, [fetchSpecialities]);
	return { specialities };
}
export function useAgencies(): { agencies: Agency[] } {
	const user = useUser();
	const agencies = useComputedObservable(() => supportTechnicalService.agencies.get(), []);
	const fetchAgencies = useCallback(() => {
		try {
			if (user) {
				supportTechnicalService.fetchAgencies();
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);

	useEffect(() => {
		fetchAgencies();
	}, [fetchAgencies]);
	return { agencies };
}
export function useCmts(agenciesValue: string | null): { cmts: HealthCareProviderUser[] } {
	const user = useUser();
	const cmts = useComputedObservable(() => supportTechnicalService.cmts.get(), []);
	const fetchCmts = useCallback(() => {
		try {
			if (user && agenciesValue) {
				supportTechnicalService.fetchCmts(agenciesValue);
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);

	useEffect(() => {
		fetchCmts();
	}, [fetchCmts]);
	return { cmts };
}
export function useModules() : {loading: boolean, modules: Module[] | undefined;} {
	const user = useUser();
	const [loading, setLoading] = useState(true);
	const modules = useComputedObservable(() => supportTechnicalService.modules.get(), [user]);
	const fetchModules = useCallback(async () => {
		try {
			if (user) {
				setLoading(true);
				await supportTechnicalService.fetchModules();
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, []);
	useEffect(() => {
		fetchModules();
	}, [fetchModules]);
	return {
		loading,
		modules,
	};
}
export function useHealthcareProviders(): { healthcareProviders: HealthcareProvider[] } {
	const user = useUser();
	const healthcareProviders = useComputedObservable(() => supportTechnicalService.healthcareProviders.get(), []);
	const fetchHealthcareProviders = useCallback(() => {
		try {
			if (user) {
				supportTechnicalService.fetchHealthcareProviders();
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);

	useEffect(() => {
		fetchHealthcareProviders();
	}, [fetchHealthcareProviders]);
	return { healthcareProviders };
}
