import styled from "@emotion/styled";
import { Button } from "components/button";
import React, { useCallback, useEffect, useState } from "react";
import {colors, desktop, row, sizes, stack} from "style";
import { Patient } from "../../domain/patient";
import {css} from "@emotion/react";
import {Surgery} from "../../domain/surgery";
import { patientService, usePatientFormParad } from "../../services/patient-service";
import {useI18n} from "../../utils/i18n";
import { FormParad } from "../../domain/formParad";
import { SurgeryHPU } from "../../domain/hpu";
import { hpuService, useHpuFormParad } from "../../services/hpu-service";
import { practitionerService, usePractitionerFormParad } from "../../services/practitioner-service";
import { secretaryService, useSecretaryFormParad } from "../../services/secretary-service";

export const PatientFormParad: React.FC<{
	patient: Patient,
	surgery: Surgery | SurgeryHPU,
	onRead?:()=>void,
	audience: string,
}> = ({patient, surgery, onRead, audience}) => {
	const {format} = useI18n();
	const {patientFormParad} = usePatientFormParad(patient.id, surgery.id, audience);
	const {hputFormParad} = useHpuFormParad(patient.id, surgery.id, audience);
	const {practitionerFormParad} = usePractitionerFormParad(patient.id, surgery.id, audience);
	const {secretaryFormParad} = useSecretaryFormParad(patient.id, surgery.id, audience);
	const [editing, setEditing] = useState(true);
	const [weight, setWeight] = useState<string | undefined | null>(null);
	const [appetite, setAppetite] = useState<string | undefined | null>(null);
	const [meal, setMeal] = useState<string | undefined | null>(null);
	const [eat, setEat] = useState<string | undefined | null>(null);
	const [loading, setLoading] = useState(false);

	let formParad: FormParad | null = null
	if (audience == "patient") {
		formParad = patientFormParad
	} else if (audience == "hpu") {
		formParad = hputFormParad
	} else if (audience == "practitioner") {
		formParad = practitionerFormParad
	} else if (audience == "secretary") {
		formParad = secretaryFormParad
	}

	useEffect(() => {
		setEditing(!(!!patient && !!surgery && surgery.formParad ==1));
	},[patient, surgery])

	const editFormParad = useCallback(async (formParad: FormParad | null, audience: string) => {
		if (formParad && formParad.id) {

			if (audience == "patient") {
				// persist
				await patientService.deleteParad(formParad)
					.then(async () => await patientService.fetchSurgery(surgery.id))
			} else if (audience == "hpu") {
				// persist
				await hpuService.deleteParad(formParad)
					.then(async () => await hpuService.fetchSurgery(surgery.id))
			} else if (audience == "secretary") {
				// persist
				await secretaryService.deleteParad(formParad)
					.then(async () => await secretaryService.fetchSurgery(surgery.id, surgery.practitionerId as string))
			}

			setWeight(null)
			setAppetite(null)
			setMeal(null)
			setEat(null)
			setEditing(true)
		}

		if (onRead) {
			onRead?.()
		}

	},[formParad, editing])

	const save = async (patient: Patient, surgery: Surgery | SurgeryHPU, audience: string) => {
		// answers compilation & persistance
		let formAnswers: FormParad;
		if (patient && surgery) {
			formAnswers = {
				patientId: patient.id.toString(),
				surgeryId: surgery.id,
				weight: weight,
				appetite: appetite,
				meal: meal,
				eat: eat,
			};

			if (audience == "patient") {
				// persist
				if(surgery.patient)
					await patientService.createParad(formAnswers, surgery.patient, surgery)
					.then(async () => await patientService.fetchSurgery(surgery.id))
			} else if (audience == "hpu") {
				// persist
				await hpuService.createParad(formAnswers, patient, surgery)
					.then(async () => await hpuService.fetchSurgery(surgery.id))
			} else if (audience == "secretary") {
				// persist
				await secretaryService.createParad(formAnswers, patient, surgery)
					.then(async () => await secretaryService.fetchSurgery(surgery.id, surgery.practitionerId as string))
			}

			setLoading(false)

			if (onRead) {
				onRead?.()
			}

			if (audience == "patient") {
				patientService.fetchParad(patient.id, surgery.id).then()
			} else if (audience == "hpu") {
				hpuService.fetchParad(patient.id, surgery.id).then()
			} else if (audience == "practitioner") {
				practitionerService.fetchParad(patient.id, surgery.id).then()
			} else if (audience == "secretary") {
				secretaryService.fetchParad(patient.id, surgery.id).then()
			}

			window.scrollTo({ top: 0});
		}
	}

	const weightResponse = (formParad: FormParad) => {
		switch (formParad.weight) {
			case "A":
				return (
					<RadioButtonLabel>{format("parad.question1.reponse1")}</RadioButtonLabel>
				);
			case "B":
				return (
					<RadioButtonLabel>{format("parad.question1.reponse2")}</RadioButtonLabel>
				);
			case "C":
				return (
					<RadioButtonLabel>{format("parad.question1.reponse3")}</RadioButtonLabel>
				);
			case "D":
				return (
					<RadioButtonLabel>{format("parad.question1.reponse4")}</RadioButtonLabel>
				);
			default:
				return null;
		}
	}

	const appetiteResponse = (formParad: FormParad) => {
		switch (formParad.appetite) {
			case "A":
				return (
					<RadioButtonLabel>{format("parad.question2.reponse1")}</RadioButtonLabel>
				);
			case "B":
				return (
					<RadioButtonLabel>{format("parad.question2.reponse2")}</RadioButtonLabel>
				);
			case "C":
				return (
					<RadioButtonLabel>{format("parad.question2.reponse3")}</RadioButtonLabel>
				);
			case "D":
				return (
					<RadioButtonLabel>{format("parad.question2.reponse4")}</RadioButtonLabel>
				);
			default:
				return null;
		}
	}

	const mealResponse = (formParad: FormParad) => {
		switch (formParad.meal) {
			case "A":
				return (
					<RadioButtonLabel>{format("parad.question3.reponse1")}</RadioButtonLabel>
				);
			case "B":
				return (
					<RadioButtonLabel>{format("parad.question3.reponse2")}</RadioButtonLabel>
				);
			case "C":
				return (
					<RadioButtonLabel>{format("parad.question3.reponse3")}</RadioButtonLabel>
				);
			case "D":
				return (
					<RadioButtonLabel>{format("parad.question3.reponse4")}</RadioButtonLabel>
				);
			default:
				return null;
		}
	}

	const eatResponse = (formParad: FormParad) => {
		switch (formParad.eat) {
			case "A":
				return (
					<RadioButtonLabel>{format("parad.question4.reponse1")}</RadioButtonLabel>
				);
			case "B":
				return (
					<RadioButtonLabel>{format("parad.question4.reponse2")}</RadioButtonLabel>
				);
			case "C":
				return (
					<RadioButtonLabel>{format("parad.question4.reponse3")}</RadioButtonLabel>
				);
			case "D":
				return (
					<RadioButtonLabel>{format("parad.question4.reponse4")}</RadioButtonLabel>
				);
			default:
				return null;
		}
	}

	return (patient && surgery && surgery.formParad != null && formParad ? (
			<Section>
				<Header>
					<Subtitle>
						{format("parad.title.patient")}

							{surgery.formParad == 0 && (
								<Subtitle1>
									{format("parad.title.patient.encours")}
								</Subtitle1>
							)}

							{surgery.formParad == 1 && (
								<Subtitle2>
									{format("parad.patient.validated")}
								</Subtitle2>
							)}

					</Subtitle>

					<>
						{formParad.score != 0 ? (
							<>
								<ScoreMeterIcon
									src={`/icons/score-meter/score-meter-${formParad.score == 1 ? "10" : formParad.score == 2 ? "05" : "01"}.svg`}
									alt="pictogramme 'jauge de score'"
								/>
								<TextScore
									color={formParad.score == 1 ? colors.red : formParad.score == 2 ? colors.orange : colors.green}
								>
									{formParad.score == 1 ? format("parad.category.forte") : formParad.score == 2 ? format("parad.category.moyenne") : format("parad.category.faible")}
								</TextScore>
							</>
						) : "Score :" +
							<>
								<ScoreMeterIcon
									src="/icons/score-meter/score-meter-unavailable.svg"
									alt="pictogramme 'indisponible'"
								/>
								<TextScore>
									{format("parad.waiting.score")}
								</TextScore>
							</>
						}
					</>

				</Header>

				<FormStack>

					<Fields>
						<FieldSection>
							<FieldLabel>{format("parad.question1")}</FieldLabel>
							{weightResponse(formParad)}
						</FieldSection>
					</Fields>

					<Fields>
						<FieldSection>
							<FieldLabel>{format("parad.question2")}</FieldLabel>
							{appetiteResponse(formParad)}
						</FieldSection>
					</Fields>

					<Fields>
						<FieldSection>
							<FieldLabel>{format("parad.question3")}</FieldLabel>
							{mealResponse(formParad)}
						</FieldSection>
					</Fields>

					<Fields>
						<FieldSection>
							<FieldLabel>{format("parad.question4")}</FieldLabel>
							{eatResponse(formParad)}
						</FieldSection>
					</Fields>

				</FormStack>

				{audience != "practitioner" && (
					<Buttons>
						<Button onClick={() =>editFormParad(formParad, audience)}>{format("patient.scoring.section.editButton")}</Button>
					</Buttons>
				)}

				<Parad>
					<img src="/images/parad.png" style={{ height: "150px" }}
							 alt="Parad Logo"/>
				</Parad>
			</Section>
		) : (
			<Section>
				<Header>
					<Subtitle>
						{format("parad.title.patient")}
					</Subtitle>

					{surgery.formParad == 0 && (
						<Subtitle1>
							{format("parad.title.patient.encours")}
						</Subtitle1>
					)}

					{surgery.formParad == 1  && (
						<Subtitle2>
							{format("parad.patient.validated")}
						</Subtitle2>
					)}
				</Header>

				<SubSubtitle>
					{format("parad.patient")}
				</SubSubtitle>

				<form
					onSubmit={e => {
						setLoading(true)
						e.preventDefault();
						save(patient, surgery, audience);
					}}
				>
					<FormStack>

						<Fields>
							<FieldSection>
								<FieldLabel>{format("parad.question1")}</FieldLabel>
								<FieldInputSection>
									<RadioButton
										type="radio"
										name="weightA"
										value="A"
										checked={weight == "A"}
										onChange={() => {
											if (audience != "practitioner")
												setWeight("A")
										}}
										disabled={audience == "practitioner"}
									/>
									<RadioButtonLabel onClick={() =>{
										if (audience != "practitioner")
											setWeight("A")
									}}>{format("parad.question1.reponse1")}</RadioButtonLabel>
									<RadioButton
										type="radio"
										name="weightB"
										value="B"
										checked={weight == "B"}
										onChange={() => {
											if (audience != "practitioner")
												setWeight("B")
										}}
										disabled={audience == "practitioner"}
									/>
									<RadioButtonLabel onClick={() =>{
										if (audience != "practitioner")
											setWeight("B")
									}}>{format("parad.question1.reponse2")}</RadioButtonLabel>
									<RadioButton
										type="radio"
										name="weightC"
										value="C"
										checked={weight == "C"}
										onChange={() => {
											if (audience != "practitioner")
												setWeight("C")
										}}
										disabled={audience == "practitioner"}
									/>
									<RadioButtonLabel onClick={() =>{
										if (audience != "practitioner")
											setWeight("C")
									}}>{format("parad.question1.reponse3")}</RadioButtonLabel>
									<RadioButton
										type="radio"
										name="weightD"
										value="D"
										checked={weight == "D"}
										onChange={() => {
											if (audience != "practitioner")
												setWeight("D")
										}}
										disabled={audience == "practitioner"}
									/>
									<RadioButtonLabel onClick={() =>{
										if (audience != "practitioner")
											setWeight("D")
									}}>{format("parad.question1.reponse4")}</RadioButtonLabel>
								</FieldInputSection>
							</FieldSection>
						</Fields>

						<Fields>
							<FieldSection>
								<FieldLabel>{format("parad.question2")}</FieldLabel>
								<FieldInputSection>
									<RadioButton
										type="radio"
										name="appetiteA"
										value="A"
										checked={appetite == "A"}
										onChange={() => {
											if (audience != "practitioner")
												setAppetite("A")
										}}
										disabled={audience == "practitioner"}
									/>
									<RadioButtonLabel onClick={() =>{
										if (audience != "practitioner")
											setAppetite("A")
									}}>{format("parad.question2.reponse1")}</RadioButtonLabel>
									<RadioButton
										type="radio"
										name="appetiteB"
										value="B"
										checked={appetite == "B"}
										onChange={() => {
											if (audience != "practitioner")
												setAppetite("B")
										}}
										disabled={audience == "practitioner"}
									/>
									<RadioButtonLabel onClick={() =>{
										if (audience != "practitioner")
											setAppetite("B")
									}}>{format("parad.question2.reponse2")}</RadioButtonLabel>
									<RadioButton
										type="radio"
										name="appetiteC"
										value="C"
										checked={appetite == "C"}
										onChange={() => {
											if (audience != "practitioner")
												setAppetite("C")
										}}
										disabled={audience == "practitioner"}
									/>
									<RadioButtonLabel onClick={() =>{
										if (audience != "practitioner")
											setAppetite("C")
									}}>{format("parad.question2.reponse3")}</RadioButtonLabel>
									<RadioButton
										type="radio"
										name="appetiteD"
										value="D"
										checked={appetite == "D"}
										onChange={() => {
											if (audience != "practitioner")
												setAppetite("D")
										}}
										disabled={audience == "practitioner"}
									/>
									<RadioButtonLabel onClick={() =>{
										if (audience != "practitioner")
											setAppetite("D")
									}}>{format("parad.question2.reponse4")}</RadioButtonLabel>
								</FieldInputSection>
							</FieldSection>
						</Fields>

						<Fields>
							<FieldSection>
								<FieldLabel>{format("parad.question3")}</FieldLabel>
								<FieldInputSection>
									<RadioButton
										type="radio"
										name="mealA"
										value="A"
										checked={meal == "A"}
										onChange={() => {
											if (audience != "practitioner")
												setMeal("A")
										}}
										disabled={audience == "practitioner"}
									/>
									<RadioButtonLabel onClick={() =>{
										if (audience != "practitioner")
											setMeal("A")
									}}>{format("parad.question3.reponse1")}</RadioButtonLabel>
									<RadioButton
										type="radio"
										name="mealB"
										value="B"
										checked={meal == "B"}
										onChange={() => {
											if (audience != "practitioner")
												setMeal("B")
										}}
										disabled={audience == "practitioner"}
									/>
									<RadioButtonLabel onClick={() =>{
										if (audience != "practitioner")
											setMeal("B")
									}}>{format("parad.question3.reponse2")}</RadioButtonLabel>
									<RadioButton
										type="radio"
										name="mealC"
										value="C"
										checked={meal == "C"}
										onChange={() => {
											if (audience != "practitioner")
												setMeal("C")
										}}
										disabled={audience == "practitioner"}
									/>
									<RadioButtonLabel onClick={() =>{
										if (audience != "practitioner")
											setMeal("C")
									}}>{format("parad.question3.reponse3")}</RadioButtonLabel>
									<RadioButton
										type="radio"
										name="mealD"
										value="D"
										checked={meal == "D"}
										onChange={() => {
											if (audience != "practitioner")
												setMeal("D")
										}}
										disabled={audience == "practitioner"}
									/>
									<RadioButtonLabel onClick={() =>{
										if (audience != "practitioner")
											setMeal("D")
									}}>{format("parad.question3.reponse4")}</RadioButtonLabel>
								</FieldInputSection>
							</FieldSection>
						</Fields>

						<Fields>
							<FieldSection>
								<FieldLabel>{format("parad.question4")}</FieldLabel>
								<FieldInputSection>
									<RadioButton
										type="radio"
										name="eatA"
										value="A"
										checked={eat == "A"}
										onChange={() => {
											if (audience != "practitioner")
												setEat("A")
										}}
										disabled={audience == "practitioner"}
									/>
									<RadioButtonLabel onClick={() =>{
										if (audience != "practitioner")
											setEat("A")
									}}>{format("parad.question4.reponse1")}</RadioButtonLabel>
									<RadioButton
										type="radio"
										name="eatB"
										value="B"
										checked={eat == "B"}
										onChange={() => {
											if (audience != "practitioner")
												setEat("B")
										}}
										disabled={audience == "practitioner"}
									/>
									<RadioButtonLabel onClick={() =>{
										if (audience != "practitioner")
											setEat("B")
									}}>{format("parad.question4.reponse2")}</RadioButtonLabel>
									<RadioButton
										type="radio"
										name="ealC"
										value="C"
										checked={eat == "C"}
										onChange={() => {
											if (audience != "practitioner")
												setEat("C")
										}}
										disabled={audience == "practitioner"}
									/>
									<RadioButtonLabel onClick={() =>{
										if (audience != "practitioner")
											setEat("C")
									}}>{format("parad.question4.reponse3")}</RadioButtonLabel>
									<RadioButton
										type="radio"
										name="ealD"
										value="D"
										checked={eat == "D"}
										onChange={() => {
											if (audience != "practitioner")
												setEat("D")
										}}
										disabled={audience == "practitioner"}
									/>
									<RadioButtonLabel onClick={() =>{
										if (audience != "practitioner")
											setEat("D")
									}}>{format("parad.question4.reponse4")}</RadioButtonLabel>
								</FieldInputSection>
							</FieldSection>
						</Fields>

					</FormStack>

					{audience != "practitioner" && (
						<Buttons>
							<Button
								key="submit"
								type="submit"
								initial={{ opacity: 0 }}
								animate={{ opacity: 1, transition: { delay: 0.3 } }}
								loading={loading}
								disabled={!(weight != null && meal != null && eat != null && appetite != null)}
							>
								{format("scoringForm.editing.patient.validate")}
							</Button>
						</Buttons>
					)}

					<Parad>
						<img src="/images/parad.png" style={{ height: "150px" }}
								 alt="Parad Logo"/>
					</Parad>
				</form>
			</Section>
		)
	);
};

const Section = styled.div`
	${stack("L", "flex-start", "stretch")}
	@media ${desktop} {
		padding: ${sizes.L};
		border-radius: 12px;
		box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.05);
		background-color: ${colors.white};
		flex-grow: 1;
	}
`;

const Header = styled.div`
	${row("M", "flex-start", "baseline")}
`;

const Subtitle = styled.div`
	font-size: 20px;
	font-weight: bold;
	color: ${colors.black};
`;

const Subtitle1 = styled.div`
    color: ${colors.orange2};
    font-size: 15px;
    font-weight: normal;
`;

const Subtitle2 = styled.div`
    color: ${colors.green};
    font-size: 15px;
    font-weight: normal;
`;

const SubSubtitle = styled.div`
	color: ${colors.grey2};
	font-size: 15px;
	margin-top: -20px;
  font-weight: normal;
`;

const Buttons = styled.div`
	align-self: flex-end;
	${row("S", "flex-end", "center")};
`;

const Fields = styled.div<{ marginFree?: boolean }>`
	${({ marginFree }) =>
		marginFree &&
		css`
			margin: 0;
		`};
`;

const FieldLabel = styled.span`
	color: ${colors.black};
	width: 100%;
	overflow-wrap: break-word;
		font-weight: bold;
`;

const FormStack = styled.div`
	${stack("M")}
		margin-bottom: 40px;
`;

const FieldSection = styled.div`
	${stack(0)}
`;

const FieldInputSection = styled.div`
	padding-top: 5px;
	${row("S", "flex-start", "flex-start")};
`;

const RadioButton = styled.input`
	margin-top : 5px;
	margin-right: 5px;
	cursor: pointer;
	width: 14px;
	height: 14px;
	accent-color: ${colors.pink};
`;

const RadioButtonLabel = styled.div`
	font-size: 15px;
	cursor: pointer;
	color: ${colors.black};
`;

const ScoreMeterIcon = styled.img`
	width: 48px;
`;

const TextScore = styled.div<{ color?: string}>`
    ${row("S", "flex-start", "flex-start")};
    ${({ color }) =>
            color &&
			css`color: ${color};
		`};
`;

const Parad = styled.div`
	text-align: center;
`;
