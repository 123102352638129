import {
	Agency,
	HpuLight,
	Practitioner,
	PractitionerPatientHPU,
	PractitionerWithItsHpu,
	SurgeryHpu,
	SurgeryHPU,
	SurgeryWithItsHpu
} from "domain/hpu";
import { Map } from "immutable";
import { useComputedObservable, useObservable, WritableObservable } from "micro-observables";
import { useCallback, useEffect, useState } from "react";
import { authService, useUser } from "services/auth-service";
import { Paginated, PaginationOptions, SortOption } from "utils/pagination";
import { api, apiConfig, buildPaginationParams, buildSearchParams } from "./api";
import { CredentialsUpdate } from "../domain/utils";
import { UserProfile } from "../domain/user-profile";
import { Hospital, Hpu } from "../domain/hpu";
import { CCAM, Surgery } from "../domain/surgery";
import { XHRUploadOptions } from "@uppy/xhr-upload";
import { DocumentUpdateDTO } from "../domain/document";
import { format, parseISO } from "date-fns";
import { AxiosResponse } from "axios";
import { FormParad } from "../domain/formParad";
import { Patient } from "../domain/patient";
import { downloadBlob } from "./secretary-service";
import { Rectangle } from "../domain/support-technical";

export const PRACTITIONER_PAGE_SIZE = 25;
export const PATIENT_PAGE_SIZE = 25;
export const FIRST_PRACTITIONER_PAGE = { page: 0, size: PRACTITIONER_PAGE_SIZE };
export const FIRST_PATIENT_PAGE = { page: 0, size: PATIENT_PAGE_SIZE };

export type PractitionerCreationDTO = Pick<
	Practitioner,
	"firstName" | "lastName" | "email" | "rppsCode" | "hospital"
> & {
	assign: boolean;
	healthcareProviderId?: string;
	healthcareProviderAgencyId?: string;
};

export class HpuService {
	profile = new WritableObservable<Hpu | null>(null);
	practitioners = new WritableObservable<Map<number, Paginated<PractitionerPatientHPU>>>(Map());
	hospitals = new WritableObservable<Hospital[]>([]);
	hospitalsByPractitioners = new WritableObservable<Map<string, Hospital[]>>(Map());
	public hpu = new WritableObservable<Hpu | null>(null);
	hpus = new WritableObservable<HpuLight[]>([]);
	public hpuCredentials = new WritableObservable<{
		newPassword: string | undefined;
		newPasswordConfirmation: string | undefined;
		currentPassword: string | undefined;
	} | null>(null);
	practitionerByIds = new WritableObservable<Map<string, Practitioner>>(Map());
	practitionersWithHpus = new WritableObservable<Map<number, Paginated<PractitionerWithItsHpu>>>(Map());
	agencies = new WritableObservable<Agency[]>([]);
	surgeriesWithHpus = new WritableObservable<Map<number, Paginated<SurgeryWithItsHpu>>>(Map());
	surgeriesHpu = new WritableObservable<Map<number, Paginated<SurgeryHpu>>>(Map());
	surgeriesHpuAccepted = new WritableObservable<Map<number, Paginated<SurgeryHpu>>>(Map());
	surgeriesHpuArchived = new WritableObservable<Map<number, Paginated<SurgeryHpu>>>(Map());
	surgeriesHpuRefused = new WritableObservable<Map<number, Paginated<SurgeryHpu>>>(Map());
	countHpu = new WritableObservable<number>(0);
	countHpuAccepted = new WritableObservable<number>(0);
	countHpuArchived = new WritableObservable<number>(0);
	practitionersSettings = new WritableObservable<Practitioner[]>([]);
	countHpuRefused = new WritableObservable<number>(0);
	notPaginatedSurgeriesWithHpus = new WritableObservable<SurgeryWithItsHpu[]>([]);
	surgeries = new WritableObservable<Map<number, Paginated<SurgeryHPU>>>(Map());
	surgeryByIds = new WritableObservable<Map<string, SurgeryHPU>>(Map());
	ccams = new WritableObservable<CCAM[]>([]);
	documentTypes = new WritableObservable<[]>([]);
	formParad = new WritableObservable<FormParad | null>(null);

	async fetchPractitionersWithHpus(
		pageOptions: PaginationOptions,
		sortOptions?: SortOption[],
		search?: string
	): Promise<void> {
		const paramsObject = {
			...buildPaginationParams(pageOptions),
			search,
		};
		const params = buildSearchParams(paramsObject, sortOptions);
		const practitionersWithHpus = await api.get<Paginated<PractitionerWithItsHpu>>("/hpu/practitionersWithHpus", {
			params,
		});
		this.practitionersWithHpus.update(practitionersPages =>
			practitionersPages.set(practitionersWithHpus.data.page, practitionersWithHpus.data)
		);
	}
	async fetchAgencies(healthcareProviderId: string): Promise<void> {
		const agencies = await api.get<Agency[]>(`/hpu/healthcareProvider/agencies`);
		agencies.data.splice(0, 0, { id: "0", name: "Toutes les agences", groupId: ""});
		this.agencies.update(() => agencies.data);
	}

	async fetchHpusByAgency(healthcareProviderAgencyId: string): Promise<void> {
		const hpus = await api.get<HpuLight[]>(`/hpu/healthcareProviderAgency/${healthcareProviderAgencyId}/hpus`);
		hpus.data.splice(0, 0, { id: "0", lastName: "Tout les  technico-commerciaux", firstName: "" });
		this.hpus.update(() => hpus.data);
	}
	async fetchSurgery(surgeryId: string) {
		const surgery = await api.get<SurgeryHPU>(`/hpu/surgery/${surgeryId}`);
		this.surgeryByIds.update(surgeryMap => surgeryMap.set(surgery.data.id, surgery.data));
		return surgery;
	}

	async fetchSurgeriesWithHpus(
		pageOptions: PaginationOptions,
		sortOptions?: SortOption[],
		search?: string
	): Promise<void> {
		const paramsObject = {
			...buildPaginationParams(pageOptions),
			search,
		};
		const params = buildSearchParams(paramsObject, sortOptions);
		const surgeriesWithHpus = await api.get<Paginated<SurgeryWithItsHpu>>("/hpu/surgeriesWithHpus", { params });
		this.surgeriesWithHpus.update(surgeriesPages =>
			surgeriesPages.set(surgeriesWithHpus.data.page, surgeriesWithHpus.data)
		);
	}

	async fetchSurgeriesHpu(pageOptions: PaginationOptions, sortOptions?: SortOption[], search?: string): Promise<void> {
		const paramsObject = {
			...buildPaginationParams(pageOptions),
			search,
		};
		const params = buildSearchParams(paramsObject, sortOptions);
		const surgeriesHpu = await api.get<Paginated<SurgeryHpu>>("/hpu/surgeries/hpu", { params });
		this.surgeriesHpu.update(surgeriesPages => surgeriesPages.set(surgeriesHpu.data.page, surgeriesHpu.data));
	}

	async fetchSurgeriesHpuAccepted(
		pageOptions: PaginationOptions,
		sortOptions?: SortOption[],
		search?: string
	): Promise<void> {
		const paramsObject = {
			...buildPaginationParams(pageOptions),
			search,
		};
		const params = buildSearchParams(paramsObject, sortOptions);
		const surgeriesHpuAccepted = await api.get<Paginated<SurgeryHpu>>("/hpu/surgeries/hpu/accepted", { params });
		this.surgeriesHpuAccepted.update(surgeriesPages =>
			surgeriesPages.set(surgeriesHpuAccepted.data.page, surgeriesHpuAccepted.data)
		);
	}

	async fetchSurgeriesHpuArchived(
		pageOptions: PaginationOptions,
		sortOptions?: SortOption[],
		search?: string
	): Promise<void> {
		const paramsObject = {
			...buildPaginationParams(pageOptions),
			search,
		};
		const params = buildSearchParams(paramsObject, sortOptions);
		const surgeriesHpuArchived = await api.get<Paginated<SurgeryHpu>>("/hpu/surgeries/hpu/archived", { params });
		this.surgeriesHpuArchived.update(surgeriesPages =>
			surgeriesPages.set(surgeriesHpuArchived.data.page, surgeriesHpuArchived.data)
		);
	}

	async fetchSurgeriesHpuRefused(
		pageOptions: PaginationOptions,
		sortOptions?: SortOption[],
		search?: string
	): Promise<void> {
		const paramsObject = {
			...buildPaginationParams(pageOptions),
			search,
		};
		const params = buildSearchParams(paramsObject, sortOptions);
		const surgeriesHpuRefused = await api.get<Paginated<SurgeryHpu>>("/hpu/surgeries/hpu/refused", { params });
		this.surgeriesHpuRefused.update(surgeriesPages =>
			surgeriesPages.set(surgeriesHpuRefused.data.page, surgeriesHpuRefused.data)
		);
	}

	async fetchCountHpu(): Promise<void> {
		const count = await api.get<number>("/hpu/surgeries/hpu/count");
		this.countHpu.update(() => count.data);
	}

	async fetchCountHpuAccepted(): Promise<void> {
		const count = await api.get<number>("/hpu/surgeries/hpu/accepted/count");
		this.countHpuAccepted.update(() => count.data);
	}

	async fetchCountHpuArchived(): Promise<void> {
		const count = await api.get<number>("/hpu/surgeries/hpu/archived/count");
		this.countHpuArchived.update(() => count.data);
	}

	async fetchCountHpuRefused(): Promise<void> {
		const count = await api.get<number>("/hpu/surgeries/hpu/refused/count");
		this.countHpuRefused.update(() => count.data);
	}

	async fetchNotPaginatedSurgeriesWithHpus(
		pageOptions?: PaginationOptions,
		sortOptions?: SortOption[],
		search?: string
	): Promise<SurgeryWithItsHpu[]> {
		const paramsObject = {
			...buildPaginationParams(pageOptions),
			search,
		};
		const params = buildSearchParams(paramsObject, sortOptions);
		const notPaginatedSurgeriesWithHpus = await api.get<SurgeryWithItsHpu[]>("/hpu/notPaginatedSurgeriesWithHpus", {
			params,
		});
		this.notPaginatedSurgeriesWithHpus.update(() => notPaginatedSurgeriesWithHpus.data);
		return notPaginatedSurgeriesWithHpus.data;
	}

	async archiveSurgery(surgeryId: string): Promise<void> {
		await api.post<SurgeryHPU>(`/hpu/surgery/${surgeryId}`);
	}

	async acceptedSurgery(surgeryId: string): Promise<void> {
		await api.post<SurgeryHPU>(`/hpu/surgery/${surgeryId}/accepted`);
	}

	async refusedSurgery(surgeryId: string): Promise<void> {
		await api.post<SurgeryHPU>(`/hpu/surgery/${surgeryId}/refused`);
	}

	async fetchSurgeries(pageOptions: PaginationOptions, sortOptions?: SortOption[], search?: string): Promise<void> {
		const paramsObject = {
			...buildPaginationParams(pageOptions),
			search,
		};
		const params = buildSearchParams(paramsObject, sortOptions);
		const surgeriesWithHpus = await api.get<Paginated<SurgeryWithItsHpu>>("/hpu/surgeriesWithHpus", { params });
		this.surgeriesWithHpus.update(surgeriesPages =>
			surgeriesPages.set(surgeriesWithHpus.data.page, surgeriesWithHpus.data)
		);
	}

	async fetchProfile(): Promise<Hpu> {
		const profile = await api.get<Hpu>("hpu/me");
		this.profile.update(() => profile.data);
		return profile.data;
	}
	async fetchListPractitioners(): Promise<Practitioner[]>{
		const listPractitioners = await api.get<Practitioner[]>("/hpu/list-practitioners");
		this.practitionersSettings.update(() => listPractitioners.data);
		return listPractitioners.data
	}

	async fetchPractitioners(
		pageOptions: PaginationOptions,
		sortOptions?: SortOption[],
		search?: string,
		startDate?: string,
		endDate?: string,
		isAdmin?: string,
		healthcareProviderId?: string,
		isAdminAgency?: string,
		healthcareProviderAgencyId?: string,
		healthcareProviderUserId?: string,
		healthcareProviderAgencySelectedId?: string
	): Promise<void> {
		const paramsObject = {
			...buildPaginationParams(pageOptions),
			search,
			startDate,
			endDate,
			isAdmin,
			healthcareProviderId,
			isAdminAgency,
			healthcareProviderAgencyId,
			healthcareProviderUserId,
			healthcareProviderAgencySelectedId,
		};
		const params = buildSearchParams(paramsObject, sortOptions);
		const practitionerPage = await api.get<Paginated<PractitionerPatientHPU>>("/hpu/practitioner", {
			params,
		});
		this.practitioners.update(practitionerPages =>
			practitionerPages.set(practitionerPage.data.page, practitionerPage.data)
		);
	}
	async assignUserProfile(hpu: string, userProfile: UserProfile | null) {
		const result = await api.post("hpu/userprofile", userProfile);
		this.hpu.set(result.data);
		return result.data;
	}
	async updateDocument(surgeryId: string, documentId: string, update: DocumentUpdateDTO): Promise<void> {
		await api.post(`/hpu/surgery/${surgeryId}/document/${documentId}`, update);
	}
	async updatePassword(hpuCredential: CredentialsUpdate | null, patch: Partial<Omit<CredentialsUpdate, "assign">>) {
		const newCredentials = await api.post<CredentialsUpdate>("hpu/password-update", {
			newPassword: hpuCredential?.newPassword,
			newPasswordConfirmation: hpuCredential?.newPasswordConfirmation,
			currentPassword: hpuCredential?.currentPassword,
			...patch,
		});
		this.hpuCredentials.update(hpuMap => (hpuMap ? newCredentials.data : null));
	}
	async getAllPractitionerByEmail(email: string): Promise<Practitioner[]> {
		const practitionerPage = await api.get<Paginated<Practitioner>>("/hpu/practitioner/search", {
			params: { email, page: 0, size: 10 },
		});
		return practitionerPage.data.content;
	}
	async getAllPractitionerByIdentity(firstName: string, lastName: string): Promise<Practitioner[]> {
		const practitionerPage = await api.get<Paginated<Practitioner>>("/hpu/practitioner/search", {
			params: { firstName, lastName, page: 0, size: 10 },
		});
		return practitionerPage.data.content;
	}
	async createPractitioner(practitioner: PractitionerCreationDTO): Promise<Practitioner> {
		const newPractitioner = await api.post<Practitioner>("/hpu/practitioner", practitioner);
		this.practitionerByIds.update(practitionerMap =>
			practitionerMap.set(newPractitioner.data.id, newPractitioner.data)
		);
		return newPractitioner.data;
	}
	async assignPractitioner(id: string) {
		const practitioner = await api.post<Practitioner>("/hpu/practitioner/assign", { id: id });
		return practitioner.data;
	}
	async fetchHospitals(): Promise<Hospital[]> {
		const hospitals = await api.get<Hospital[]>("/hpu/hospitals");
		this.hospitals.set(hospitals.data);
		return hospitals.data;
	}
	async fetchHospitalsByName(hospitalName: string): Promise<Hospital[]> {
		const hospitals = await api.get<Hospital[]>(`/hpu/hospitals/search`, { params: { hospitalName: hospitalName } });
		this.hospitals.set(hospitals.data);
		return hospitals.data;
	}
	async getHospitalByPractitioner(practitionerId: string): Promise<Hospital[]> {
		const hospitals = await api.get<Hospital[]>(`/hpu/hospitals/practitioner/${practitionerId}`);
		this.hospitalsByPractitioners.update(practitionerMap => practitionerMap.set(practitionerId as string, hospitals.data));
		return hospitals.data;
	}
	async fetchPractitionerById(practitionerId: string): Promise<Practitioner> {
		const practitioner = await api.get<Practitioner>(`/hpu/practitioner/${practitionerId}`);
		this.practitionerByIds.update(practitionerMap => practitionerMap.set(practitionerId as string, practitioner.data));
		return practitioner.data;
	}

	async fetchPractitioner(practitionerId: string): Promise<Practitioner> {
		const practitioner = await api.get<Practitioner>(`/hpu/practitioner/${practitionerId}/surgery`);
		this.practitionerByIds.update(practitionerMap => practitionerMap.set(practitionerId as string, practitioner.data));
		return practitioner.data;
	}
	async fetchCCAMS(): Promise<CCAM[]> {
		const ccamData = await api.get<CCAM[]>(`/hpu/ccam`);
		this.ccams.update(() => ccamData.data);
		return ccamData.data;
	}
	async exportPractitionerCsv(practitioners: PractitionerPatientHPU[] | undefined) {
		const filename = `export-${format(Date.now(), "dd-MM-yyyy")}.csv`;
		if (practitioners) {
			const csvData = [];

			// Liste des colonnes à exclure des en-têtes CSV et des données JSON exportées

			// Correspondance entre les noms de colonnes d'origine et les noms souhaités
			const columnMappings: Record<
				keyof Omit<PractitionerPatientHPU,  "id" >,
				string
			> = {
				patientLastName: "Nom du patient",
				patientFirstName: "Prénom du patient",
				practitionerLastName: "Nom du praticien",
				speciality: "Spécialité",
				healthcareProviderName: "PSAD sélectionné",
				healthcareProviderAgencyName: "Agence",
				surgeryCreationDate: "Date de création de l'intervention",
				surgeryEndDate: "Date de prise en charge",
				surgeryDate: "Date de l'intervention"
			};

			// Filtrer les colonnes à inclure dans les en-têtes CSV
			const headers = Object.keys(columnMappings)
				.map(
					key =>
						columnMappings[
							key as keyof Omit<PractitionerPatientHPU,  "id">
						] || key
				);
			csvData.push(headers.join(","));

			// Filtrer les données JSON pour exclure les colonnes
			const filteredPractitioners = practitioners.map(practitioner => {
				const { ...rest } = practitioner;
				return rest;
			});

			// Ajouter les données filtrées au CSV
			for (const data of filteredPractitioners) {
				const values = headers.map(header => {
					const originalKey =
						Object.keys(columnMappings).find(
							key =>
								columnMappings[
									key as keyof Omit<PractitionerPatientHPU, "id" >
								] === header
						) || header;
					let value = data[originalKey as keyof Omit<PractitionerPatientHPU, "id">];

					// Vérifier si la valeur est null et la remplacer par "NAN"
					if (value == null) {
						value = "-";
					} else if (
						originalKey === "surgeryDate" ||
						originalKey === "surgeryEndDate" ||
						originalKey === "surgeryCreationDate"
					) {
						const parsedDate = parseISO(value.toString()); // Utiliser parseISO pour parser la date
						value = format(parsedDate, "dd-MM-yyyy"); // Utiliser la fonction format pour formater les dates
					}

					// Si la valeur contient des virgules, entourez-la de guillemets
					return typeof value === "string" && value.includes(",") ? `"${value}"` : value;
				});
				csvData.push(values.join(","));
			}

			// Créer le contenu CSV
			const csvContent = csvData.join("\n");
			const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

			// Créer un objet URL pour le blob
			const url = URL.createObjectURL(blob);

			// Créer un lien d'ancre pour le téléchargement du fichier CSV
			const link = document.createElement("a");
			link.setAttribute("href", url);
			link.setAttribute("download", filename);

			// Cliquez sur le lien pour déclencher le téléchargement
			link.click();

			// Libérer l'URL de l'objet blob après le téléchargement
			URL.revokeObjectURL(url);
		}
	}

	async getDocumentUrl(surgeryId: string, documentId: string | undefined) {
		if (surgeryId && documentId) {
			try {
				const response = await api.get(`/hpu/surgery/${surgeryId}/document/${documentId}`, {
					responseType: 'blob', // Important: Indique que la réponse doit être traitée comme un Blob
				});
				downloadBlob(response)

			} catch (error) {
				console.error("Erreur lors du téléchargement du document:", error);
			}
		}
	}

	getDocumentUploadEndpoint(surgeryId: string, practitionerId: string): XHRUploadOptions {
		const user = authService.user.get();
		return {
			endpoint: `${apiConfig.baseURL}/hpu/practitioner/${practitionerId}/surgery/${surgeryId}/document`,
			formData: true,
			method: "post",
			fieldName: "file",
			headers: {
				Authorization: `Bearer ${user?.tokens.accessToken}`,
				...apiConfig.headers,
			},
			timeout: 0,
		} as const;
	}
	async deleteDocument(surgeryId: string, documentId: string): Promise<void> {
		await api.delete(`/hpu/surgery/${surgeryId}/document/${documentId}`);
	}
	async postDocCustom(formData: FormData):Promise<AxiosResponse>{
		return await api.post(`/hpu/addDocCustom`, formData)
	}

	async updatePsadNewOrderSurgery(surgeryId: string): Promise<void> {
		await api.post(`/hpu/psadNewOrder/surgery/${surgeryId}/`);
	}
		async getPatientPractitionerHpuData(
			pageOptions: PaginationOptions,
			sortOptions?: SortOption[],
			search?: string,
			startDate?: string,
			endDate?: string,
			isAdmin?: string,
			healthcareProviderId?: string,
			isAdminAgency?: string,
			healthcareProviderAgencyId?: string,
			healthcareProviderUserId?: string,
			healthcareProviderAgencySelectedId?: string) {
		if (startDate == "") {
			startDate = "1970-01-01";
		}
		const paramsObject = {
			...buildPaginationParams(pageOptions),
			search,
			startDate,
			endDate,
			isAdmin,
			healthcareProviderId,
			isAdminAgency,
			healthcareProviderAgencyId,
			healthcareProviderUserId,
			healthcareProviderAgencySelectedId,
		};
		const params = buildSearchParams(paramsObject, sortOptions);
		const patientPractitionerHpuList = await api.get<PractitionerPatientHPU[]>("/hpu/practitionerList", {
			params,
		});
		return patientPractitionerHpuList.data
	}

	async createParad(answers: FormParad, patient: Patient, surgery: Surgery | SurgeryHPU) {
		await api.post<FormParad>(`/hpu/${patient.id}/surgery/${surgery.id}/parad/new`, answers);
	}

	async deleteParad(formParad: FormParad) {
		if (formParad.id != undefined) {
			await api.delete(`/hpu/parad/${formParad.id}/delete`);
		}
	}

	async fetchParad(patientId: string, surgeryId: string) {
		try {
			const result = await api.get<FormParad>(`/hpu/patient/${patientId}/surgery/${surgeryId}/parad`);
			this.formParad.set(result.data);
			return result.data;
		} catch (e) {
			console.log(e)
			console.log("Query fetchPatient aborted");
		}
		return null;
	}
	async generateDocument(rectangles: Rectangle[]) {
		return await api.post("/hpu/docCustom", rectangles)
	}
}

export const hpuService = new HpuService();

export function useHpuProfile() {
	return useObservable(hpuService.profile);
}

export function useHpuHospitals() {
	return useObservable(hpuService.hospitals);
}
export function usePractitionersForSettings() : {loading: boolean, practitioners: Practitioner[] | undefined;} {
	const user = useUser();
	const [loading, setLoading] = useState(true);
	const practitioners = useComputedObservable(() => hpuService.practitionersSettings.get(), [user]);
	const fetchPractitioners = useCallback(async () => {
		try {
			if (user) {
				setLoading(true);
				await hpuService.fetchListPractitioners();
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, []);
	useEffect(() => {
		fetchPractitioners().then();
	}, [fetchPractitioners]);
	return {
		loading,
		practitioners,
	};
}

export function usePractitioners(
	pageOptions: PaginationOptions,
	sortOption: SortOption | null,
	search?: string,
	startDate?: string,
	endDate?: string,
	isAdmin?: string,
	healthcareProviderId?: string,
	isAdminAgency?: string,
	healthcareProviderAgencyId?: string,
	healthcareProviderUserId?: string,
	healthcareProviderAgencySelectedId?: string
): { loading: boolean; practitioners: Paginated<PractitionerPatientHPU> | undefined; refresh: () => Promise<void> } {
	if (healthcareProviderAgencySelectedId == "0") {
		healthcareProviderAgencySelectedId = undefined;
	}

	const user = useUser();
	const practitioners = useComputedObservable(() => hpuService.practitioners.get().get(pageOptions.page), [
		user,
		pageOptions,
		sortOption,
	]);
	const [loading, setLoading] = useState(true);
	const fetchPractitioners = useCallback(async () => {
		try {
			if (user) {
				setLoading(true);
				if (startDate == "") {
					startDate = "1970-01-01";
				}
				await hpuService.fetchPractitioners(
					pageOptions,
					sortOption ? [sortOption] : undefined,
					search,
					startDate,
					endDate,
					isAdmin,
					healthcareProviderId,
					isAdminAgency,
					healthcareProviderAgencyId,
					healthcareProviderUserId,
					healthcareProviderAgencySelectedId
				);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [
		pageOptions,
		sortOption,
		user,
		search,
		startDate,
		endDate,
		isAdmin,
		healthcareProviderId,
		isAdminAgency,
		healthcareProviderAgencyId,
		healthcareProviderUserId,
		healthcareProviderAgencySelectedId,
	]);

	useEffect(() => {
		fetchPractitioners();
	}, [fetchPractitioners]);

	return {
		loading,
		practitioners,
		refresh: fetchPractitioners,
	};
}
export function useAgencies(
	healthcareProviderId?: string
): {
	agencies: Agency[];
} {
	const user = useUser();
	const agencies = useComputedObservable(() => hpuService.agencies.get(), [user]);
	const fetchAgencies = useCallback(async () => {
		try {
			if (user && healthcareProviderId) {
				await hpuService.fetchAgencies(healthcareProviderId);
			}
		} catch (e) {
			console.log(e);
		}
	}, [user, healthcareProviderId]);

	useEffect(() => {
		fetchAgencies();
	}, [fetchAgencies]);

	return {
		agencies,
	};
}

export function useHpus(
	healthcareProviderAgencyId?: string
): {
	hpus: HpuLight[];
} {
	const user = useUser();
	const hpus = useComputedObservable(() => hpuService.hpus.get(), [user]);
	const fetchHpus = useCallback(async () => {
		try {
			if (user && healthcareProviderAgencyId) {
				await hpuService.fetchHpusByAgency(healthcareProviderAgencyId);
			}
		} catch (e) {
			console.log(e);
		}
	}, [user, healthcareProviderAgencyId]);

	useEffect(() => {
		fetchHpus();
	}, [fetchHpus]);

	return {
		hpus,
	};
}

export function usePractitionersWithHpus(
	pageOptions: PaginationOptions,
	sortOption: SortOption | null,
	search?: string
): {
	loading2: boolean;
	practitionersWithHpus: Paginated<PractitionerWithItsHpu> | undefined;
	refresh: () => Promise<void>;
} {
	const user = useUser();
	const practitionersWithHpus = useComputedObservable(
		() => hpuService.practitionersWithHpus.get().get(pageOptions.page),
		[user, pageOptions, sortOption]
	);
	const [loading2, setLoading2] = useState(true);
	const fetchPractitionersWithHpus = useCallback(async () => {
		try {
			if (user) {
				setLoading2(true);
				await hpuService.fetchPractitionersWithHpus(pageOptions, sortOption ? [sortOption] : undefined, search);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading2(false);
		}
	}, [pageOptions, sortOption, user, search]);

	useEffect(() => {
		fetchPractitionersWithHpus();
	}, [fetchPractitionersWithHpus]);

	return {
		loading2,
		practitionersWithHpus,
		refresh: fetchPractitionersWithHpus,
	};
}

export function useHospitals(): { hospitals: Hospital[] } {
	const user = useUser();
	const hospitals = useComputedObservable(() => hpuService.hospitals.get(), []);
	const fetchHospitals = useCallback(() => {
		try {
			if (user) {
				hpuService.fetchHospitals();
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);

	useEffect(() => {
		fetchHospitals();
	}, [fetchHospitals]);
	return { hospitals };
}

export function useSurgeriesWithHpus(
	pageOptions: PaginationOptions,
	sortOption: SortOption | null,
	search?: string
): {
	loadingSurgeriesWithHpus: boolean;
	surgeriesWithHpus: Paginated<SurgeryWithItsHpu> | undefined;
	notPaginatedSurgeriesWithHpus: SurgeryWithItsHpu[] | undefined;
	refresh: () => Promise<void>;
} {
	const user = useUser();
	const surgeriesWithHpus = useComputedObservable(() => hpuService.surgeriesWithHpus.get().get(pageOptions.page), [
		user,
		pageOptions,
		sortOption,
	]);
	const notPaginatedSurgeriesWithHpus = useComputedObservable(() => hpuService.notPaginatedSurgeriesWithHpus.get(), [
		user,
	]);
	const [loadingSurgeriesWithHpus, setLoadingSurgeriesWithHpus] = useState(true);
	const fetchSurgeriesWithHpus = useCallback(async () => {
		try {
			if (user) {
				setLoadingSurgeriesWithHpus(true);
				await hpuService.fetchSurgeriesWithHpus(pageOptions, sortOption ? [sortOption] : undefined, search);
				await hpuService.fetchNotPaginatedSurgeriesWithHpus(pageOptions, sortOption ? [sortOption] : undefined, search);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoadingSurgeriesWithHpus(false);
		}
	}, [pageOptions, sortOption, user, search]);

	useEffect(() => {
		fetchSurgeriesWithHpus();
	}, [fetchSurgeriesWithHpus]);

	return {
		loadingSurgeriesWithHpus,
		surgeriesWithHpus,
		notPaginatedSurgeriesWithHpus,
		refresh: fetchSurgeriesWithHpus,
	};
}

export function useSurgeriesHpu(
	pageOptions: PaginationOptions,
	sortOption: SortOption | null,
	search?: string
): {
	surgeriesHpu: Paginated<SurgeryHpu> | undefined;
} {
	const user = useUser();
	const surgeriesHpu = useComputedObservable(() => hpuService.surgeriesHpu.get().get(pageOptions.page), [
		user,
		pageOptions,
		sortOption,
	]);
	const fetchSurgeriesHpu = useCallback(async () => {
		try {
			if (user && (search == "" || search == null || (search && search?.length >= 3))) {
				await hpuService.fetchSurgeriesHpu(pageOptions, sortOption ? [sortOption] : undefined, search);
			}
		} catch (e) {
			console.log(e);
		}
	}, [pageOptions, sortOption, user, search]);

	useEffect(() => {
		fetchSurgeriesHpu();
	}, [fetchSurgeriesHpu]);

	return {
		surgeriesHpu,
	};
}

export function useSurgeriesHpuAccepted(
	pageOptions: PaginationOptions,
	sortOption: SortOption | null,
	search?: string
): {
	surgeriesHpuAccepted: Paginated<SurgeryHpu> | undefined;
} {
	const user = useUser();
	const surgeriesHpuAccepted = useComputedObservable(
		() => hpuService.surgeriesHpuAccepted.get().get(pageOptions.page),
		[user, pageOptions, sortOption]
	);
	const fetchSurgeriesHpuAccepted = useCallback(async () => {
		try {
			if (user && (search == "" || search == null || (search && search?.length >= 3))) {
				await hpuService.fetchSurgeriesHpuAccepted(pageOptions, sortOption ? [sortOption] : undefined, search);
			}
		} catch (e) {
			console.log(e);
		}
	}, [pageOptions, sortOption, user, search]);

	useEffect(() => {
		fetchSurgeriesHpuAccepted().then();
	}, [fetchSurgeriesHpuAccepted]);

	return {
		surgeriesHpuAccepted,
	};
}

export function useSurgeriesHpuArchived(
	pageOptions: PaginationOptions,
	sortOption: SortOption | null,
	search?: string
): {
	surgeriesHpuArchived: Paginated<SurgeryHpu> | undefined;
} {
	const user = useUser();
	const surgeriesHpuArchived = useComputedObservable(
		() => hpuService.surgeriesHpuArchived.get().get(pageOptions.page),
		[user, pageOptions, sortOption]
	);
	const fetchSurgeriesHpuArchived = useCallback(async () => {
		try {
			if (user && (search == "" || search == null || (search && search?.length >= 3))) {
				await hpuService.fetchSurgeriesHpuArchived(pageOptions, sortOption ? [sortOption] : undefined, search);
			}
		} catch (e) {
			console.log(e);
		}
	}, [pageOptions, sortOption, user, search]);

	useEffect(() => {
		fetchSurgeriesHpuArchived().then();
	}, [fetchSurgeriesHpuArchived]);

	return {
		surgeriesHpuArchived,
	};
}

export function useSurgeriesHpuRefused(
	pageOptions: PaginationOptions,
	sortOption: SortOption | null,
	search?: string
): {
	surgeriesHpuRefused: Paginated<SurgeryHpu> | undefined;
} {
	const user = useUser();
	const surgeriesHpuRefused = useComputedObservable(() => hpuService.surgeriesHpuRefused.get().get(pageOptions.page), [
		user,
		pageOptions,
		sortOption,
	]);
	const fetchSurgeriesHpuRefused = useCallback(async () => {
		try {
			if (user && (search == "" || search == null || (search && search?.length >= 3))) {
				await hpuService.fetchSurgeriesHpuRefused(pageOptions, sortOption ? [sortOption] : undefined, search);
			}
		} catch (e) {
			console.log(e);
		}
	}, [pageOptions, sortOption, user, search]);

	useEffect(() => {
		fetchSurgeriesHpuRefused().then();
	}, [fetchSurgeriesHpuRefused]);

	return {
		surgeriesHpuRefused,
	};
}

export function useCountHpu(): {
	countToDo: number;
} {
	const user = useUser();
	const countToDo = useComputedObservable(() => hpuService.countHpu.get(), [user]);
	const fetchCountHpu = useCallback(async () => {
		try {
			if (user) {
				await hpuService.fetchCountHpu();
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);

	useEffect(() => {
		fetchCountHpu().then();
	}, [fetchCountHpu]);

	return {
		countToDo,
	};
}

export function useCountHpuAccepted(): {
	countAccepted: number;
} {
	const user = useUser();
	const countAccepted = useComputedObservable(() => hpuService.countHpuAccepted.get(), [user]);
	const fetchCountHpuAccepted = useCallback(async () => {
		try {
			if (user) {
				await hpuService.fetchCountHpuAccepted();
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);

	useEffect(() => {
		fetchCountHpuAccepted().then();
	}, [fetchCountHpuAccepted]);

	return {
		countAccepted,
	};
}

export function useCountHpuArchived(): {
	countArchived: number;
} {
	const user = useUser();
	const countArchived = useComputedObservable(() => hpuService.countHpuArchived.get(), [user]);
	const fetchCountHpuArchived = useCallback(async () => {
		try {
			if (user) {
				await hpuService.fetchCountHpuArchived();
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);

	useEffect(() => {
		fetchCountHpuArchived().then();
	}, [fetchCountHpuArchived]);

	return {
		countArchived,
	};
}

export function useCountHpuRefused(): {
	countRefused: number;
} {
	const user = useUser();
	const countRefused = useComputedObservable(() => hpuService.countHpuRefused.get(), [user]);
	const fetchCountHpuRefused = useCallback(async () => {
		try {
			if (user) {
				await hpuService.fetchCountHpuRefused();
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);

	useEffect(() => {
		fetchCountHpuRefused().then();
	}, [fetchCountHpuRefused]);

	return {
		countRefused,
	};
}

export function useSurgeries(
	pageOptions: PaginationOptions,
	sortOption: SortOption | null,
	search?: string
): { loadingSurgeries: boolean; surgeries: Paginated<SurgeryHPU> | undefined; refresh: () => Promise<void> } {
	const user = useUser();
	const surgeries = useComputedObservable(() => hpuService.surgeries.get().get(pageOptions.page), [
		user,
		pageOptions,
		sortOption,
	]);
	const [loadingSurgeries, setLoadingSurgeries] = useState(true);
	const fetchSurgeries = useCallback(async () => {
		try {
			if (user) {
				setLoadingSurgeries(true);
				await hpuService.fetchSurgeries(pageOptions, sortOption ? [sortOption] : undefined, search);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoadingSurgeries(false);
		}
	}, [pageOptions, sortOption, user, search]);

	useEffect(() => {
		fetchSurgeries();
	}, [fetchSurgeries]);

	return {
		loadingSurgeries,
		surgeries,
		refresh: fetchSurgeries,
	};
}

export function useSurgery(surgeryId?: string): { loading: boolean; surgery: SurgeryHPU | undefined } {
	const user = useUser();
	const surgery = useComputedObservable(() => (surgeryId ? hpuService.surgeryByIds.get().get(surgeryId) : undefined), [
		user,
		surgeryId,
	]);

	const [loading, setLoading] = useState(true);
	const fetchSurgeries = useCallback(async () => {
		try {
			if (user && surgeryId) {
				setLoading(true);
				await hpuService.fetchSurgery(surgeryId);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [user, surgeryId]);

	useEffect(() => {
		fetchSurgeries();
	}, [fetchSurgeries]);

	return {
		loading,
		surgery,
	};
}

export function usePractitionerSurgery(
	practitionerId?: string
): { loading: boolean; practitioner: Practitioner | undefined } {
	const user = useUser();
	const practitioner = useComputedObservable(
		() => (practitionerId ? hpuService.practitionerByIds.get().get(practitionerId as string) : undefined),
		[user, practitionerId]
	);

	const [loading, setLoading] = useState(true);
	const fetchSurgeries = useCallback(async () => {
		try {
			if (user && practitionerId as string) {
				setLoading(true);
				await hpuService.fetchPractitioner(practitionerId as string);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [user, practitionerId]);

	useEffect(() => {
		fetchSurgeries().then();
	}, [user, practitionerId]);

	return {
		loading,
		practitioner,
	};
}

export function useCCAMDatabase(): { ccamList: CCAM[] } {
	const user = useUser();
	const ccamList = useComputedObservable(() => hpuService.ccams.get(), []);
	const fetchCCAMS = useCallback(async () => {
		try {
			if (user) {
				await hpuService.fetchCCAMS();
			}
		} catch (e) {
			console.log("Query fetch DocCustomForm aborted");
		}
	}, []);
	useEffect(() => {
		fetchCCAMS().then();
	}, []);
	return { ccamList };
}


export function useDocumentTypes(): { ccamList: CCAM[] } {
	const user = useUser();
	const ccamList = useComputedObservable(() => hpuService.ccams.get(), []);
	const fetchCCAMS = useCallback(async () => {
		try {
			if (user) {
				await hpuService.fetchCCAMS();
			}
		} catch (e) {
			console.log("Query fetch DocCustomForm aborted");
		}
	}, []);
	useEffect(() => {
		fetchCCAMS().then();
	}, []);
	return { ccamList };
}

export function useHpuFormParad(patientId: string, surgeryId: string, audience: string): { hputFormParad: FormParad | null } {
	const user = useUser();
	const hputFormParad = useComputedObservable(() => hpuService.formParad.get(), [])
	const fetchFormParad = useCallback(async () => {
		try {
			if (user && audience == "hpu") {
				await hpuService.fetchParad(patientId, surgeryId);
			}
		} catch (e) {
			console.log(e);
		}
	}, [user, patientId, surgeryId, audience]);
	useEffect(() => {
		fetchFormParad().then();
	}, [fetchFormParad]);
	return {hputFormParad};
}
