import { DocumentErrorIcon } from "@assets/icons/document-error.svg";
import styled from "@emotion/styled";
import React from "react";
import { colors, row } from "style";

export const DocumentsMessage = React.forwardRef<HTMLDivElement, { color: string; label: string; subLabel?: string }>(
	({ color, label, subLabel }, ref) => {
		return (
			<DocumentsMessageContainer ref={ref}>
				<ColorDocumentErrorIcon color={color} />
				<div>
					<DocumentsMessageLabel style={{ color }}>{label}</DocumentsMessageLabel>
					{subLabel && <DocumentsMessageSubLabel>« {subLabel} »</DocumentsMessageSubLabel>}
				</div>
			</DocumentsMessageContainer>
		);
	}
);
DocumentsMessage.displayName = "DocumentsMessage";

const DocumentsMessageContainer = styled.div`
	${row("S")};
`;
const DocumentsMessageLabel = styled.div`
	padding-top: 2px;
	font-size: 16px;
`;
const DocumentsMessageSubLabel = styled.div`
	font-size: 13px;
	font-style: italic;
	color: ${colors.grey2};
`;
const ColorDocumentErrorIcon = styled(DocumentErrorIcon)<{ color: string }>`
	path {
		fill: ${props => props.color};
	}
`;
