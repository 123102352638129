import "babel-polyfill";
import store from "../src/services/store"
import React from "react";
import { App } from "./app";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from "react-router-dom";

const container = document.getElementById('react-root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
	<HelmetProvider>
		<Provider store={store}>
			<BrowserRouter> {/* Encapsulation de l'application dans un BrowserRouter */}
				<App />
			</BrowserRouter>
		</Provider>
	</HelmetProvider>
);
