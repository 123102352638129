import { DocumentErrorIcon } from "@assets/icons/document-error.svg";
import { DocumentProcessingIcon } from "@assets/icons/document-processing.svg";
import { DocumentSuccessIcon } from "@assets/icons/document-success.svg";
import { InfoIcon } from "@assets/icons/info.svg";
import ContextMenuIcon from "@assets/icons/more.svg";
import styled from "@emotion/styled";
import { Document, SurgeryDocumentAdmission, SurgeryDocumentValidationStatus } from "domain/document";
import React, { CSSProperties, useRef } from "react";
import { createModal, ModalCardContainer, ModalCloseIcon } from "services/modal-service";
import { colors, row, sizes, Spacer, stack } from "style";
import { DocumentMenuContextAction, DocumentMenuContextMenu } from "./document-menu-context-menu";
import { PatientService } from "../../services/patient-service";
import { HospitalService } from "../../services/hospital-service";
import { HpuService } from "../../services/hpu-service";

interface DocumentCardProps {
	document: Document;
	onDelete?: () => void;
	onCancel?: (documentId: string) => void;
	onClick?: () => void;
	wording: { processing: string; valid: string; rejected: string };
	showContextMenu?: boolean;
	audience?: string;
	surgeryId: string;
}

export const DocumentCard = React.forwardRef<HTMLDivElement, DocumentCardProps>(
	({ document, onDelete, onCancel, wording, onClick, showContextMenu = true, audience, surgeryId }, ref) => {
		const contextMenuIcon = useRef<HTMLDivElement | null>(null);
		let desc = document.documentType.label;
		if (desc == "Dépassement honoraires à editer") desc = "Dépassement honoraire";

		return (
			<Container status={document.validationStatus} ref={ref} onClick={onClick}>
				{showContextMenu && (
					<ContextMenuButton
						ref={contextMenuIcon}
						onClick={async () => {
							const action = await createModal<DocumentMenuContextAction | null>(
								({ style, onClose }) => (
									<DocumentMenuContextMenu
										style={style}
										onClose={onClose}
										showDelete={
											(!document.isSigned &&
												document.validationStatus !== SurgeryDocumentValidationStatus.Validated &&
												audience == "Patient") ||
											(document.isSigned && document.status == SurgeryDocumentAdmission.Completed) ||
											(document && document.validationStatus == SurgeryDocumentValidationStatus.Pending)
										}
										showCancel={
											!document.isSigned &&
											document.validationStatus == SurgeryDocumentValidationStatus.Validated &&
											audience == "hospital"
										}
										audience={audience}
										surgeryId={surgeryId}
										documentId={document?.id}
										showHss={document.lienHss != null}
										showHss2={document.lienHss2 != null}
									/>
								),
								{
									valueOnBackdropClick: null,
									anchor: contextMenuIcon.current ?? undefined,
								}
							);
							if (action === "downloadHss") {
								window.open(document.lienHss, "_blank");
							}
							if (action === "downloadHss2") {
								window.open(document.lienHss2, "_blank");
							}
							if (action === "download") {
								if (audience == "patient") {
									const patientService = new PatientService();
									await patientService.getDocumentUrl(surgeryId, document);
								}
								if(audience == "hospital") {
									const hospitalService = new HospitalService();
									hospitalService.getDocumentUrl(surgeryId, document.dlConnectorId).then()
								}
								if(audience == "hpu") {
									const hpuService = new HpuService();
									hpuService.getDocumentUrl(surgeryId, document.dlConnectorId).then()
								}
							}
							if (action === "delete") {
								onDelete?.();
							}
							if (action === "cancel") {
								onCancel?.(document.id);
							}
						}}
					>
						<ContextMenuIcon />
					</ContextMenuButton>
				)}
				<Status>
					{document.validationStatus === SurgeryDocumentValidationStatus.Rejected ? (
						<ColoredDocumentErrorIcon />
					) : document.validationStatus === SurgeryDocumentValidationStatus.Validated ? (
						<ColoredDocumentSuccessIcon />
					) : (
						<ColoredDocumentProcessingIcon />
					)}
					<Label>
						{document.validationStatus === SurgeryDocumentValidationStatus.Rejected
							? wording.rejected
							: document.validationStatus === SurgeryDocumentValidationStatus.Validated
							? wording.valid
							: wording.processing}
					</Label>
				</Status>
				<Spacer />
				<DocumentData>
					{!!document.description && (
						<TooltipDescription
							onClick={e => {
								e.stopPropagation();
								createModal(props => <TooltipModal {...props} description={document.description} />, {
									valueOnBackdropClick: null,
								});
							}}
						>
							<ColoredInfoIcon />
						</TooltipDescription>
					)}
					<Name>{desc}</Name>
				</DocumentData>
			</Container>
		);
	}
);
DocumentCard.displayName = "DocumentCard";

const TooltipModal: React.FC<{
	style?: CSSProperties;
	onClose: (result: null) => void;
	description?: string;
}> = ({ onClose, description }) => {
	return (
		<ModalCardContainer onClick={e => e.stopPropagation()}>
			<ModalCloseIcon onClick={() => onClose(null)} />
			<Description>{description ?? ""}</Description>
		</ModalCardContainer>
	);
};

const Container = styled.div<{ status?: SurgeryDocumentValidationStatus }>`
	${stack("M")};
	width: 150px;
	height: 200px;
	border-radius: 12px;
	padding: ${sizes.M};
	font-size: 16px;
	color: ${colors.white};
	position: relative;
	cursor: ${props => (props.onClick ? "pointer" : "auto")};
	background-color: ${props =>
		props.status === SurgeryDocumentValidationStatus.Rejected
			? colors.red
			: props.status === SurgeryDocumentValidationStatus.Validated
			? colors.green
			: colors.blue2};
`;

const Status = styled.div`
	${stack("S")};
`;

const DocumentData = styled.div`
	${stack(0)};
`;

const Name = styled.div`
	font-size: 15px;
	font-weight: bold;
	color: ${colors.white};
`;

const TooltipDescription = styled.div`
	z-index: 200;
	cursor: pointer;
	width: 24px;
	height: 24px;
	${row(0, "center", "center")}
	* > {
		display: block;
	}
`;

const Description = styled.div`
	padding: ${sizes.XL};
	padding-top: 60px;
	font-size: 15px;
	color: ${colors.grey2};
`;

const Label = styled.div`
	font-size: 13px;
	color: ${colors.white};
`;

const ColoredDocumentProcessingIcon = styled(DocumentProcessingIcon)`
	path {
		fill: ${colors.white};
	}
`;
const ColoredDocumentSuccessIcon = styled(DocumentSuccessIcon)`
	path {
		fill: ${colors.white};
	}
`;
const ColoredDocumentErrorIcon = styled(DocumentErrorIcon)`
	path {
		fill: ${colors.white};
	}
`;
const ColoredInfoIcon = styled(InfoIcon)`
	width: 18px;
	height: 18px;
	path {
		fill: ${colors.white};
	}
`;

export const ContextMenuButton = styled.div`
	width: 16px;
	height: 16px;
	object-fit: contain;

	position: absolute;
	padding: 10px;
	top: 0;
	right: 0;
	box-sizing: content-box;
	cursor: pointer;
	z-index: 10;

	circle {
		fill: ${colors.white};
	}
`;
