import styled from "@emotion/styled";
import {Button} from "components/button";
import {FormField} from "components/form-field";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {ModalCardContainer, ModalCloseIcon} from "services/modal-service";
import {colors, row, sizes, stack} from "style";
import { useI18n } from "../../../utils/i18n";
import { EmailInput, TextInput } from "../../inputs/text-input";
import { PractitionerContact } from "../../../domain/practitioner";
import {
	practitionerService,
	usePractitionerContact,
	usePractitionerProfile,
} from "../../../services/practitioner-service";
import { splitAddress } from "../../../services/secretary-service";

interface CreateSurgeryModalProps {
	practitionerContactId?: string;
	practitionerId: string;
	onClose: () => void;
}
export const CreatePractitionerContactModal: React.FC<CreateSurgeryModalProps> = ({ practitionerId, onClose, practitionerContactId }) => {
	const practitioner = usePractitionerProfile()
	const practitionerContact = usePractitionerContact();
	const [error, setError] = useState<{ code: number, message: string } | null>(null);
	const { format } = useI18n();
	const [name, setName] = useState<string>("");
	const [phoneNumber1, setPhoneNumber1] = useState<string>("");
	const [phoneNumber2, setPhoneNumber2] = useState<string | undefined>("");
	const [email, setEmail] = useState("");
	const [city, setCity] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [street, setStreet] = useState("");
	const [additional , setAdditional ] = useState("");
	const [loading, setLoading] = useState(false);

	// init le form avec les valeurs à modifier quand on a un id
	useEffect(() => {
		let currentPractitionerContact: PractitionerContact| undefined
		const fetchContactDataParameter = async (practitionerContactId: string | undefined) => {
			if (practitionerContactId !== undefined) {
				currentPractitionerContact = practitionerContact.find(it => it.id == practitionerContactId);
				if (currentPractitionerContact) {
						setName(currentPractitionerContact.name);
						setPhoneNumber1(currentPractitionerContact.phoneNumber1);
						setPhoneNumber2(currentPractitionerContact.phoneNumber2);
						setEmail(currentPractitionerContact.email);
						const address  = splitAddress(currentPractitionerContact.address)
					if (address.length == 4) {
						setStreet(address[0].trim());
						setAdditional(address[1].trim());
						setPostalCode(address[2].trim());
						setCity(address[3].trim());
					} else {
						setStreet(address[0].trim());
						setPostalCode(address[1].trim());
						setCity(address[2].trim());
					}
				}
			}
		};

		fetchContactDataParameter(practitionerContactId).then(); // Appel de la fonction asynchrone
	}, [practitionerContactId, practitionerContact, practitionerId]);

	const handleNameInputChange = (e: { target: { value: string } }) => {
		const value = e.target.value.toUpperCase();
		setName(value);
	};
	const handlePhoneNumber1InputChange = (e: { target: { value: string } }) => {
		const value = e.target.value.toUpperCase();
		setPhoneNumber1(value);
	};
	const handlePhoneNumber2InputChange = (e: { target: { value: string } }) => {
		const value = e.target.value.toUpperCase();
		setPhoneNumber2(value);
	};
	const handleStreetInputChange = (e: { target: { value: string } }) => {
		const value = e.target.value.toUpperCase();
		setStreet(value);
	};
	const handleAdditionalInputChange = (e: { target: { value: string } }) => {
		const value = e.target.value.toUpperCase();
		setAdditional(value);
	};
	const handleCityInputChange = (e: { target: { value: string } }) => {
		const value = e.target.value.toUpperCase();
		setCity(value);
	};
	const handlePostalCodeInputChange = (e: { target: { value: string } }) => {
		const value = e.target.value.toUpperCase();
		setPostalCode(value);
	};

	const save = useCallback(async (e: { preventDefault: () => void; }, practitionerId: any) => {
		setLoading(true)
		e.preventDefault();
		try {
			console.log(additional.length > 0)
			console.log(phoneNumber2)
			setLoading(true)
			if (practitionerId && name && phoneNumber1 && email && street && city && postalCode) {
				const practitionerContact = {
					practitionerId: practitionerId as string,
					name: name,
					phoneNumber1: phoneNumber1,
					phoneNumber2: phoneNumber2,
					email: email,
					address: additional.length > 0 ? `${street}, ${additional}, ${postalCode}, ${city}` : `${street}, ${postalCode}, ${city}`,
					enabled: 1
				}
				if (practitionerContactId == undefined) {
					await practitionerService.addPractitionerContact(practitionerContact);
				} else {
					await practitionerService.updatePractitionerContact(practitionerContactId, practitionerContact);
				}
				practitionerService.fetchPractitionerContact().then();
			}
		} catch (e: any) {
			alert(e.message)
			throw e.message
		} finally {
			setLoading(false)
			onClose()
		}
	}, [name, phoneNumber1, phoneNumber2, email, city, street, postalCode, additional, onClose])

	return (
		<Card>
			<ModalCloseIcon onClick={() => onClose()} />
			<Form onSubmit={(event) => save(event, practitionerId as string).then()}>

				<Title>{format("addContactParameter")}</Title>
				<Description>{format("createSurgeryModal.subtitle")}</Description>
				<Fields>
					<FormField label={format("role.practitioner")}>
						{practitioner && (
							practitioner.firstName + " " + practitioner.lastName
						)}
					</FormField>
				</Fields>
				<Fields>
					<FormField label={format("secretary.modal.contact.name")}
										 style={{ gridArea: "name" }}>
						<TextInput
							value={name}
							placeholder={format("secretary.modal.contact.name")}
							onChange={e => {
								handleNameInputChange(e);
							}}
							required
						/>
					</FormField>
				</Fields>
				<Fields>
					<FormField label={format("secretary.contact.phoneNumber1")}
										 style={{ gridArea: "phoneNumber1" }}>
						<TextInput
							type="tel"
							value={phoneNumber1}
							placeholder={format("secretary.modal.contact.phoneNumber1Placeholder")}
							pattern="^[0-9]{10}$"
							onChange={e => {
								handlePhoneNumber1InputChange(e);
							}}
							required
						/>
					</FormField>
				</Fields>
				<Fields>
					<FormField label={format("secretary.modal.contact.phoneNumber2Placeholder")}
										 style={{ gridArea: "phoneNumber2" }}>
						<TextInput
							value={phoneNumber2}
							placeholder={format("secretary.modal.contact.phoneNumber2Placeholder")}
							pattern="^[0-9]{10}$"
							onChange={e => {
								handlePhoneNumber2InputChange(e);
							}}
						/>
					</FormField>
				</Fields>
				<Fields>
					<FormField label={format("secretary.modal.contact.email")} style={{ gridArea: "email" }}>
						<EmailInputField
							value={email}
							onChange={e => {
								setEmail(e.target.value);
							}}
							placeholder={format("secretary.modal.contact.email")}
							required
						/>
					</FormField>
				</Fields>
				<Fields>
					<FormField label={format("secretary.modal.contact.address")}
					 style={{ gridArea: "street" }}>
						<TextInput
							value={street}
							placeholder={format("secretary.modal.contact.address")}
							onChange={e => {
								handleStreetInputChange(e);
							}}
							required
						/>
					</FormField>
				</Fields>
				<Fields>
					<FormField label={format("secretary.modal.contact.additional")}
										 style={{ gridArea: "additional" }}>
						<TextInput
							value={additional}
							placeholder={format("secretary.modal.contact.additional")}
							onChange={e => {
								handleAdditionalInputChange(e);
							}}
						/>
					</FormField>
				</Fields>
				<Fields>
					<FormField label={format("secretary.modal.contact.postalCode")}
										 style={{ gridArea: "postalCode" }}>
						<TextInput
							value={postalCode}
							placeholder={format("secretary.modal.contact.postalCode")}
							onChange={e => {
								handlePostalCodeInputChange(e);
							}}
							required
						/>
					</FormField>
				</Fields>
				<Fields>
					<FormField label={format("secretary.modal.contact.city")}
							style={{ gridArea: "city" }}>
						<TextInput
							value={city}
							placeholder={format("secretary.modal.contact.city")}
							onChange={e => {
								handleCityInputChange(e);
							}}
							required
						/>
					</FormField>
				</Fields>

				{error && (
					<ErrorMessage>
						{error.code === 400
							? error.message
							: format("login.error.unknownError.admin")
						}
					</ErrorMessage>
				)}
				<ButtonContainer>
					{
						practitionerContactId === undefined ? (
							<Button loading={loading} type="submit">
								{format("addContact")}
							</Button>
						) : (
							<Button loading={loading} type="submit">
								{format("updateContact")}
							</Button>
						)
					}

					<Button secondary type="button" onClick={() => {
						onClose()
					}}>
						{format("createSurgeryModal.cancelButton")}
					</Button>
				</ButtonContainer>
			</Form>
		</Card>
	);
};

const Card = styled(ModalCardContainer)`
    padding: 63px 80px;
    width: 650px; /* Ajoutez cette ligne pour définir une largeur fixe */
    max-width: 100%; /* Cela garantit que la carte ne dépasse pas 100% de la largeur de l'écran */
    ${row(0, "center", "center")};
    @media (max-width: 920px) {
        padding: ${sizes.L};
        width: auto; /* Ajustement pour les petits écrans */
    }
`;

const Title = styled.h1`
	font-size: 27px;
	color: ${colors.black};
`;


const RowPatient = styled.div`
	${row("L")}
`;

const Description = styled.div`
	font-size: 15px;
	color: ${colors.black};
`;

const Form = styled.form`
	${stack("S")}
	max-height: 100vh;
	overflow: auto;
`;


const Fields = styled.div`
	${stack("M")}
`;

const FieldsCustom = styled.div`
	${stack("M")}
`;

const ButtonContainer = styled.div`
	${row("S")}
	align-self:flex-end;
`;

const ErrorMessage = styled.div`
	color: ${colors.red};
	font-size: 12px;
`;
const EmailInputField = styled(EmailInput)`
	::placeholder {
		font-size: 13px;
		color: #000000;
	}`