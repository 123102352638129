import { DocumentType } from "domain/document";
import { UserType } from "domain/user-type";
import { useComputedObservable, WritableObservable } from "micro-observables";
import { useCallback, useEffect, useRef } from "react";
import { useUser } from "services/auth-service";
import { api } from "./api";
import { useParams } from "react-router";

let loading = false;
export class DocumentTypeService {
	documentTypes = new WritableObservable<DocumentType[]>([]);
	documentTypesHealthCareProviders = new WritableObservable<DocumentType[]>([]);

	async fetchDocumentTypes(userType: UserType): Promise<void> {
		const newDocumentTypes = await api.get<DocumentType[]>(`${userType}/document-types`);
		this.documentTypes.set(newDocumentTypes.data);
	}

	async fetchDocumentTypesPatient(userType: UserType, surgeryId: string): Promise<void> {
		const newDocumentTypes = await api.get<DocumentType[]>(`${userType}/surgery/${surgeryId}/document-types`);
		this.documentTypes.set(newDocumentTypes.data);
	}

	async fetchDocumentTypesPractitioner(userType: UserType): Promise<void> {
			const newDocumentTypes = await api.get<DocumentType[]>(`${userType}/document-types-practitioner`);
		this.documentTypes.set(newDocumentTypes.data);
	}
	async fetchDocumentTypesHealthCareProvider(userType: UserType): Promise<void> {
		const newDocumentTypes = await api.get<DocumentType[]>(`${userType}/document-types-healthcare-provider`);
		this.documentTypesHealthCareProviders.set(newDocumentTypes.data);
	}
}

export const documentTypeService = new DocumentTypeService();

export function useDocumentTypes(): { loading: boolean; documentTypes: DocumentType[] } {
	const user = useUser();
	const documentTypes = useComputedObservable(() => documentTypeService.documentTypes.get(), []);
	const userType= useRef<UserType | null>(null)
	const { surgeryId } = useParams<{ surgeryId: string }>();
	const fetchDocumentTypes = useCallback(async () => {
		try {
			if (user) {
				switch (user.type) {
					case "practitioner": userType.current = UserType.Practitioner; break;
					case "secretary": userType.current = UserType.Secretary; break;
					case "patient": userType.current = UserType.Patient; break;
					case "hospital": userType.current = UserType.Hospital; break;
					case "hpu": userType.current = UserType.Hpu; break;
					case "support-technical": userType.current = UserType.Support; break;
				}
				if(userType.current && userType.current != "patient"){
					await documentTypeService.fetchDocumentTypes(userType.current);
				} else if (userType.current == "patient" && surgeryId) {
					await documentTypeService.fetchDocumentTypesPatient(userType.current, surgeryId);
				}
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);

	useEffect(() => {
		if (!loading) {
			loading = true;
			fetchDocumentTypes().then(() => (loading = false));
		}
	}, [fetchDocumentTypes]);

	return {
		loading,
		documentTypes,
	};
}

export function useDocumentTypesPractitioner(): { loading: boolean; documentTypes: DocumentType[] } {
	const user = useUser();
	const documentTypes = useComputedObservable(() => documentTypeService.documentTypes.get(), []);
	const userType= useRef<UserType | null>(null)
	const fetchDocumentTypes = useCallback(async () => {
		try {
			if (user) {
				switch (user.type) {
					case "hpu": userType.current = UserType.Hpu; break;
					case "support-technical": userType.current = UserType.Support; break;
					case "secretary": userType.current = UserType.Secretary; break;
					case "practitioner": userType.current = UserType.Practitioner; break;
				}
				if(userType.current){
					await documentTypeService.fetchDocumentTypesPractitioner(userType.current);
				}
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);

	useEffect(() => {
		if (!loading) {
			loading = true;
			fetchDocumentTypes().then(() => (loading = false));
		}
	}, [fetchDocumentTypes]);

	return {
		loading,
		documentTypes,
	};
}

export function useDocumentTypesHealthCareProvider(): { loading: boolean; documentTypesHPU: DocumentType[] } {
	const user = useUser();
	const documentTypesHPU = useComputedObservable(() => documentTypeService.documentTypesHealthCareProviders.get(), []);
	const userType= useRef<UserType | null>(null)
	const fetchDocumentTypes = useCallback(async () => {
		try {
			if (user) {
				switch (user.type) {
					case "practitioner": userType.current = UserType.Practitioner; break;
					case "secretary": userType.current = UserType.Secretary; break;
					case "patient": userType.current = UserType.Patient; break;
					case "hospital": userType.current = UserType.Hospital; break;
					case "hpu": userType.current = UserType.Hpu; break;
					case "support-technical": userType.current = UserType.Support; break;
				}
				if(userType.current){
					await documentTypeService.fetchDocumentTypesHealthCareProvider(userType.current);
				}
			}
		} catch (e) {
			console.log(e);
		}
	}, [user]);

	useEffect(() => {
		if (!loading) {
			loading = true;
			fetchDocumentTypes().then(() => (loading = false));
		}
	}, [fetchDocumentTypes]);

	return {
		loading,
		documentTypesHPU,
	};
}

export function useDocumentType(id: string | undefined | null): DocumentType | undefined {
	const { documentTypes } = useDocumentTypes();

	return documentTypes.find(doc => id && doc.id === id);
}
