import styled from "@emotion/styled";
import {Button} from "components/button";
import React, { useCallback, useState } from "react";
import { colors, row, Spacer, stack } from "style";
import { useI18n } from "../../../utils/i18n";
import {
	practitionerService, useCCAMDatabasePractitioner, FIRST_SURGERY_PAGE
} from "../../../services/practitioner-service";
import { useDocumentTypesPractitioner } from "../../../services/document-type-service";
import { Section } from "../../section";
import { AlertComponent } from "../../notifications/alertComponent";
import { CheckboxMultiSelect } from "../../inputs/multi-select-input";
import { CheckboxInput } from "../../inputs/checkbox-input";
import { SelectInput } from "../../inputs/select-input";
import { motion } from "framer-motion";
import { ModalCardContainer, ModalCloseIcon } from "../../../services/modal-service";

interface CreateSurgeryModalProps {
	practitionerContactId?: string;
	practitionerId: string;
	task?: string;
	onClose: () => void;
}
export const CreatePractitionerDocumentModal: React.FC<CreateSurgeryModalProps> = ({ practitionerId, task = "false", onClose, practitionerContactId }) => {
	const { format } = useI18n();
	const { ccamList } = useCCAMDatabasePractitioner();
	const {documentTypes} = useDocumentTypesPractitioner();

	const [ccamValue, setCcamValue] = useState<string[]>([]);
	const [hospitalsValue, setHospitalsValue] = useState<string[]>([]);
	const [documentTypeValue, setDocumentTypeValue] = useState<string>("");
	const [message, setMessage] = useState<string>("");
	const [state, setState] = useState<string>("success");
	const [showNotification, setShowNotification] = useState<boolean>(false);
	const [selectedFilesNames, setSelectedFilesNames] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(false)
	const [disabled, setDisabled] = useState<boolean>(false)
	const [hasDocumentTypeError, setHasDocumentTypeError] = useState<boolean>(false)
	const [fileError, setFileError] = useState<boolean>(false)
	const [files, setFiles] = useState<File[]>([]);
	const [healthcareProviderId, setHealthcareProviderId] = useState<string | null>(null);
	const [range, setRange] = useState<string>('');
	const [name, setName] = useState<string>('document');
	const [toPrint, setToPrint] = useState<boolean>(false);
	const [target, setTarget] = useState<string>('docs_practitioner');
	const [forMinor, setForMinor] = useState<boolean>(false);
	const [forLegual, setForLegual] = useState<boolean>(false);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);
		setDisabled(true);
		const formData = new FormData();
		for (const filesKey in files) {
			if (Object.hasOwnProperty.call(files, filesKey)) {
				formData.append('files', files[filesKey]);
			}
		}
		formData.append('hospitalId', hospitalsValue.toString());
		formData.append('surgeryDocumentTypeId', documentTypeValue);
		formData.append('healthcareProviderId', healthcareProviderId?.toString() ?? "");
		formData.append('surgeryCodeFrId', ccamValue.toString()??"");
		formData.append('practitionerId', practitionerId);
		formData.append('range', range);
		formData.append('name', name);
		formData.append('enabled', String(true));
		formData.append('toCustom', String(false));
		formData.append('toPrint', String(toPrint));
		formData.append('target', target);
		formData.append('forMutualCmuAld', String(false));
		formData.append('forMinor', String(forMinor));
		formData.append('forAnesGeneral', String(true));
		formData.append('forLegual', String(forLegual));
		formData.append('pdfModifiable',  String(false));

		try {
			if(files.length === 0){
				setMessage(`Erreur : Sélectionnez un seul fichier.`);
				setFileError(true)
				throw Error("Fichier non sélectionnée.");
			}
			if(files.length > 1){
				setMessage(`Erreur : 1 seul fichier à la fois`);
				setFileError(true)
				throw Error("1 seul fichier à choisir");
			}
			if(documentTypeValue == "" || documentTypeValue == null){
				setMessage(`Erreur : Type de document vide.`);
				setHasDocumentTypeError(true);
				throw Error("Type de document vide.");
			}
			if(ccamValue.length == 0){
				setMessage(`Erreur : Dossier de destination non séléctionné, sélectionner un praticien ou un établissement de santé ou un code CCAM.`);
				throw Error("Dossier de destination non séléctionné, sélectionner un praticien ou un établissement de santé ou un code CCAM.");
			}
			const response = await practitionerService.postDocCustom(formData, task);
			if(response.status == 200){
				setMessage("Ajout de document terminé")
				setState("success")
				setShowNotification(true)
				await practitionerService.fetchPractitionerDocCustomLight()
				await practitionerService.fetchPractitionerDocCustom(FIRST_SURGERY_PAGE)
			}
		} catch (error) {
			setMessage(`Erreur : ${(error as Error).message }`)
			setState("danger")
			setShowNotification(true)
		}
		finally {
			setLoading(false);
			setDisabled(false);
		}
	};

	const handleFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const newFiles: File[] = [];
			Array.from(e.target.files).forEach((file, index) => {
				newFiles[index] = file;
			});

			setFileError(false);
			setShowNotification(false);
			setFiles(newFiles); // Remplace directement files par les nouveaux fichiers sélectionnés
			setSelectedFilesNames(newFiles.map(file => file.name)); // Mettre à jour la liste des noms de fichiers sélectionnés
		}
	}, []);

	return (
		<ModalContainer>
			<ModalCloseIcon onClick={() => onClose()} />
			<Sections>
				{showNotification && <AlertComponent
					openAlertBox={true}
					state={state}
					message={message}
					onClose={() => setShowNotification(false)}
				/>}
				<Section layout>
					<Title>{format("add.documents.button")}</Title>
					<Form onSubmit={async (e) => {
						setShowNotification(false);
						await handleSubmit(e);
					}}>
						<Stack>
							<FileInputLabel htmlFor="fileInput" hasError={fileError}>
								{selectedFilesNames.length > 0 ? (
									<ul>
										{selectedFilesNames.map((fileName, index) => (
											<li key={index}>{fileName}</li>
										))}
									</ul>
								) : (
									"Sélectionner des fichiers ici"
								)}
							</FileInputLabel>
							<FileInput id="fileInput" type="file" multiple onChange={handleFileChange} accept=".pdf, .html"
												 style={{ display: "none" }} />
							<Row>
								<StyledSelect
									hasError={hasDocumentTypeError}
									innerId="documentType"
									placeholder={"Sélectionner un type de document"}
									value={documentTypeValue}
									options={documentTypes?.sort((documentA, documentB) => (documentA.label > documentB.label ? 1 : -1))
										.filter((document) => !document.isForAdmission)
										.map(document => document.id) ?? []}
									onChange={(e) => {
										setDocumentTypeValue(e);
										setShowNotification(false);
										setHasDocumentTypeError(false);
									}}
									itemRenderer={id => `${documentTypes?.find(document => document.id === id)?.label}` ?? ""}
								/>
								<CheckboxMultiSelect<string>
									innerId="CCAM"
									values={ccamValue ?? []}
									onChange={(e) => setCcamValue(e)}
									defaultValue="Sélectionner un code CCAM"
									options={ccamList
										?.sort((codeA, codeB) => codeA.codeCCAM.localeCompare(codeB.codeCCAM))
										.map(code => code.id) ?? []}
									itemRenderer={id => `${ccamList?.find(code => code.id === id)?.codeCCAM}` ?? ""}
									itemNumber={0}
								/>
							</Row>
							<Spacer />
							<RowCheckBox>
								<label>
									A imprimer:
								</label>
								<CheckboxInput value={toPrint} onChange={(e) => setToPrint(e)} />
								<label>
									Pour les mineurs:
								</label>
								<CheckboxInput value={forMinor} onChange={(e) => setForMinor(e)} />
								<label>
									Pour les tuteurs / curateurs:
								</label>
								<CheckboxInput value={forLegual} onChange={(e) => setForLegual(e)} />
							</RowCheckBox>
						</Stack>
						<Button style={{ alignSelf: "flex-end" }} key="submit" type="submit" loading={loading} disabled={disabled}>
							Envoyer
						</Button>
					</Form>
				</Section>
			</Sections>
		</ModalContainer>
	);
}

const ModalContainer = styled(ModalCardContainer)`
    padding: 30px;
    ${row(0, "center", "center")};
`;

const Stack = styled.div`
 	${stack("XL", "flex-start", "flex-start")}
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
`;

const Form = styled.form`
	${stack("XL")};
`;
const RowCheckBox = styled.div`
	${row("S", "center", "center")}
`;
export const CheckboxMultiSelectContainer = styled.div`
  display: inline-block;
  width: 100%;
  height: auto;
  position: relative;
`;

export const CheckboxMultiSelectTrigger = styled.button`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 31px;
  padding: 6px 27px 6px 6px;
  font-size: 14px;
  text-align: left;
  border-radius: 3px;
  border: 1px solid #d3d4d4;
  outline: 0;
  cursor: pointer;
  position: relative;
  background: #fff;

  &:active {
    color: black;
  }

  &.react-select-enabled {
  }

  &.react-select-target-attached-top {
    border-radius: 0 0 3px 3px;
  }

  &.react-select-target-attached-bottom {
    border-radius: 3px 3px 0 0;
  }

  // truncate children with ellipsis
  * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const CheckboxMultiSelectOption = styled.div`
  user-select: none;

  & + & {
    margin-left: 6px;
  }

  .react-select-trigger--multiple & {
    padding: 0 3px;
    border-radius: 2px;
    background: #E6F9FF;
  }
`;

export const CheckboxMultiSelectArrow = styled.span`
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
`;

export const CheckboxMultiSelectMenu = styled.div`
  max-height: 180px;
  padding: 3px 0;
  border: 1px solid #e1e1e1;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 2px 4px 0 rgba(218, 221, 222, 0.35);
  background-color: white;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: none;
  -webkit-tap-highlight-color: transparent;

  .react-select-enabled & {
    pointer-events: auto;
  }

  .react-select-element-attached-top  & {
    margin-top: -1px;
    border-top: 0;
  }

  .react-select-element-attached-bottom  & {
    margin-top: 1px;
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
    box-shadow: 0 -2px 4px 0 rgba(218, 221, 222, 0.35);
  }
`;

export const CheckboxMultiSelectOptionLabel = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CheckboxMultiSelectOptgroup = styled.div`
  display: block;
  padding: 3px 0;
  cursor: default;
  border-top: 1px solid #F1F3F5;

  &:first-child {
    border: 0;
  }
`;

const Sections = styled.div`
	${stack("M", "center", "stretch")};
`;

export const CheckboxMultiSelectOptgroupTitle = styled.div`
  display: block;
  padding: 8px 12px 6px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #909294;
  user-select: none;
`;

const FileInput = styled.input`
    background-color: #f7fff7;
    width: 350px;
    padding: 20px 20px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 10px 20px, rgba(0, 0, 0, 0.28) 0px 6px 6px;
`;
const StyledSelect = styled(SelectInput<string>)<{ hasError: boolean }>`
	border: ${({ hasError }) => (hasError ? `2px solid ${colors.red}` : "none")};
	${({ hasError }) => hasError && `border-radius: 8px;`}
`;
const FileInputLabel = styled.label<{ hasError: boolean }>`
    background-color: #f7fff7;
    width: 350px;
    padding: 20px 20px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 10px 20px, rgba(0, 0, 0, 0.28) 0px 6px 6px;
    border: ${({ hasError }) => (hasError ? `2px solid ${colors.red}` : `2px dashed ${colors.green}`)};
    cursor: pointer;
`;

const Title = styled(motion.div)`
	font-size: 20px;
	font-weight: bold;
	color: ${colors.black};
`;
