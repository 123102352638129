import styled from "@emotion/styled";
import React, { useCallback, useEffect, useState } from "react";
import { ModalCardContainer, ModalCloseIcon } from "services/modal-service";
import { AnimateSharedLayout, motion } from "framer-motion";
import { Document, RejectedDocument, SurgeryDocumentSource, SurgeryDocumentValidationStatus } from "domain/document";
import { colors, desktop, mobile, row, Spacer, stack } from "style";
import { useI18n } from "utils/i18n";
import { fullName } from "../../../domain/utils";
import { useObservable } from "micro-observables";
import { patientService } from "../../../services/patient-service";
import { downloadFile } from "../../../utils/web";
import ArrowDownloadIcon from "@assets/icons/download-arrow.svg";
import { Button } from "../../button";
import { TextArea } from "../../inputs/text-input";
import { CheckboxInput } from "../../inputs/checkbox-input";
import { secretaryService, useSurgery } from "../../../services/secretary-service";
import { api } from "../../../services/api";

const useBlobUrl = (surgeryId : string, doc: Document, practitionerId: string) => {
	const [blobUrl, setBlobUrl] = useState<string | null>(null);

	const getUrl = useCallback(async () => {
			const blob = await secretaryService.getDocumentUrl(surgeryId, practitionerId as string, doc.dlConnectorId, true);
			if (blob instanceof Blob) {
				const url = URL.createObjectURL(blob);
				setBlobUrl(url);
				return () => URL.revokeObjectURL(url);
			}
	}, [surgeryId, doc]);

	useEffect(() => {
		getUrl();
	}, [getUrl]);

	return blobUrl;
};
export const MySignModalSecretary: React.FC<{
	surgeryId: string;
	surgeryDate: string;
	documents: Document[];
	onClose: (result?: string) => void;
	practitionerId: string;
}> = ({ surgeryId, surgeryDate, onClose, practitionerId, documents }) => {
	const { format, formatDate } = useI18n();
	const patient = useObservable(patientService.patient);
	const [count, setCount] = useState<number>(0);
	const [loading, setLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const { surgery } = useSurgery(practitionerId as string, surgeryId);
	const documentsTosign = documents.length > 0 ? documents : surgery?.surgeryDocuments.filter(
		document =>
			document.documentType.source == SurgeryDocumentSource.HPU && document.needSignature && !document.isSigned
	) || [];
	const [rejectedDocuments] = useState<RejectedDocument[]>([])
	const docLength = documentsTosign ? Object.keys(documentsTosign).length : 0;
	const [signatureProcess, setSignatureProcess] = useState(false);
	const [timerStatus, setTimerStatus] = useState(false);
	const [checked, setChecked] = useState(false);
	const [showTextArea, setShowTextArea] = useState(false);
	const [rejectDescription, setRejectDescription] = useState("");

	const handleDescriptionInputChange = (e: string) => {
		setRejectDescription(e);
	};
	useEffect(() => {
		if (count == docLength - 1) {
			setEdit(true);
		}
	}, [count, docLength]);
	const currentDocument = documentsTosign[count];
	const blobUrl = useBlobUrl(surgeryId, currentDocument, practitionerId as string);

	const triggerToSign = async () => {
		try {
			if (checked) {
				await secretaryService.signDocument(surgeryId);
				setTimeout(() => setTimerStatus(true), 300000);
				setSignatureProcess(true);
				if (surgery) await secretaryService.fetchSurgery(surgeryId, surgery.practitionerId as string).then();
				if (rejectedDocuments.length > 0) {
					await secretaryService.sendNotificationHpuRejectedDocs(surgeryId, rejectedDocuments)
				}
				onClose();
			}
		} catch (e) {
			console.log(e);
		}
	};
	const triggerToReject = async () => {
		try {
			if (surgery && documentsTosign) {
				await secretaryService.rejectDocument(documentsTosign[count].id, rejectDescription, surgery.practitionerId as string);
				await secretaryService.fetchSurgery(surgeryId, surgery.practitionerId as string);
				setRejectDescription("");
				setShowTextArea(false);

				// region rejected documents storage
				const rejectedDoc: RejectedDocument = {
					documentName: documentsTosign[count].documentType.label,
					documentDescription: documentsTosign[count].description ?? "",
					rejectionMessage: rejectDescription
				}
				rejectedDocuments.push(rejectedDoc);
				// endregion

				if (documentsTosign.length == 1) {
					onClose();
				}
			}
		} catch (e) {
			console.log(e);
		}
	};

	const decrement = () => {
		try {
			if (count > 0 && count <= docLength - 1) {
				setEdit(false);
				setCount(count - 1);
			}
		} catch (e: any) {
			throw e.message;
		} finally {
		}
	};

	const increment = () => {
		try {
			if (count < docLength - 1) setCount(count + 1);
			if (count == docLength - 1) setEdit(true);
		} catch (e: any) {
			throw e.message;
		} finally {
		}
	};

	return (
		<AnimateSharedLayout>
			<Card layout>
				<ModalCloseIcon onClick={() => onClose()} />
				<Regular>
					<Header>
						<Title>{format("patient.signModal.title")}</Title>
						<DescriptionWrapper>
							<Spacer />
							<PatientData>
								{patient && <PatientName>{fullName(patient)}</PatientName>}
								<PatientSurgery>
									{format("hospital.validationModal.surgeryDate")}{" "}
									{formatDate(surgeryDate, { day: "numeric", month: "numeric", year: "numeric" })}
								</PatientSurgery>
							</PatientData>
						</DescriptionWrapper>
					</Header>
					<Body>
						<FolderContainer>
							{documentsTosign &&
								documentsTosign.map((doc, docIndex) => (
									<ShowDocuments
										key={docIndex}
										current={count == docIndex}
										rejected={doc.validationStatus == SurgeryDocumentValidationStatus.Rejected}
									>
										{doc.documentType.label}
										<ArrowDownload
											onClick={async () => {
												const JSZip = await import("jszip");
												// eslint-disable-next-line @typescript-eslint/ban-ts-comment
												//@ts-ignore
												const zip = new JSZip();
												if (!doc.url) {
													return null;
												}
												const result = await api.get(`/secretary/practitioner/${practitionerId}/surgery/${surgeryId}/document/${doc.dlConnectorId}/download`, {
													responseType: 'blob', // Important: Indique que la réponse doit être traitée comme un Blob
												});
												const filename = `${doc.documentType.label}.pdf`;
												zip.file(filename, result.data);
												const archive = await zip.generateAsync({ type: "blob" });
												downloadFile(`${filename}`, archive);
											}}
										/>
									</ShowDocuments>
								))}
						</FolderContainer>
						{blobUrl && <FileViewer>
							<object
								data={documentsTosign && blobUrl}
								type="application
								/pdf"
								width="100%"
								height="100%"
							>
								<iframe src={documentsTosign && blobUrl} width="100%" height="100%"></iframe>
							</object>
						</FileViewer>}
					</Body>
					<Footer>
						<CheckboxContainer>
							{edit ? (
								<CheckboxRow onClick={() => setChecked(!checked)}>
									<Label>{format("practitioner.signModal.checkBox")}</Label>
									<CheckboxInput value={checked} name="signatureCheckbox" />
								</CheckboxRow>
							) : (
								<DocumentArea>
									<DocumentsIndex>
										Document: {count + 1}/{documentsTosign?.length}
									</DocumentsIndex>
								</DocumentArea>
							)}
						</CheckboxContainer>
					</Footer>
					<ButtonContainer>
						{showTextArea && (
							<Area>
								{format("practitioner.modal.hpu.reject.title")}
								<StyledTextArea
									value={rejectDescription}
									placeholder={format("surgerySection.postOp.descriptions.placeholder")}
									maxLength={150}
									onChange={e => {
										handleDescriptionInputChange(e.target.value);
									}}
									required
								/>
							</Area>
						)}
						{count > 0 && (
							<PreviousButton
								onClick={() => {
									decrement();
									setShowTextArea(false);
								}}
								activated={true}
							>
								{format("patient.signModal.previousDocument")}
							</PreviousButton>
						)}
						{edit ? (
							<ButtonRow>
								{!showTextArea && (
									<>
										<PreviousButton
											key="validate"
											layout="position"
											loading={loading}
											onClick={e => {
												if (checked) {
													setLoading(true);
													e.preventDefault();
													triggerToSign();
												}
											}}
											activated={checked}
										>
											{format("sign")}
										</PreviousButton>
										<SignButton
											key="reject"
											layout="position"
											secondary
											onClick={e => {
												setShowTextArea(true);
												e.preventDefault();
											}}
										>
											{format("patient.signModal.rejectDocument")}
										</SignButton>
									</>
								)}
							</ButtonRow>
						) : (
							!showTextArea && (
								<>
									<NextButton key="validate" layout="position" loading={loading} onClick={increment}>
										{format("patient.signModal.nextDocument")}
									</NextButton>
									<SignButton
										key="reject"
										layout="position"
										secondary
										onClick={e => {
											setShowTextArea(true);
											e.preventDefault();
										}}
									>
										{format("patient.signModal.rejectDocument")}
									</SignButton>
								</>
							)
						)}
						{showTextArea && (
							<ButtonRow>
								<SignButton
									key="validate"
									layout="position"
									onClick={e => {
										e.preventDefault();
										triggerToReject();
									}}
								>
									{format("patient.signModal.confirmReject")}
								</SignButton>
								<SignButton
									key="reject"
									layout="position"
									secondary
									onClick={e => {
										setShowTextArea(false);
										setRejectDescription("");
										e.preventDefault();
									}}
								>
									{format("patient.signModal.cancelRejectDocument")}
								</SignButton>
							</ButtonRow>
						)}
					</ButtonContainer>
				</Regular>
			</Card>
		</AnimateSharedLayout>
	);
};

// region style
const Card = styled(motion(ModalCardContainer))`
	${stack("M")};
	width: 96%;
	height: 96%;
	padding: 10px 50px;

	@media (max-width: 920px) {
		width: 100%;
		height: 100%;
		padding: 1px;
	}
`;

const Regular = styled.div`
	width: 100%;
	height: 100%;
	@media (max-width: 920px) {
		overflow: auto;
		width: 100%;
		height: 100%;
	}
`;

const Header = styled.div`
	${stack("S")};
	width: 100%;
`;

const Body = styled.div`
	${row("S", "flex-start", "flex-start")};
	width: 100%;
	height: 70%;
	position: relative;
	@media (max-width: 920px) {
		height: 40%;
	}
`;

const Footer = styled.div`
	${row("S", "flex-start", "flex-start")};
	margin-top: 2vh;
	width: 100%;
	@media ${desktop} {
		font-size: 20px;
		height: 40px;
	}
`;

//		HEADER		//

const Title = styled.h1`
	font-size: 32px;
	max-width: 600px;
	color: ${colors.black};
	@media ${mobile} {
		font-size: 24px;
	}
`;

const DescriptionWrapper = styled.div`
	${row("S")};
	width: 100%;
`;

const PatientData = styled.div`
	${stack(0, "flex-start", "flex-end")}
`;

const PatientName = styled.div`
	font-size: 26px;
	font-weight: bold;
	color: ${colors.black};
	@media ${mobile} {
		font-size: 20px;
	}
`;

const PatientSurgery = styled.div`
	font-size: 15px;
	color: ${colors.black};
`;

//		BODY		//

const FolderContainer = styled.div`
	${stack("S", "flex-start", "flex-start")};
	max-height: 100%;
	width: 15%;
	overflow: auto;
	padding-top: 5px;
	padding-left: 10px;
	padding-bottom: 20px;
	border: 2px solid grey;
	border-radius: 13px;

	@media (max-width: 920px) {
		display: none;
	}
`;

const ShowDocuments = styled.div<{ current?: boolean; rejected?: boolean }>`
	background-color: ${props => (props.current ? "#D3D3D3" : props.rejected ? "rgba(255,111,122,0.7)" : null)};
	border-radius: 8px;
	padding-left: 10px;
	padding-right: 20px;
	padding-bottom: 5px;
	padding-top: 5px;
	width: 90%;
	height: 80%;
	${row("M", "space-between", "center")};
`;

const ArrowDownload = styled(ArrowDownloadIcon)`
	height: 20px;
	width: 20px;
	path,
	rect {
		fill: ${colors.green};
	}
`;

const FileViewer = styled.div`
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	border-radius: 26px;
	border: none;
	@media ${mobile} {
		height: 90%;
	}
`;

//		FOOTER		//

const CheckboxContainer = styled.div`
	@media ${mobile} {
		word-break: break-word;
		${row("M", "space-between", "center")};
	}
	width: 100%;
	height: 50%;
`;

const CheckboxRow = styled.div`
	${row("S", "flex-end", "flex-end")}
	margin-top: 3px;
`;

const ButtonContainer = styled.div`
	@media${desktop} {
		${row("S", "flex-end", "center")}
		width: 100%;
		height: 17%;
	}
	@media${mobile} {
		${stack("S", "center", "center")}
		width: 100%;
		height: 10vh;
	}
`;

const Label = styled.label`
	color: ${colors.black};
	font-size: 20px;
`;

const NextButton = styled(Button)`
	background-color: ${colors.green};
`;

const PreviousButton = styled(Button)<{ activated?: boolean }>`
	background-color: ${props => (props.activated ? colors.green : colors.grey2)};
`;

const DocumentsIndex = styled.div`
	width: 100%;
	height: 100%;
	${row("M")};

	@media (min-width: 920px) {
		display: none;
	}
`;

//					//
//		ELEMENT		//
//					//

const SignButton = styled(Button)`
	margin-top: auto;
	margin-bottom: auto;
	@media${desktop} {
		margin-left: 0;
		margin-right: 0;
	}
`;

const StyledTextArea = styled(TextArea)`
	width: 600px;
`;

const ButtonRow = styled.div`
	${row("S", "flex-start", "flex-start")};
`;
const DocumentArea = styled.div`
	${row("S", "space-evenly", "stretch")}
`;

const Area = styled.div`
	${stack("S", "flex-start", "stretch")}
	height: 100%;
`;
// endregion
