
import { Patient } from "domain/patient";
import { useComputedObservable, WritableObservable } from "micro-observables";
import { useCallback, useEffect, useState } from "react";
import { useUser } from "services/auth-service";
import { api, buildPaginationParams, buildSearchParams } from "./api";
import {
	DocCustomLight, SurgeryFrCodePractitioner,
} from "../domain/practitioner";
import { CCAM } from "../domain/surgery";
import { Paginated, PaginationOptions } from "../utils/pagination";
import { useParams } from "react-router";
import { Map } from "immutable";
import { AxiosResponse } from "axios";
import { Hospital } from "../domain/hpu";

export type PatientCreationDTO = Pick<Patient, "firstName" | "lastName" | "birthDate" | "email" | "phoneNumber1"> & {
	legalGuardian1Id?: string | null;
	legalGuardian2Id?: string | null;
	assign: boolean;
	addTutorAfter?: boolean | null;
};

export class CommonService {
	surgeryFrCodePractitioners = new WritableObservable<SurgeryFrCodePractitioner[]>([]);
	ccamsCommon = new WritableObservable<CCAM[]>([]);
	hospitalsCommon = new WritableObservable<Hospital[]>([]);
	docCustomsStaticPagined = new WritableObservable<Map<number, Paginated<DocCustomLight>>>(Map());

	async fetchHospitalsBySecretary(practitionerId: string): Promise<Hospital[]> {
		const hospitalsCommonData = await api.get<Hospital[]>(`/secretary/practitioner/${practitionerId}/hospitals`);
		this.hospitalsCommon.set(hospitalsCommonData.data);
		return hospitalsCommonData.data;
	}

	async fetchHospitalsByPractitioner(): Promise<Hospital[]> {
		const hospitalsCommonData = await api.get<Hospital[]>(`/practitioner/hospitals`);
		this.hospitalsCommon.set(hospitalsCommonData.data);
		return hospitalsCommonData.data;
	}

	async fetchSurgeryFrCodePractitionerBySecretary(practitionerId: string): Promise<SurgeryFrCodePractitioner[]> {
		const surgeryFrCodePractitionerData = await api.get<SurgeryFrCodePractitioner[]>(`/secretary/practitioner/${practitionerId}/customCcam`);
		this.surgeryFrCodePractitioners.set(surgeryFrCodePractitionerData.data);
		return surgeryFrCodePractitionerData.data;
	}

	async fetchSurgeryFrCodePractitioner(): Promise<SurgeryFrCodePractitioner[]> {
		const surgeryFrCodePractitionerData = await api.get<SurgeryFrCodePractitioner[]>(`/practitioner/customCcam`);
		this.surgeryFrCodePractitioners.set(surgeryFrCodePractitionerData.data);
		return surgeryFrCodePractitionerData.data;
	}

	async fetchCCAMSBySecretary(practitionerId: string): Promise<CCAM[]> {
		const ccamData = await api.get<CCAM[]>(`/secretary/practitioner/${practitionerId}/ccam`);
		this.ccamsCommon.update(() => ccamData.data);
		return ccamData.data;
	}

	async fetchCCAMSByPractitioner(): Promise<CCAM[]> {
		const ccamData = await api.get<CCAM[]>(`/practitioner/ccam`);
		this.ccamsCommon.update(() => ccamData.data);
		return ccamData.data;
	}

	async deleteSurgeryFrCodePractitionerBySecretary(surgeryCodeFrId: string, practitionerId: string): Promise<void> {
		await api.delete(`secretary/practitioner/${practitionerId}/customCcam/${surgeryCodeFrId}/delete`);
	}

	async deleteSurgeryFrCodePractitionerByPractitioner(surgeryCodeFrId: string): Promise<void> {
		await api.delete(`practitioner/customCcam/${surgeryCodeFrId}/delete`);
	}

	async fetchPractitionerDocCustom(practitionerId: string | undefined, pageOptions: PaginationOptions, search?: string) {
		try {
			const paramsObject = {
				...buildPaginationParams(pageOptions),
				search,
			};
			const params = buildSearchParams(paramsObject);
			let result: AxiosResponse<Paginated<DocCustomLight>>
			if(practitionerId) {
				result = await api.get<Paginated<DocCustomLight>>(`/secretary/practitioner/${practitionerId}/practitioner-document`, {params});
			} else {
				result = await api.get<Paginated<DocCustomLight>>(`/practitioner/practitioner-document`, {params});
			}
			this.docCustomsStaticPagined.update(docCustomPages => docCustomPages.set(result.data.page, result.data));

		} catch (e) {
			console.log(e)
			console.log("Query fetchPractitionerDocCustomBySecretary aborted");
		}
		return null;
	}
}

export const commonService = new CommonService();

export function useSurgeryFrCodePractitioner(practitionerId: string | undefined) {
	const user = useUser();
	const customCCAMs = useComputedObservable(() => commonService.surgeryFrCodePractitioners.get(), []);
	const fetchCustomCCAM = useCallback(async () => {
		try {
			if (user && practitionerId as string) {
				await commonService.fetchSurgeryFrCodePractitionerBySecretary(practitionerId as string);
			}
			if (user && practitionerId == undefined) {
				await commonService.fetchSurgeryFrCodePractitioner();
			}
		} catch (e) {
			console.log("Query fetch DocCustomForm aborted");
		}
	}, [practitionerId]);
	useEffect(() => {
		fetchCustomCCAM().then();
	}, [fetchCustomCCAM]);
	return { customCCAMs };
}

export function useCCAMPractitionerCommon(practitionerId: string | undefined): { ccamList: CCAM[] } {
	const user = useUser();
	const ccamList = useComputedObservable(() => commonService.ccamsCommon.get(), []);
	const fetchCCAMS = useCallback(async () => {
		try {
			if (user && practitionerId as string) {
				await commonService.fetchCCAMSBySecretary(practitionerId as string);
			}
			if (user && practitionerId == undefined) {
				await commonService.fetchCCAMSByPractitioner();
			}
		} catch (e) {
			console.log("Query fetch DocCustomForm aborted");
		}
	}, [practitionerId]);
	useEffect(() => {
		fetchCCAMS().then();
	}, [fetchCCAMS]);
	return { ccamList };
}

export function useHospitalsCommon(practitionerId: string | undefined): { hospitals: Hospital[] } {
	const user = useUser();
	const hospitals = useComputedObservable(() => commonService.hospitalsCommon.get(), []);
	const fetchHospitals = useCallback(async () => {
		try {
			if (user && practitionerId as string) {
				await commonService.fetchHospitalsBySecretary(practitionerId as string);
			}
			if (user && practitionerId == undefined) {
				await commonService.fetchHospitalsByPractitioner();
			}
		} catch (e) {
			console.log("Query fetch DocCustomForm aborted");
		}
	}, [practitionerId]);
	useEffect(() => {
		fetchHospitals().then();
	}, [fetchHospitals]);
	return { hospitals };
}

export function useCommonPractitionerDocCustom(
	pageOptions: PaginationOptions,
	search?: string,
): { loading: boolean; practitionerDocCustoms: Paginated<DocCustomLight> | undefined; refresh: () => Promise<void> } {
	const user = useUser();
	const { practitionerId } = useParams<{ practitionerId: string | undefined }>();
	const practitionerDocCustoms = useComputedObservable(() => commonService.docCustomsStaticPagined.get().get(pageOptions.page), [
		user,
		pageOptions,
	]);
	const [loading, setLoading] = useState(true);
	const fetchDocCustom = useCallback(async () => {
		try {
			if (user && practitionerId as string) {
				await commonService.fetchPractitionerDocCustom(
					practitionerId as string,
					pageOptions,
					search,
				);
			} else if (user && practitionerId == undefined) {
				await commonService.fetchPractitionerDocCustom(
					undefined,
					pageOptions,
					search,
				);
			}
		} catch (e) {
			console.log("Query fetch DocCustomForm aborted");
		} finally {
			setLoading(false);
		}
	}, [user, pageOptions, search, practitionerId]);

	useEffect(() => {
		fetchDocCustom().then();
	}, [fetchDocCustom]);

	return {
		loading,
		practitionerDocCustoms,
		refresh: fetchDocCustom,
	};
}
