import styled from "@emotion/styled";
import {Button} from "components/button";
import React from "react";
import {ModalCardContainer, ModalCloseIcon} from "services/modal-service";
import {colors, sizes, stack} from "style";
import { useI18n } from "../../utils/i18n";

interface CreateInfoModalProps {
	onClose: (patientId: string | null) => void;
}
export const CreatInfoModal: React.FC<CreateInfoModalProps> = ({ onClose }) => {
	const { format } = useI18n();

	return (
		<Card>
			<ModalCloseIcon onClick={() => onClose(null)} />
			<Title>{format("processSign")}</Title>

			<Sections>
				<Step>1) Cliquez sur le bouton rose « visionner et signer tous les documents » légèrement en haut à droite</Step>

				<Step>2) Choisissez SMS ou email pour recevoir le code et veuillez cliquer sur le bouton rose « Signer »</Step>

				<Step>3) Cliquez sur le lien web reçu par SMS ou email. Cela va ouvrir une page web sur votre téléphone ou ordinateur</Step>

				<Step>4) Sur cette nouvelle page web,</Step>

				<SubStep>
					<Illustration>
						<img src="/images/signatureSMS.png"  alt="sms"/>
					</Illustration>
				</SubStep>

				<SubStep>Étape 1 : Cliquez sur « Voir le document » et notez le code en rouge <Red>à la dernière page</Red></SubStep>
				<SubStep>Étape 2 : Saisissez le code dans le champ prévu et cliquez sur OK</SubStep>

				<Step>5) A cet instant, les documents seront donc signés. Après quelques secondes, la page dans SOPHI se rafraichira</Step>

				<Step>En outre, quand il y a 2 signataires (parents ou tuteurs), il faut absolument que les 2 signent pour pouvoir valider l’étape</Step>
			</Sections>

			<Sections>
				<Button secondary type="button" onClick={() => {
					onClose(null)
				}}>
					{format("practitionerDashboardDocumentToCustomSection.close")}
				</Button>
			</Sections>

		</Card>
	);
};

const Card = styled(ModalCardContainer)`
    padding: 13px 20px;
    width: 950px; /* Ajoutez cette ligne pour définir une largeur fixe */
    max-width: 100%; /* Cela garantit que la carte ne dépasse pas 100% de la largeur de l'écran */
    ${stack(0, "center", "center")};
    @media (max-width: 920px) {
        padding: ${sizes.S};
        width: auto; /* Ajustement pour les petits écrans */
    }
    font-size: 15px;
`;

const Title = styled.h1`
	font-size: 23px;
	color: ${colors.black};
`;

const Sections = styled.div`
    ${stack("S", "flex-start", "flex-start")};
		margin: 10px;
`;

const Step = styled.div`
    ${stack("S", "center", "stretch")};
`;

const SubStep = styled.div`
    ${stack("S", "center", "stretch")};
		margin-left: 20px;
`;

const Illustration = styled.div`
    ${stack("S", "center", "stretch")};
`;

const Red = styled.text`
    color: ${colors.red};
		display: contents;
`;